package com.virtualrain.sdk.utils

import kotlin.reflect.KClass

sealed interface RequestException {
    class NotFound(modelType: KClass<*>): NullPointerException("${modelType.simpleName} was not found")

    class UpdateError(modelType: KClass<*>): NullPointerException("Error in modifying ${modelType.simpleName}")

    class CreationError(modelType: KClass<*>): NullPointerException("Error in creating ${modelType.simpleName}")

    class DeletionError(modelType: KClass<*>): NullPointerException("Error in deleting ${modelType.simpleName}")
}

inline fun <reified T> notFoundError(): Nothing { throw RequestException.NotFound(T::class) }
inline fun <reified T> updateError(): Nothing { throw RequestException.UpdateError(T::class) }
inline fun <reified T> creationError(): Nothing { throw RequestException.CreationError(T::class) }
inline fun <reified T> deletionError(): Nothing { throw RequestException.DeletionError(T::class) }