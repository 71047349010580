package com.virtualrain.validation

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.InvalidSemantic
import com.lightningkite.kiteui.models.Theme
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.Writable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.dynamicTheme
import com.lightningkite.kiteui.views.themeFromLast
import com.virtualrain.selfCancelling.HasDebug

@ViewModifierDsl3
fun ViewWriter.validate(validated: Validated): ViewWrapper {
    beforeNextElementSetup {
        dynamicTheme {
            if (!validated.valid()) InvalidSemantic else null
        }
    }
    return ViewWrapper
}

@ViewModifierDsl3
fun ViewWriter.validate(validated: Readable<Validated>): ViewWrapper {
    beforeNextElementSetup {
        dynamicTheme {
            if (!validated().valid()) InvalidSemantic else null
        }
    }
    return ViewWrapper
}

@ViewModifierDsl3
fun ViewWriter.validateDebug(validated: Readable<Validated>): ViewWrapper {
    beforeNextElementSetup {
        dynamicTheme {
            val obj = validated()
            val valid = obj.valid()
            if (obj is HasDebug) {
                with (obj) {
                    obj.debug?.id("Found valid = $valid")
                }
            }
            if (!valid) InvalidSemantic else null
        }
    }
    return ViewWrapper
}