// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareInventoryFields() {
    val props: Array<SerializableProperty<Inventory, *>> = arrayOf(Inventory__id, Inventory_count)
    Inventory.serializer().properties { props }
}
val <K> DataClassPath<K, Inventory>._id: DataClassPath<K, ProductWarehouse> get() = this[Inventory__id]
val <K> DataClassPath<K, Inventory>.count: DataClassPath<K, Int> get() = this[Inventory_count]
inline val Inventory.Companion.path: DataClassPath<Inventory, Inventory> get() = path<Inventory>()


object Inventory__id: SerializableProperty<Inventory, ProductWarehouse> {
    override val name: String = "_id"
    override fun get(receiver: Inventory): ProductWarehouse = receiver._id
    override fun setCopy(receiver: Inventory, value: ProductWarehouse) = receiver.copy(_id = value)
    override val serializer: KSerializer<ProductWarehouse> = ProductWarehouse.serializer()
    override val annotations: List<Annotation> = Inventory.serializer().tryFindAnnotations("_id")
}
object Inventory_count: SerializableProperty<Inventory, Int> {
    override val name: String = "count"
    override fun get(receiver: Inventory): Int = receiver.count
    override fun setCopy(receiver: Inventory, value: Int) = receiver.copy(count = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = Inventory.serializer().tryFindAnnotations("count")
    override val default: Int = 0
}
