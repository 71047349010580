package com.virtualrain.views.components

import com.lightningkite.kiteui.reactive.ReactiveContext
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.bold
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.models.*
import com.virtualrain.views.screens.products.CatalogScreen.rowCollapsingToColumn

fun ViewWriter.detail(label: String, collapseAt: Dimension = 30.rem, detail: ReactiveContext.() -> String) {
    rowCollapsingToColumn(collapseAt) {
        bold - text(label)
        expanding - text { ::content { detail() } }
    }
}

fun ViewWriter.detail(label: ReactiveContext.() -> String, detail: ReactiveContext.() -> String) {
    row {
        bold - text { ::content { label() } }
        text { ::content { detail() } }
    }
}