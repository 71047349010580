package com.virtualrain

import com.lightningkite.kiteui.navigation.*
import com.virtualrain.admin.AdminAccountsScreen
import com.virtualrain.admin.AdminAdminLoginsScreen
import com.virtualrain.admin.AdminCategoriesScreen
import com.virtualrain.admin.AdminOrderDetailScreen
import com.virtualrain.admin.AdminOrdersScreen
import com.virtualrain.admin.AdminProductsScreen
import com.virtualrain.admin.AdminReportsScreen
import com.virtualrain.admin.AdminSpecialsScreen
import com.virtualrain.admin.AdminUsersScreen
import com.virtualrain.admin.AdminWarehousesScreen
import com.virtualrain.admin.PriceIncreasesScreen
import com.virtualrain.admin.PriceIncreaseForm
import com.virtualrain.views.screens.account.AccountInfoScreen
import com.virtualrain.views.screens.account.PickupLocationsScreen
import com.virtualrain.views.screens.account.ShippingLocationsScreen
import com.virtualrain.views.screens.account.orders.OrderScreen
import com.virtualrain.views.screens.account.orders.OrderHistoryScreen
import com.virtualrain.views.screens.auth.Landing
import com.virtualrain.views.screens.auth.LoginScreen
import com.virtualrain.views.screens.cart.CartScreen
import com.virtualrain.views.screens.products.CatalogScreen
import com.virtualrain.views.screens.products.CategoryScreen
import com.virtualrain.views.screens.products.Favorites
import com.virtualrain.views.screens.products.ProductScreen
import com.virtualrain.views.screens.products.SearchScreen
import com.lightningkite.kiteui.navigation.Screen.Empty
import com.lightningkite.UUID
import com.virtualrain.models.OrderStatusCode
import kotlinx.datetime.LocalDate


val AutoRoutes = Routes(
    parsers = listOf(
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "accounts") return@label null
            AdminAccountsScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.collections.Set<com.lightningkite.UUID>>("representatives", it.parameters)?.let { this.representatives.value = it }
                UrlProperties.decodeFromStringMap<kotlin.String>("search", it.parameters)?.let { this.search.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "settings") return@label null
            AdminAdminLoginsScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String>("search", it.parameters)?.let { this.search.value = it }
                UrlProperties.decodeFromStringMap<kotlin.Boolean>("showRemoved", it.parameters)?.let { this.showRemoved.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "categories") return@label null
            AdminCategoriesScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.Boolean>("limitToNoImage", it.parameters)?.let { this.limitToNoImage.value = it }
                UrlProperties.decodeFromStringMap<kotlin.String>("search", it.parameters)?.let { this.search.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "orders") return@label null
            AdminOrderDetailScreen(
                orderId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "orders") return@label null
            AdminOrdersScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.collections.Set<com.lightningkite.UUID>>("accounts", it.parameters)?.let { this.accounts.value = it }
                UrlProperties.decodeFromStringMap<kotlinx.datetime.LocalDate?>("endDate", it.parameters)?.let { this.endDate.value = it }
                UrlProperties.decodeFromStringMap<kotlin.Boolean>("includeRemoved", it.parameters)?.let { this.includeRemoved.value = it }
                UrlProperties.decodeFromStringMap<kotlin.String?>("orderNum", it.parameters)?.let { this.orderNum.value = it }
                UrlProperties.decodeFromStringMap<kotlin.String?>("poNum", it.parameters)?.let { this.poNum.value = it }
                UrlProperties.decodeFromStringMap<kotlinx.datetime.LocalDate?>("startDate", it.parameters)?.let { this.startDate.value = it }
                UrlProperties.decodeFromStringMap<com.virtualrain.models.OrderStatusCode?>("status", it.parameters)?.let { this.status.value = it }
                UrlProperties.decodeFromStringMap<kotlin.collections.Set<com.lightningkite.UUID>>("users", it.parameters)?.let { this.users.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "products") return@label null
            AdminProductsScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.Boolean>("limitToNoImage", it.parameters)?.let { this.limitToNoImage.value = it }
                UrlProperties.decodeFromStringMap<kotlin.String>("search", it.parameters)?.let { this.search.value = it }
                UrlProperties.decodeFromStringMap<kotlin.Boolean>("showRemoved", it.parameters)?.let { this.showRemoved.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "reports") return@label null
            AdminReportsScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlinx.datetime.LocalDate?>("endDate", it.parameters)?.let { this.endDate.value = it }
                UrlProperties.decodeFromStringMap<kotlinx.datetime.LocalDate?>("startDate", it.parameters)?.let { this.startDate.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "specials") return@label null
            AdminSpecialsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "users") return@label null
            AdminUsersScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.collections.Set<com.lightningkite.UUID>>("accounts", it.parameters)?.let { this.accounts.value = it }
                UrlProperties.decodeFromStringMap<kotlin.String>("search", it.parameters)?.let { this.search.value = it }
                UrlProperties.decodeFromStringMap<kotlin.Boolean>("showRemoved", it.parameters)?.let { this.showRemoved.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "warehouses") return@label null
            AdminWarehousesScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "admin") return@label null
            if (it.segments[1] != "price-increases") return@label null
            PriceIncreasesScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "admin") return@label null
            if (it.segments[1] != "price-increases") return@label null
            PriceIncreaseForm(
                id = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "Account") return@label null
            AccountInfoScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "Pickup-Locations") return@label null
            PickupLocationsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "Shipping-Locations") return@label null
            ShippingLocationsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "order") return@label null
            OrderScreen(
                orderID = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "OrderHistory") return@label null
            OrderHistoryScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 0) return@label null
            Landing(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "auth") return@label null
            if (it.segments[1] != "login") return@label null
            LoginScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "Carts") return@label null
            CartScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "catalog") return@label null
            CatalogScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "Category") return@label null
            CategoryScreen(
                categoryID = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "Favorites") return@label null
            Favorites(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "Product") return@label null
            ProductScreen(
                productID = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "Search") return@label null
            SearchScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String>("query", it.parameters)?.let { this.query.value = it }
            }
        },
    ),
    renderers = mapOf(
        AdminAccountsScreen::class to label@{
            if (it !is AdminAccountsScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.representatives.value, "representatives", p)
            UrlProperties.encodeToStringMap(it.search.value, "search", p)
            RouteRendered(UrlLikePath(
                segments = listOf("accounts"),
                parameters = p
            ), listOf(it.representatives, it.search))
        },
        AdminAdminLoginsScreen::class to label@{
            if (it !is AdminAdminLoginsScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.search.value, "search", p)
            UrlProperties.encodeToStringMap(it.showRemoved.value, "showRemoved", p)
            RouteRendered(UrlLikePath(
                segments = listOf("settings"),
                parameters = p
            ), listOf(it.search, it.showRemoved))
        },
        AdminCategoriesScreen::class to label@{
            if (it !is AdminCategoriesScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.limitToNoImage.value, "limitToNoImage", p)
            UrlProperties.encodeToStringMap(it.search.value, "search", p)
            RouteRendered(UrlLikePath(
                segments = listOf("categories"),
                parameters = p
            ), listOf(it.limitToNoImage, it.search))
        },
        AdminOrderDetailScreen::class to label@{
            if (it !is AdminOrderDetailScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("orders", UrlProperties.encodeToString(it.orderId)),
                parameters = p
            ), listOf())
        },
        AdminOrdersScreen::class to label@{
            if (it !is AdminOrdersScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.accounts.value, "accounts", p)
            UrlProperties.encodeToStringMap(it.endDate.value, "endDate", p)
            UrlProperties.encodeToStringMap(it.includeRemoved.value, "includeRemoved", p)
            UrlProperties.encodeToStringMap(it.orderNum.value, "orderNum", p)
            UrlProperties.encodeToStringMap(it.poNum.value, "poNum", p)
            UrlProperties.encodeToStringMap(it.startDate.value, "startDate", p)
            UrlProperties.encodeToStringMap(it.status.value, "status", p)
            UrlProperties.encodeToStringMap(it.users.value, "users", p)
            RouteRendered(UrlLikePath(
                segments = listOf("orders"),
                parameters = p
            ), listOf(it.accounts, it.endDate, it.includeRemoved, it.orderNum, it.poNum, it.startDate, it.status, it.users))
        },
        AdminProductsScreen::class to label@{
            if (it !is AdminProductsScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.limitToNoImage.value, "limitToNoImage", p)
            UrlProperties.encodeToStringMap(it.search.value, "search", p)
            UrlProperties.encodeToStringMap(it.showRemoved.value, "showRemoved", p)
            RouteRendered(UrlLikePath(
                segments = listOf("products"),
                parameters = p
            ), listOf(it.limitToNoImage, it.search, it.showRemoved))
        },
        AdminReportsScreen::class to label@{
            if (it !is AdminReportsScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.endDate.value, "endDate", p)
            UrlProperties.encodeToStringMap(it.startDate.value, "startDate", p)
            RouteRendered(UrlLikePath(
                segments = listOf("reports"),
                parameters = p
            ), listOf(it.endDate, it.startDate))
        },
        AdminSpecialsScreen::class to label@{
            if (it !is AdminSpecialsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("specials"),
                parameters = p
            ), listOf())
        },
        AdminUsersScreen::class to label@{
            if (it !is AdminUsersScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.accounts.value, "accounts", p)
            UrlProperties.encodeToStringMap(it.search.value, "search", p)
            UrlProperties.encodeToStringMap(it.showRemoved.value, "showRemoved", p)
            RouteRendered(UrlLikePath(
                segments = listOf("users"),
                parameters = p
            ), listOf(it.accounts, it.search, it.showRemoved))
        },
        AdminWarehousesScreen::class to label@{
            if (it !is AdminWarehousesScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("warehouses"),
                parameters = p
            ), listOf())
        },
        PriceIncreasesScreen::class to label@{
            if (it !is PriceIncreasesScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("admin", "price-increases"),
                parameters = p
            ), listOf())
        },
        PriceIncreaseForm::class to label@{
            if (it !is PriceIncreaseForm) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("admin", "price-increases", UrlProperties.encodeToString(it.id)),
                parameters = p
            ), listOf())
        },
        AccountInfoScreen::class to label@{
            if (it !is AccountInfoScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("Account"),
                parameters = p
            ), listOf())
        },
        PickupLocationsScreen::class to label@{
            if (it !is PickupLocationsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("Pickup-Locations"),
                parameters = p
            ), listOf())
        },
        ShippingLocationsScreen::class to label@{
            if (it !is ShippingLocationsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("Shipping-Locations"),
                parameters = p
            ), listOf())
        },
        OrderScreen::class to label@{
            if (it !is OrderScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("order", UrlProperties.encodeToString(it.orderID)),
                parameters = p
            ), listOf())
        },
        OrderHistoryScreen::class to label@{
            if (it !is OrderHistoryScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("OrderHistory"),
                parameters = p
            ), listOf())
        },
        Landing::class to label@{
            if (it !is Landing) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf(),
                parameters = p
            ), listOf())
        },
        LoginScreen::class to label@{
            if (it !is LoginScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("auth", "login"),
                parameters = p
            ), listOf())
        },
        CartScreen::class to label@{
            if (it !is CartScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("Carts"),
                parameters = p
            ), listOf())
        },
        CatalogScreen::class to label@{
            if (it !is CatalogScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("catalog"),
                parameters = p
            ), listOf())
        },
        CategoryScreen::class to label@{
            if (it !is CategoryScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("Category", UrlProperties.encodeToString(it.categoryID)),
                parameters = p
            ), listOf())
        },
        Favorites::class to label@{
            if (it !is Favorites) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("Favorites"),
                parameters = p
            ), listOf())
        },
        ProductScreen::class to label@{
            if (it !is ProductScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("Product", UrlProperties.encodeToString(it.productID)),
                parameters = p
            ), listOf())
        },
        SearchScreen::class to label@{
            if (it !is SearchScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.query.value, "query", p)
            RouteRendered(UrlLikePath(
                segments = listOf("Search"),
                parameters = p
            ), listOf(it.query))
        },
    ),
    fallback = Empty
)
