// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun preparePopupMessageFields() {
    val props: Array<SerializableProperty<PopupMessage, *>> = arrayOf(PopupMessage__id, PopupMessage_title, PopupMessage_message, PopupMessage_startTime, PopupMessage_stopTime, PopupMessage_behavior, PopupMessage_image, PopupMessage_product, PopupMessage_category)
    PopupMessage.serializer().properties { props }
}
val <K> DataClassPath<K, PopupMessage>._id: DataClassPath<K, UUID> get() = this[PopupMessage__id]
val <K> DataClassPath<K, PopupMessage>.title: DataClassPath<K, String> get() = this[PopupMessage_title]
val <K> DataClassPath<K, PopupMessage>.message: DataClassPath<K, String> get() = this[PopupMessage_message]
val <K> DataClassPath<K, PopupMessage>.startTime: DataClassPath<K, Instant> get() = this[PopupMessage_startTime]
val <K> DataClassPath<K, PopupMessage>.stopTime: DataClassPath<K, Instant> get() = this[PopupMessage_stopTime]
val <K> DataClassPath<K, PopupMessage>.behavior: DataClassPath<K, PopupBehavior> get() = this[PopupMessage_behavior]
val <K> DataClassPath<K, PopupMessage>.image: DataClassPath<K, ServerFile?> get() = this[PopupMessage_image]
val <K> DataClassPath<K, PopupMessage>.product: DataClassPath<K, UUID?> get() = this[PopupMessage_product]
val <K> DataClassPath<K, PopupMessage>.category: DataClassPath<K, UUID?> get() = this[PopupMessage_category]
inline val PopupMessage.Companion.path: DataClassPath<PopupMessage, PopupMessage> get() = path<PopupMessage>()


object PopupMessage__id: SerializableProperty<PopupMessage, UUID> {
    override val name: String = "_id"
    override fun get(receiver: PopupMessage): UUID = receiver._id
    override fun setCopy(receiver: PopupMessage, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = PopupMessage.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object PopupMessage_title: SerializableProperty<PopupMessage, String> {
    override val name: String = "title"
    override fun get(receiver: PopupMessage): String = receiver.title
    override fun setCopy(receiver: PopupMessage, value: String) = receiver.copy(title = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = PopupMessage.serializer().tryFindAnnotations("title")
    override val default: String = "Special"
}
object PopupMessage_message: SerializableProperty<PopupMessage, String> {
    override val name: String = "message"
    override fun get(receiver: PopupMessage): String = receiver.message
    override fun setCopy(receiver: PopupMessage, value: String) = receiver.copy(message = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = PopupMessage.serializer().tryFindAnnotations("message")
}
object PopupMessage_startTime: SerializableProperty<PopupMessage, Instant> {
    override val name: String = "startTime"
    override fun get(receiver: PopupMessage): Instant = receiver.startTime
    override fun setCopy(receiver: PopupMessage, value: Instant) = receiver.copy(startTime = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = PopupMessage.serializer().tryFindAnnotations("startTime")
}
object PopupMessage_stopTime: SerializableProperty<PopupMessage, Instant> {
    override val name: String = "stopTime"
    override fun get(receiver: PopupMessage): Instant = receiver.stopTime
    override fun setCopy(receiver: PopupMessage, value: Instant) = receiver.copy(stopTime = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = PopupMessage.serializer().tryFindAnnotations("stopTime")
}
object PopupMessage_behavior: SerializableProperty<PopupMessage, PopupBehavior> {
    override val name: String = "behavior"
    override fun get(receiver: PopupMessage): PopupBehavior = receiver.behavior
    override fun setCopy(receiver: PopupMessage, value: PopupBehavior) = receiver.copy(behavior = value)
    override val serializer: KSerializer<PopupBehavior> = PopupBehavior.serializer()
    override val annotations: List<Annotation> = PopupMessage.serializer().tryFindAnnotations("behavior")
    override val default: PopupBehavior = PopupBehavior.Always
}
object PopupMessage_image: SerializableProperty<PopupMessage, ServerFile?> {
    override val name: String = "image"
    override fun get(receiver: PopupMessage): ServerFile? = receiver.image
    override fun setCopy(receiver: PopupMessage, value: ServerFile?) = receiver.copy(image = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = PopupMessage.serializer().tryFindAnnotations("image")
    override val default: ServerFile? = null
}
object PopupMessage_product: SerializableProperty<PopupMessage, UUID?> {
    override val name: String = "product"
    override fun get(receiver: PopupMessage): UUID? = receiver.product
    override fun setCopy(receiver: PopupMessage, value: UUID?) = receiver.copy(product = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = PopupMessage.serializer().tryFindAnnotations("product")
    override val default: UUID? = null
}
object PopupMessage_category: SerializableProperty<PopupMessage, UUID?> {
    override val name: String = "category"
    override fun get(receiver: PopupMessage): UUID? = receiver.category
    override fun setCopy(receiver: PopupMessage, value: UUID?) = receiver.copy(category = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = PopupMessage.serializer().tryFindAnnotations("category")
    override val default: UUID? = null
}
