package com.virtualrain.theming

import com.lightningkite.kiteui.models.*

data object LightOutline: Semantic {
    override val key: String = "light-outline"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        outlineWidth = 1.px,
        outline = Color.interpolate(theme.background.closestColor(), theme.foreground.closestColor(), 0.3f),
        revert = true
    ).withBack
}

data object GhostSemantic: Semantic {
    override val key: String = "ghost"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        background = theme.background.closestColor().highlight(0.2f).withAlpha(0.5f),
    ).withBack
}

data object FadingGradient: Semantic {
    override val key: String = "fading-grad"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        elevation = 0.rem,
        cornerRadii = CornerRadii.Constant(0.rem),
        background = LinearGradient(
            angle = Angle(0.05),
            stops = listOf(
                GradientStop(0f, theme.background.closestColor()),
                GradientStop(0.05f, theme.background.closestColor()),
                GradientStop(0.15f, theme.background.closestColor().withAlpha(0.9f)),
                GradientStop(0.4f, Color.transparent),
                GradientStop(1f, Color.transparent),
            )
        )
    ).withBack
}

data object CheckboxSemantic: Semantic {
    override val key: String = "checkbox"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        outline = ThemeSettings.Colors.blue.toRGB(),
        cornerRadii = CornerRadii.Constant(0.1.rem)
    ).withoutBack
}

data object UnavailableSemantic: Semantic {
    override val key: String = "unavailable"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        foreground = Color.interpolate(theme.foreground.closestColor(), Color.red, 0.5f).withAlpha(0.5f)
    ).withoutBack
}

data object ListContainerSemantic: Semantic {
    override val key: String = "list-container"
    override fun default(theme: Theme): ThemeAndBack = theme[CardSemantic][EmbeddedSemantic]
}

data object SpecialsSemantic: Semantic {
    override val key: String = "specials"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        background = LinearGradient(
            stops = listOf(
                GradientStop(0f, ThemeSettings.Colors.blue.toRGB().whiteShift(0.3f)),
                GradientStop(0.3f, ThemeSettings.Colors.blue.toRGB()),
                GradientStop(1f, ThemeSettings.Colors.seafoam)
            ),
            angle = Angle(-1f/8)
        ),
        foreground = Color.white,
//        revert = true,
        derivations = mapOf(
            HoverSemantic to {
                (theme.derivations[HoverSemantic]?.invoke(it) ?: HoverSemantic.default(it)).theme.copy(
                    id = "hvr",
                    derivations = mapOf(
                        DisabledSemantic to { it.withoutBack }
                    )
                ).withBack
            },
            DisabledSemantic to {
                it.copy(
                    id = "disabled",
                    derivations = mapOf(
                        HoverSemantic to { it.withoutBack }
                    )
                ).withoutBack
            },
            LightOutline to {
                it.copy(
                    id = "light-out",
                    outline = Color.white,
                    outlineWidth = 1.px,
                    background = Color.transparent,
                    foreground = Color.white,
                    derivations = mapOf(
                        HoverSemantic to { it.withoutBack }
                    )
                ).withBack
            }
        ),
    ).withBack
}

data object InvalidMessage: Semantic {
    override val key: String = "ivd-msg"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        foreground = ThemeSettings.Colors.danger
    ).withoutBack
}