package com.virtualrain.views.screens.common

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.reactiveScope
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.h2
import com.lightningkite.kiteui.views.direct.stack
import com.virtualrain.models.UserRole
import com.virtualrain.sdk.currentSession
import com.virtualrain.views.emptyView

val userHasAccount: Readable<Boolean> = shared(useLastWhileLoading = true) {
    currentSession.invoke()?.accountId != null
}

val userIsAdmin: Readable<Boolean> = shared(useLastWhileLoading = true) {
    (currentSession.invoke()?.self()?.role ?: UserRole.AccountReadOnly) >= UserRole.AccountAdmin
}

interface ContentDeterminedByAccount: Screen {
    fun ViewWriter.hasAccount(): Unit
    val noAccountMessage: String

    fun ViewWriter.noAccount() {
        emptyView {
            centered - h2(noAccountMessage)
        }
    }

    override fun ViewWriter.render() {
        stack {
            val split = split()

            reactiveScope {
                clearChildren()
                if (userHasAccount()) {
                    split.hasAccount()
                }
                else split.noAccount()
            }
        }
    }
}