package com.virtualrain.views.screens.common

import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.ContainingView
import com.lightningkite.kiteui.views.direct.rowCollapsingToColumn

interface HasNarrowContent {
    val narrowDefault get() = false
    val narrow get() = Info.narrow

    companion object Info {
        val breakpoint = 50.rem
        val narrow = shared(useLastWhileLoading = true) { AppState.windowInfo().width < breakpoint }
    }

    fun ViewWriter.rowCollapsingToColumn(setup: ContainingView.() -> Unit) = rowCollapsingToColumn(breakpoint, setup)

    fun RView.existsWhenNarrow() {
        exists = narrowDefault
        ::exists { Info.narrow() }
    }

    fun RView.existsWhenWide() {
        exists = !narrowDefault
        ::exists { !Info.narrow() }
    }
}