// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareCustomerAccountFields() {
    val props: Array<SerializableProperty<CustomerAccount, *>> = arrayOf(CustomerAccount__id, CustomerAccount_email, CustomerAccount_phoneNumber, CustomerAccount_erpId, CustomerAccount_address, CustomerAccount_active, CustomerAccount_representatives, CustomerAccount_preferredShippingAddress, CustomerAccount_preferredPickupLocation)
    CustomerAccount.serializer().properties { props }
}
val <K> DataClassPath<K, CustomerAccount>._id: DataClassPath<K, UUID> get() = this[CustomerAccount__id]
val <K> DataClassPath<K, CustomerAccount>.email: DataClassPath<K, String> get() = this[CustomerAccount_email]
val <K> DataClassPath<K, CustomerAccount>.phoneNumber: DataClassPath<K, String> get() = this[CustomerAccount_phoneNumber]
val <K> DataClassPath<K, CustomerAccount>.erpId: DataClassPath<K, Long?> get() = this[CustomerAccount_erpId]
val <K> DataClassPath<K, CustomerAccount>.address: DataClassPath<K, Address> get() = this[CustomerAccount_address]
val <K> DataClassPath<K, CustomerAccount>.active: DataClassPath<K, Boolean> get() = this[CustomerAccount_active]
val <K> DataClassPath<K, CustomerAccount>.representatives: DataClassPath<K, Set<UUID>> get() = this[CustomerAccount_representatives]
val <K> DataClassPath<K, CustomerAccount>.preferredShippingAddress: DataClassPath<K, UUID?> get() = this[CustomerAccount_preferredShippingAddress]
val <K> DataClassPath<K, CustomerAccount>.preferredPickupLocation: DataClassPath<K, UUID?> get() = this[CustomerAccount_preferredPickupLocation]
inline val CustomerAccount.Companion.path: DataClassPath<CustomerAccount, CustomerAccount> get() = path<CustomerAccount>()


object CustomerAccount__id: SerializableProperty<CustomerAccount, UUID> {
    override val name: String = "_id"
    override fun get(receiver: CustomerAccount): UUID = receiver._id
    override fun setCopy(receiver: CustomerAccount, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = CustomerAccount.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object CustomerAccount_email: SerializableProperty<CustomerAccount, String> {
    override val name: String = "email"
    override fun get(receiver: CustomerAccount): String = receiver.email
    override fun setCopy(receiver: CustomerAccount, value: String) = receiver.copy(email = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = CustomerAccount.serializer().tryFindAnnotations("email")
}
object CustomerAccount_phoneNumber: SerializableProperty<CustomerAccount, String> {
    override val name: String = "phoneNumber"
    override fun get(receiver: CustomerAccount): String = receiver.phoneNumber
    override fun setCopy(receiver: CustomerAccount, value: String) = receiver.copy(phoneNumber = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = CustomerAccount.serializer().tryFindAnnotations("phoneNumber")
}
object CustomerAccount_erpId: SerializableProperty<CustomerAccount, Long?> {
    override val name: String = "erpId"
    override fun get(receiver: CustomerAccount): Long? = receiver.erpId
    override fun setCopy(receiver: CustomerAccount, value: Long?) = receiver.copy(erpId = value)
    override val serializer: KSerializer<Long?> = Long.serializer().nullable2
    override val annotations: List<Annotation> = CustomerAccount.serializer().tryFindAnnotations("erpId")
    override val default: Long? = null
}
object CustomerAccount_address: SerializableProperty<CustomerAccount, Address> {
    override val name: String = "address"
    override fun get(receiver: CustomerAccount): Address = receiver.address
    override fun setCopy(receiver: CustomerAccount, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> = Address.serializer()
    override val annotations: List<Annotation> = CustomerAccount.serializer().tryFindAnnotations("address")
}
object CustomerAccount_active: SerializableProperty<CustomerAccount, Boolean> {
    override val name: String = "active"
    override fun get(receiver: CustomerAccount): Boolean = receiver.active
    override fun setCopy(receiver: CustomerAccount, value: Boolean) = receiver.copy(active = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = CustomerAccount.serializer().tryFindAnnotations("active")
    override val default: Boolean = true
}
object CustomerAccount_representatives: SerializableProperty<CustomerAccount, Set<UUID>> {
    override val name: String = "representatives"
    override fun get(receiver: CustomerAccount): Set<UUID> = receiver.representatives
    override fun setCopy(receiver: CustomerAccount, value: Set<UUID>) = receiver.copy(representatives = value)
    override val serializer: KSerializer<Set<UUID>> = SetSerializer(ContextualSerializer(UUID::class))
    override val annotations: List<Annotation> = CustomerAccount.serializer().tryFindAnnotations("representatives")
    override val default: Set<UUID> = emptySet()
}
object CustomerAccount_preferredShippingAddress: SerializableProperty<CustomerAccount, UUID?> {
    override val name: String = "preferredShippingAddress"
    override fun get(receiver: CustomerAccount): UUID? = receiver.preferredShippingAddress
    override fun setCopy(receiver: CustomerAccount, value: UUID?) = receiver.copy(preferredShippingAddress = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = CustomerAccount.serializer().tryFindAnnotations("preferredShippingAddress")
    override val default: UUID? = null
}
object CustomerAccount_preferredPickupLocation: SerializableProperty<CustomerAccount, UUID?> {
    override val name: String = "preferredPickupLocation"
    override fun get(receiver: CustomerAccount): UUID? = receiver.preferredPickupLocation
    override fun setCopy(receiver: CustomerAccount, value: UUID?) = receiver.copy(preferredPickupLocation = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = CustomerAccount.serializer().tryFindAnnotations("preferredPickupLocation")
    override val default: UUID? = null
}
