package com.virtualrain.views.screens.auth

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningserver.LsErrorException
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.serialization.lensPath
import com.virtualrain.*
import com.virtualrain.models.*
import com.virtualrain.sdk.currentSession
import com.virtualrain.sdk.selectedApi
import com.virtualrain.sdk.sessionToken
import com.virtualrain.validation.Validator
import com.virtualrain.validation.validate
import com.virtualrain.views.screens.products.CatalogScreen

class UserRegistration : Validator() {
    val user = Property(
        User(
            firstName = "",
            lastName = "",
            email = "",
        )
    )

    private var passcodeToken: String? = null
    private suspend fun beginProof(onSuccess: () -> Unit) {
        val api = selectedApi().api

        passcodeToken = api.emailProof.beginEmailOwnershipProof(user().email)

        onSuccess()
    }

    private suspend fun confirmPasscode(passcode: String, onSuccess: () -> Unit) {
        val api = selectedApi().api

        val proof = api.emailProof.proveEmailOwnership(FinishProof(passcodeToken!!, passcode))

        try {
            api.user.create(CreateUser(user(), proof))
        } catch (e: LsErrorException) {
            if (e.status == 400.toShort() && e.error.detail == "unique") {
               // If they already have an email registered then log them in
                if (e.error.message?.let { Regexes.isolateUniqueKey(it) } != "email") throw e
            }
        }

        val session = api.userAuth.logIn(listOf(proof))
        sessionToken.value = session.session

        currentSession.onNextSuccess(onSuccess)
    }

    fun ViewWriter.form() {
        col {
            spacing = 1.5.rem

            label {
                content = "Name"

                val first = user.lensPath { it.firstName }.validate { it.isNotBlank() }
                val last = user.lensPath { it.lastName }.validate { it.isNotBlank() }

                row {
                    expanding - fieldTheme - validate(first) - textInput {
                        hint = "First"
                        content bind first
                    }
                    expanding - fieldTheme - validate(last) - textInput {
                        hint = "Last"
                        content bind last
                    }
                }
            }

            label {
                content = "Phone Number"

                val phoneNumber = user.lensPath { it.phoneNumber }.validate { it.matches(Regexes.phoneNumber) }

                fieldTheme - validate(phoneNumber) - textInput {
                    hint = "123-456-7890"
                    content bind phoneNumber
                }
            }

            label {
                content = "Email"

                val email = user.lensPath { it.email }.validate { it.matches(Regexes.email) }

                fieldTheme - validate(email) - textInput {
                    hint = "fake@email.com"
                    content bind email
                }
            }

            label {
                content = "Company Name and/or Account Number"

                val company = user.lensPath { it.desiredCompany }.validate { !it.isNullOrBlank() }

                fieldTheme - validate(company) - textInput {
                    hint = "\"Fake Company\" or \"#ABC123\""
                    content bind company.nullToBlank()
                }
            }
        }
    }

    @Routable("/auth/register/start")
    inner class RegistrationScreen : Screen, AuthScreen {
        override fun ViewWriter.render() {
            scrolls - col {
                expanding - space()
                sizeConstraints(width = 35.rem) - centered - dialog - col {
                    h2("Register")

                    form()

                    atEnd - important - button {
                        ::enabled { allValid() }
                        centered - text("Create User")

                        onClick {
                            beginProof {
                                mainScreenNavigator.navigate(ConfirmEmailScreen())
                            }
                        }
                    }
                }
                expanding - space()
            }
        }
    }

    @Routable("/auth/register/confirm")
    inner class ConfirmEmailScreen : Screen, AuthScreen {

        private val pin = Property("").validate { it.length == 7 }

        private suspend fun ViewWriter.confirm() = confirmPasscode(pin()) { mainScreenNavigator.reset(CatalogScreen) }

        override fun ViewWriter.render() {
            stack {
                centered - card - col {
                    spacing = 1.5.rem

                    h2("Confirm Email")

                    text("Please confirm your email by entering the 7 letter code sent to \n${user.value.email}")

                    fieldTheme - validate(pin) - textInput {
                        hint = "Passcode"
                        keyboardHints = KeyboardHints.id
                        content bind pin
                        action = Action(
                            "Submit",
                            Icon.done
                        ) { confirm() }
                    }

                    important - button {
                        spacing = 0.5.rem

                        ::enabled { allValid() }

                        centered - text("Submit")

                        onClick { confirm() }
                    }
                }
            }
        }
    }
}