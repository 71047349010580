package com.virtualrain

object Regexes {
    val email = Regex("""[\w\-+._]+@(?:[\w\-]+\w\.)+[\w\-]+\w${'$'}""")
    val phoneNumber = Regex("^\\d{3}-?\\d{3}-?\\d{4}$")
    val lowercaseBoundary = Regex("\\b[a-z]")
    val link = Regex("^http://|https://.+")
    val uniqueErrorKeyRegex = Regex("Key (\\w+) already exists")
    fun isolateUniqueKey(input: String): String? {
        val match = uniqueErrorKeyRegex.find(input)
        return match?.groups?.get(1)?.value
    }
}