package com.virtualrain

import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.navigation.DefaultSerializersModule
import com.lightningkite.kiteui.navigation.ScreenNavigator
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.RContext
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.serialization.ClientModule
import com.virtualrain.theming.appTheme
import kotlinx.browser.document
import kotlinx.coroutines.GlobalScope
import kotlin.coroutines.CoroutineContext

fun main() {
    val writer = object : ViewWriter() {
        override val context: RContext = RContext("/")
        override val coroutineContext: CoroutineContext
            get() = GlobalScope.coroutineContext

        override fun addChild(view: RView) {
            document.body?.append(view.native.create())
        }
    }

    writer.beforeNextElementSetup {
        ::themeChoice { ThemeDerivation(appTheme.await()) }
    }

    writer.app(ScreenNavigator { AutoRoutes }, ScreenNavigator { AutoRoutes })
}