package com.virtualrain.admin

import com.ilussobsa.views.containsDynamic
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.locale.renderDateToString
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.LimitReadable
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.now
import com.lightningkite.serialization.*

import com.virtualrain.mappings.toLocalDateTime
import com.virtualrain.models.*
import com.virtualrain.sdk.currentSession
import com.virtualrain.sdk.utils.pushChanges
import com.virtualrain.setName
import com.virtualrain.theming.*
import com.virtualrain.validation.Validator
import com.virtualrain.views.components.pageChildren
import com.virtualrain.views.emptyView
import com.virtualrain.views.textFormatting.format
import kotlin.time.Duration.Companion.days

@Routable("/admin/price-increases")
class PriceIncreasesScreen : Screen {
    override val title: Readable<String> = Constant("Price Increases")

    private val showPastIncreases = Property(true)

    private val now = now()
    private val priceIncreases = shared {
        currentSession().priceIncreases.query(
            Query(
                condition = if (showPastIncreases()) Condition.Always() else condition { it.increasesAt gt now },
                orderBy = sort { it.increasesAt.ascending() }
            )
        )()
    }
    private val upcoming = shared {
        if (showPastIncreases()) priceIncreases().filter { it.increasesAt > now } else priceIncreases()
    }

    override fun ViewWriter.render() {
        col {
            row {
                expanding - centered - row {
                    centered - switch {
                        checked bind showPastIncreases
                    }
                    centered - text("Show Past Increases")
                }

                buttonTheme - button {
                    row {
                        centered - icon { source = Icon.add }
                        centered - text("New Price Increase")
                    }

                    onClick { mainScreenNavigator.navigate(PriceIncreaseForm(UUID.random())) }
                }
            }

            separator()

            expanding - recyclerView {
                ::exists { priceIncreases().isNotEmpty() }

                children(priceIncreases) { increase ->
                    card - button {
                        dynamicTheme {
                            if (increase().increasesAt <= now) DisabledSemantic else null
                        }

                        col {
                            bold - row {
                                expanding - text { ::content { increase().increasesAt.format() } }

                                text { ::content { "${increase().products.size} products" } }
                            }

                            text {
                                ::exists { increase().message.isNotBlank() }
                                ::content { increase().message }
                            }
                        }

                        onClick { mainScreenNavigator.navigate(PriceIncreaseForm(increase()._id)) }
                    }
                }
            }

            expanding - emptyView {
                exists = false
                ::exists { upcoming().isEmpty() }

                centered - h3("Nothing Scheduled")
            }
        }
    }


}

@Routable("/admin/price-increases/{id}")
class PriceIncreaseForm(val id: UUID) : Screen, Validator() {
    val existing = shared { currentSession().priceIncreases[id]() }
    val starting = shared {
        existing() ?: PriceIncrease(
            increasesAt = now() + 1.days,
            message = "",
            products = emptySet()
        )
    }
    private val draft = Draft { starting() }

    private val products = draft.lensPath { it.products }
    private val selectedProducts = shared {
        val ids = products()
        currentSession().products.query(
            Query { it._id inside ids }
        )
    }

    private val allowedFields = Product.path.run {
        setOf(
            title.setName("Title"),
            erpId.setName("Product #"),
            manufacturer.setName("Manufacturer"),
            manufacturerDescription.setName("Description")
        )
    }
    private val searchFields = Property(allowedFields)

    private val query = Property("")
    private val debounced = query.debounce(600)

    val now = now()
    private val otherFutureIncreases = shared {
        currentSession().priceIncreases.query(
            Query(
                condition = condition { it.increasesAt.gt(now) and it._id.neq(id) },
                orderBy = sort { it.increasesAt.ascending() }
            )
        )()
    }
    private val searched = shared {
        val q = debounced()
        currentSession().products.query(
            Query(
                Condition.Or(
                    searchFields().map { it.mapCondition(Condition.StringContains(q, true)) }
                ),
                orderBy = sort { it.title.ascending() },
                limit = 200,
            )
        )
    }

    fun ViewWriter.icon(icon: Icon) {
        icon { source = icon }
    }

    private fun ViewWriter.chooseFromProducts(list: Readable<LimitReadable<Product>>, setup: RView.() -> Unit = {}) {
        recyclerView {
            setup()
            pageChildren(list) { product ->
                lightOutline - row {
                    centered - checkbox {
                        checked bind products.containsDynamic { product()._id }
                    }
                    expanding - col {
                        spacing = 0.25.rem
                        text {
                            wraps = false
                            ellipsis = true
                            ::content { product().title }
                        }
                        row {
                            subtext {
                                ::content { product().erpId }
                            }

                            centered - subtext("-")

                            subtext {
                                ::content { product().manufacturer }
                            }
                        }
                        val existingIncrease = shared {
                            val id = product()._id
                            otherFutureIncreases().find { id in it.products }
                        }
                        onlyWhen { existingIncrease() != null } - row {
                            icon(Icon.warning.copy(1.rem, 1.rem), "Warning")
                            subtext { ::content{ "Already marked for future increase on ${existingIncrease()?.increasesAt?.renderDateToString()}" } }
                        }
                    }
                }
            }
        }
    }

    override fun ViewWriter.render() {
        expanding - card - col {

            expanding - row {
                spacing = 2.rem

                expanding - col {
                    row {
                        spacing = 0.4.rem

                        important - menuButton {
                            preferredDirection = PopoverPreferredDirection.belowRight

                            row {
                                spacing = 0.px
                                icon(Icon.search)
                                icon(Icon.chevronDown)
                            }

                            opensMenu {
                                col {
                                    for (prop in allowedFields) {
                                        row {
                                            checkbox {
                                                checked bind searchFields.contains(prop)
                                            }
                                            centered - text("Search by $prop")
                                        }
                                    }
                                }
                            }
                        }

                        important - expanding - row {
                            expanding - textInput {
                                ::hint {
                                    "Search by ${searchFields().joinToString(", ")}"
                                }
                                content bind query
                            }

                            centered - button {
                                spacing = 0.px
                                icon(Icon.close, "Clear Search")
                                onClick { query set "" }
                            }
                        }
                    }

                    expanding - lightOutline - chooseFromProducts(searched) {
                        spacing = 0.px
                    }
                }

                separator()

                expanding - col {
                    spacing = 1.5.rem

                    label {
                        content = "When"

                        // TODO: This validation system can't handle entering null on non-null fields
                        fieldTheme - localDateTimeField {
                            content bind draft.lensPath { it.increasesAt }.toLocalDateTime().nullable()
                        }
                    }

                    label {
                        content = "Message"

                        fieldTheme - textArea {
                            content bind draft.lensPath { it.message }
                        }
                    }

                    expanding - label {
                        content = "Products"

                        expanding - lightOutline - embedded - validate {
                            products().isNotEmpty()
                        } - col {
                            expanding - chooseFromProducts(selectedProducts)
                        }
                    }
                }
            }

            row {
                expanding - row {
                    onlyWhen { existing() != null } - sizeConstraints(width = 10.rem) - danger - button {
                        spacing = 0.5.rem

                        centered - row {
                            icon(Icon.delete, "Remove Flag")
                            centered - text("Delete")
                        }

                        onClick {
                            confirmDanger(
                                "Remove Price Increase",
                                "Are you sure you want to remove this price increase? This cannot be undone.",
                                "Confirm"
                            ) {
                                currentSession().priceIncreases[this@PriceIncreaseForm.id].delete()
                                dialogScreenNavigator.clear()
                            }
                        }
                    }
                }

                onlyWhen { existing() != null } - important - button {
                    ::enabled { draft.changesMade() }
                    icon(Icon.close, "Discard Changes")
                    onClick { draft.cancel() }
                }

                sizeConstraints(width = 8.5.rem) - important - button {
                    ::enabled { allValid() and draft.changesMade() }

                    centered - row {
                        icon {
                            ::source { if (existing() == null) Icon.send else Icon.save }
                        }
                        centered - text {
                            ::content { if (existing() == null) "Publish" else "Save" }
                        }
                    }

                    onClick {
                        draft.pushChanges { currentSession().priceIncreases }
                        screenNavigator.dismiss()
                    }
                }
            }
        }
    }

}