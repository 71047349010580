package com.virtualrain.admin

import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.inside
import com.lightningkite.uuid
import com.virtualrain.models.Order
import com.virtualrain.models.Product
import com.virtualrain.models.Product__id
import com.virtualrain.models._id
import com.virtualrain.sdk.currentSession
import com.virtualrain.views.screens.account.orders.*
import com.virtualrain.views.textFormatting.format

@Routable("/orders/{orderId}")
class AdminOrderDetailScreen(val orderId: UUID) : Screen {
    override val title: Readable<String> = Constant<String>("Order Details")

    val order = shared {
            currentSession().orders[orderId]()
    }

    val products = shared {
        val session = currentSession()

        val products: List<UUID> = order()!!.items.map { it.product }

        session.products.query(
            Query(
                condition {
                    it._id inside products
                }
            )
        )()

    }

    override fun ViewWriter.render() = scrolls - col {
         val showErpData = Property<Boolean>(false)
         col {
            card - col {
                row {
                    expanding - h2 {
                        ::content{ "ERP Order: ${order()?.erpId}" }
                    }
                    button {
                        row {
                            icon(Icon.arrowBack, "Back to orders")
                            centered - text("Back to Orders")
                        }

                        onClick {
                            screenNavigator.navigate(AdminOrdersScreen())
                        }
                    }
//                    button {
//                        centered - text("Export")
//                    }
//                    button {
//                        centered - text("Print")
//                    }
                }
                fun ViewWriter.line(label: String, detail: ReactiveContext.() -> String) = row {
                    text(label)
                    bold - text {
                        ::content { detail() }
                    }
                }
                line("Order ID: ") { order()?._id.toString() }
                line("Date: ") { order()?.orderedAt?.format() ?: "" }
                line("Company:") {
                    order()?.account.let {

                    if (it != null) {
                        (currentSession().customerAccounts.get(it).invoke()?.address?.businessName ?: "").toString()
                    } else ""

                }}
                line("Acc #:") {
                    order()?.account.let {

                        if (it != null) {
                            (currentSession().customerAccounts[it].invoke()?.erpId ?: "").toString()
                        } else ""

                    }

                }
                line("PO #:") { order()?.poNum ?: "" }
                line("Status:") { order()?.status?.code.toString() }

                col {
                    ::exists{ order()?.shipTo !== null }
                    h4("Shipping Location:")
                    text {
                        ::content{
                            order()?.shipTo?.let {
                                currentSession().shippingAddresses.get(it).invoke()?.address?.format()
                            }
                                .toString()
                        }
                    }

                }
                col {
                    ::exists{ order()?.warehouse !== null }
                    h4("Pickup Location:")
                    text {
                        ::content{
                            order()?.warehouse?.let { currentSession().warehouses.get(it).invoke()?.address?.format() }
                                .toString()
                        }
                    }
                    text {
                        ::content {
                            order()?.account.let {

                                if (it != null) {
                                    currentSession().customerAccounts.get(it).invoke()?.phoneNumber ?: ""
                                } else ""

                            }
                        }
                    }
                }
                col {
                    ::exists{ order()?.warehouse == null && order()?.shipTo == null }
                    text("No shipping or pickup location specified.")
                }
                col {
                    ::exists { order()?.comment !== "" }
                    h4("Comments:")
                    text {
                        ::content { order()?.comment ?: "" }
                    }
                }
                row {
                    weight(1f) - col { bold - text("Item") }
                    weight(1f) - col { bold - text("Part #") }
                    weight(1f) - col { bold - text("Qty") }
                    weight(1f) - col { bold - text("Unit") }
                    weight(1f) - col { bold - text("List Price") }
                }
                expanding - col {
                    expanding - padded - recyclerView {
                        children(products) { item: Readable<Product> ->
                            row {
                                weight(2f) - col {
                                    row {
                                        bold - text { ::content{ item().title } }
                                        subtext { ::content{ item().manufacturer } }
                                    }
                                }
                                weight(1f) - col {
                                    text { ::content{ item().erpId.toString() } }
                                }
                                col { text { ::content{ item().quantityType.toString() } } }
                                col { text("EA") }
                                weight(1f) - col {
//                            text{::content{"$${item().listPrice}"} //use new pricing endpoint currently in pr
                                }
                            }
                        }
                    }

                }
                text {
                    align = Align.End
                    ::content{ "SUB TOTAL  ${order()?.total}" }
                }
            }
        }
        sizeConstraints(maxWidth = 15.rem) - toggleButton {
            centered - text("ERP Request Data")
            checked bind showErpData
        }

        col {
           ::exists{ showErpData() }
            col {
                ::exists{ order()?.requestJson?.isNotBlank() == true && order()?.responseJson?.isNotBlank() == true }
                h4("Raw Request")
                text { ::content{ order()?.requestJson ?: "" } }
                h4("Raw Response")
                text { ::content{ order()?.responseJson ?: "" } }

            }
            text {
                ::exists{ order()?.requestJson?.isNotBlank() == false && order()?.responseJson?.isNotBlank() == false }
                content = "No request data was found."
            }
        }

    }
}