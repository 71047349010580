package com.virtualrain.sdk.erp

import com.lightningkite.UUID
import com.lightningkite.kiteui.CancelledException
import com.lightningkite.kiteui.Console
import com.lightningkite.kiteui.ConsoleRoot
import com.lightningkite.kiteui.reactive.*
import com.virtualrain.models.*
import com.virtualrain.sdk.UserSession
import com.virtualrain.sdk.utils.CachedCalculation
import kotlin.time.Duration

class PricingCache(val session: UserSession, val cacheTime: Duration) {
    private val cache = HashMap<PricingRequestInfo, CachedCalculation<Set<CartItem>>>()

    private val log = ConsoleRoot.tag("PricingCache")
    private val debug: Console? = null

    private fun pricingFail(reason: String) = log.warn("Pricing request not sent: $reason")
    fun request(info: Priceable): Readable<Set<CartItem>> {
        if(session.accountId == null && session.role <= UserRole.Representative) return Constant(setOf())
        debug?.log("PRICING REQ")
        val i = info.toPricingRequestInfo()
        return cache.getOrPut(i) {
            debug?.log("PRICING REQ NEW")
            CachedCalculation(cacheTime) {
                debug?.log("PRICING REQ NEW ACTIVE")
                session.nonCached.getERPPricing(i).toSet()
            }
        }
    }
}