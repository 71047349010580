// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareCartFields() {
    val props: Array<SerializableProperty<Cart, *>> = arrayOf(Cart__id, Cart_account, Cart_warehouse, Cart_shipTo, Cart_shipASAP, Cart_name, Cart_keepAfterOrder, Cart_createdAt, Cart_previousOrder, Cart_items)
    Cart.serializer().properties { props }
}
val <K> DataClassPath<K, Cart>._id: DataClassPath<K, UUID> get() = this[Cart__id]
val <K> DataClassPath<K, Cart>.account: DataClassPath<K, UUID> get() = this[Cart_account]
val <K> DataClassPath<K, Cart>.warehouse: DataClassPath<K, UUID> get() = this[Cart_warehouse]
val <K> DataClassPath<K, Cart>.shipTo: DataClassPath<K, UUID?> get() = this[Cart_shipTo]
val <K> DataClassPath<K, Cart>.shipASAP: DataClassPath<K, Boolean?> get() = this[Cart_shipASAP]
val <K> DataClassPath<K, Cart>.name: DataClassPath<K, String?> get() = this[Cart_name]
val <K> DataClassPath<K, Cart>.keepAfterOrder: DataClassPath<K, Boolean> get() = this[Cart_keepAfterOrder]
val <K> DataClassPath<K, Cart>.createdAt: DataClassPath<K, Instant> get() = this[Cart_createdAt]
val <K> DataClassPath<K, Cart>.previousOrder: DataClassPath<K, UUID?> get() = this[Cart_previousOrder]
val <K> DataClassPath<K, Cart>.items: DataClassPath<K, Set<CartItem>> get() = this[Cart_items]
inline val Cart.Companion.path: DataClassPath<Cart, Cart> get() = path<Cart>()


object Cart__id: SerializableProperty<Cart, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Cart): UUID = receiver._id
    override fun setCopy(receiver: Cart, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Cart.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object Cart_account: SerializableProperty<Cart, UUID> {
    override val name: String = "account"
    override fun get(receiver: Cart): UUID = receiver.account
    override fun setCopy(receiver: Cart, value: UUID) = receiver.copy(account = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Cart.serializer().tryFindAnnotations("account")
}
object Cart_warehouse: SerializableProperty<Cart, UUID> {
    override val name: String = "warehouse"
    override fun get(receiver: Cart): UUID = receiver.warehouse
    override fun setCopy(receiver: Cart, value: UUID) = receiver.copy(warehouse = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Cart.serializer().tryFindAnnotations("warehouse")
}
object Cart_shipTo: SerializableProperty<Cart, UUID?> {
    override val name: String = "shipTo"
    override fun get(receiver: Cart): UUID? = receiver.shipTo
    override fun setCopy(receiver: Cart, value: UUID?) = receiver.copy(shipTo = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = Cart.serializer().tryFindAnnotations("shipTo")
    override val default: UUID? = null
}
object Cart_shipASAP: SerializableProperty<Cart, Boolean?> {
    override val name: String = "shipASAP"
    override fun get(receiver: Cart): Boolean? = receiver.shipASAP
    override fun setCopy(receiver: Cart, value: Boolean?) = receiver.copy(shipASAP = value)
    override val serializer: KSerializer<Boolean?> = Boolean.serializer().nullable2
    override val annotations: List<Annotation> = Cart.serializer().tryFindAnnotations("shipASAP")
    override val default: Boolean? = null
}
object Cart_name: SerializableProperty<Cart, String?> {
    override val name: String = "name"
    override fun get(receiver: Cart): String? = receiver.name
    override fun setCopy(receiver: Cart, value: String?) = receiver.copy(name = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Cart.serializer().tryFindAnnotations("name")
    override val default: String? = null
}
object Cart_keepAfterOrder: SerializableProperty<Cart, Boolean> {
    override val name: String = "keepAfterOrder"
    override fun get(receiver: Cart): Boolean = receiver.keepAfterOrder
    override fun setCopy(receiver: Cart, value: Boolean) = receiver.copy(keepAfterOrder = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = Cart.serializer().tryFindAnnotations("keepAfterOrder")
    override val default: Boolean = false
}
object Cart_createdAt: SerializableProperty<Cart, Instant> {
    override val name: String = "createdAt"
    override fun get(receiver: Cart): Instant = receiver.createdAt
    override fun setCopy(receiver: Cart, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Cart.serializer().tryFindAnnotations("createdAt")
    override val default: Instant = now()
}
object Cart_previousOrder: SerializableProperty<Cart, UUID?> {
    override val name: String = "previousOrder"
    override fun get(receiver: Cart): UUID? = receiver.previousOrder
    override fun setCopy(receiver: Cart, value: UUID?) = receiver.copy(previousOrder = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = Cart.serializer().tryFindAnnotations("previousOrder")
    override val default: UUID? = null
}
object Cart_items: SerializableProperty<Cart, Set<CartItem>> {
    override val name: String = "items"
    override fun get(receiver: Cart): Set<CartItem> = receiver.items
    override fun setCopy(receiver: Cart, value: Set<CartItem>) = receiver.copy(items = value)
    override val serializer: KSerializer<Set<CartItem>> = SetSerializer(CartItem.serializer())
    override val annotations: List<Annotation> = Cart.serializer().tryFindAnnotations("items")
    override val default: Set<CartItem> = emptySet()
}
