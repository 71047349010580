// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareProductCategoryFields() {
    val props: Array<SerializableProperty<ProductCategory, *>> = arrayOf(ProductCategory__id, ProductCategory_name, ProductCategory_productCount, ProductCategory_path, ProductCategory_image, ProductCategory_parent)
    ProductCategory.serializer().properties { props }
}
val <K> DataClassPath<K, ProductCategory>._id: DataClassPath<K, UUID> get() = this[ProductCategory__id]
val <K> DataClassPath<K, ProductCategory>.name: DataClassPath<K, String> get() = this[ProductCategory_name]
val <K> DataClassPath<K, ProductCategory>.productCount: DataClassPath<K, Int> get() = this[ProductCategory_productCount]
val <K> DataClassPath<K, ProductCategory>.path: DataClassPath<K, String> get() = this[ProductCategory_path]
val <K> DataClassPath<K, ProductCategory>.image: DataClassPath<K, ServerFile?> get() = this[ProductCategory_image]
val <K> DataClassPath<K, ProductCategory>.parent: DataClassPath<K, UUID?> get() = this[ProductCategory_parent]
inline val ProductCategory.Companion.path: DataClassPath<ProductCategory, ProductCategory> get() = path<ProductCategory>()


object ProductCategory__id: SerializableProperty<ProductCategory, UUID> {
    override val name: String = "_id"
    override fun get(receiver: ProductCategory): UUID = receiver._id
    override fun setCopy(receiver: ProductCategory, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ProductCategory.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object ProductCategory_name: SerializableProperty<ProductCategory, String> {
    override val name: String = "name"
    override fun get(receiver: ProductCategory): String = receiver.name
    override fun setCopy(receiver: ProductCategory, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ProductCategory.serializer().tryFindAnnotations("name")
}
object ProductCategory_productCount: SerializableProperty<ProductCategory, Int> {
    override val name: String = "productCount"
    override fun get(receiver: ProductCategory): Int = receiver.productCount
    override fun setCopy(receiver: ProductCategory, value: Int) = receiver.copy(productCount = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = ProductCategory.serializer().tryFindAnnotations("productCount")
    override val default: Int = 0
}
object ProductCategory_path: SerializableProperty<ProductCategory, String> {
    override val name: String = "path"
    override fun get(receiver: ProductCategory): String = receiver.path
    override fun setCopy(receiver: ProductCategory, value: String) = receiver.copy(path = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ProductCategory.serializer().tryFindAnnotations("path")
}
object ProductCategory_image: SerializableProperty<ProductCategory, ServerFile?> {
    override val name: String = "image"
    override fun get(receiver: ProductCategory): ServerFile? = receiver.image
    override fun setCopy(receiver: ProductCategory, value: ServerFile?) = receiver.copy(image = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = ProductCategory.serializer().tryFindAnnotations("image")
    override val default: ServerFile? = null
}
object ProductCategory_parent: SerializableProperty<ProductCategory, UUID?> {
    override val name: String = "parent"
    override fun get(receiver: ProductCategory): UUID? = receiver.parent
    override fun setCopy(receiver: ProductCategory, value: UUID?) = receiver.copy(parent = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = ProductCategory.serializer().tryFindAnnotations("parent")
    override val default: UUID? = null
}
