package com.virtualrain.mappings

import com.lightningkite.kiteui.reactive.*
import com.lightningkite.lightningdb.HasId
import com.lightningkite.lightningserver.files.ServerFile
import kotlinx.coroutines.launch
import kotlin.js.JsName
import kotlin.jvm.JvmName
import kotlinx.datetime.*

fun <T: Any> Writable<T>.nullable(): Writable<T?> =
    object : Writable<T?> {
        override val state: ReadableState<T?> get() = this@nullable.state
        override fun addListener(listener: () -> Unit): () -> Unit = this@nullable.addListener(listener)

        override suspend fun set(value: T?) {

            if (value != null) this@nullable.set(value)
        }
    }

fun <T: Any> Writable<T>.asNullable(): Writable<T?> =
    object : Writable<T?> {
        val listeners = ArrayList<()->Unit>()
        var remover: (()->Unit)? = null
        var nullSet = false

        override val state: ReadableState<T?>
            get() = if (nullSet) ReadableState(null) else this@asNullable.state

        override fun addListener(listener: () -> Unit): () -> Unit {
            listeners.add(listener)
            if (listeners.size == 1) {
                remover = this@asNullable.addListener { listeners.invokeAllSafe() }
            }
            return {
                listeners.remove(listener)
                if (listeners.size <= 0) remover?.invoke()
            }
        }

        override suspend fun set(value: T?) {
            if (value == null) {
                nullSet = true
                listeners.invokeAllSafe()
                return
            }
            else {
                nullSet = false
                this@asNullable.set(value)
            }
        }
    }

fun Writable<Instant>.toLocalDateTime(): Writable<LocalDateTime> {
    val timeZone = TimeZone.currentSystemDefault()
    return lens(
        get = { it.toLocalDateTime(timeZone) },
        set = { it.toInstant(timeZone) }
    )
}

fun Writable<ServerFile>.location(): Writable<String> = lens(
    get = { it.location },
    set = { ServerFile(it) }
)

fun Writable<ServerFile?>.nullToBlank(): Writable<String> = lens(
    get = { it?.location ?: "" },
    set = { str -> str.takeUnless { it.isBlank() }?.let { ServerFile(it) } }
)

fun <T, D: T> Writable<T>.equalToDynamic(other: Readable<D>): Writable<Boolean> = shared { this@equalToDynamic() == other() }
    .withWrite { if(it) this@equalToDynamic.set(other()) }

fun <T, V> Writable<T>.dynamicLens(
    get: ReactiveContext.(T) -> V,
    set: suspend (V) -> T,
): Writable<V> = shared {
    get(this@dynamicLens())
}.withWrite {
    this@dynamicLens set set(it)
}

fun <T, V> Writable<T>.calculatingLens(
    get: ReactiveContext.(T) -> V,
    set: suspend (V) -> T,
) = object: Writable<V> {
    val late = LateInitProperty<Unit>().apply { value = Unit }

    val shared = shared {
        late()
        get(this@calculatingLens())
    }

    override val state: ReadableState<V> get() = shared.state
    override fun addListener(listener: () -> Unit): () -> Unit = shared.addListener(listener)

    fun setLoading() = late.unset()
    fun setComplete() { late.value = Unit }

    override suspend fun set(value: V) {
        setLoading()

        this@calculatingLens set set(value)

        setComplete()
    }
}

fun <T: HasId<ID>, ID: Comparable<ID>> Writable<ID>.fromData(
    data: Readable<Collection<T>>,
    default: ReactiveContext.(Collection<T>) -> T,
): Writable<T> = dynamicLens(
    set = { it._id },
    get = { id ->
        data().firstOrNull { it._id == id } ?: default(data.once()).also { launch { this@fromData set it._id } }
    }
)

fun <T: HasId<ID>, ID: Comparable<ID>> Writable<ID>.fromData(
    data: Readable<Collection<T>>
): Writable<T> = dynamicLens(
    set = { it._id },
    get = { id -> data().firstOrNull { it._id == id } ?: throw NoSuchElementException("fromData found no data with id $id") }
)

@JsName("fromDataNullable")
@JvmName("fromDataNullable")
fun <T: HasId<ID>, ID: Comparable<ID>> Writable<ID?>.fromData(
    data: Readable<Collection<T?>>
): Writable<T?> = dynamicLens(
    set = { it?._id },
    get = { id -> data().find { it?._id == id } }
)