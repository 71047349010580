package com.virtualrain.views.screens.products

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.LimitReadable
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import com.virtualrain.models.*
import com.virtualrain.sdk.currentSession
import com.virtualrain.views.components.productCard
import com.virtualrain.views.screens.common.HasNarrowContent

@Routable("/Search")
class SearchScreen: Screen, HasNarrowContent {

    @QueryParameter
    val query = Property("")

    val foundProductsMeta = shared {
        val session = currentSession()
        val q = query.debounce(500)()

        if (q.isBlank()) object: LimitReadable<Product> {
            override val state: ReadableState<List<Product>> = ReadableState(listOf())
            override var limit: Int = 1000
            override fun addListener(listener: () -> Unit): () -> Unit = {}
        }
        session.products.query(Query(
            if (q.isBlank()) Condition.Never()
            else Condition.And(q.split(' ').map { part -> Condition.Or(listOf(
                condition { it.title.contains(part, true) },
                condition { it.erpId.contains(part, true) },
                condition { it.manufacturer.contains(part, true) },
            )) }),
            orderBy = sort { it.title.ascending() }
        ))
    }
    val foundProducts = shared { foundProductsMeta()() }

    override fun ViewWriter.render() {
        col {
            sizeConstraints(height = 3.rem) - row {
                expanding - important - row {
                    centered - icon { source = Icon.search }

                    expanding - textInput {
                        hint = "Search product titles, part-numbers, manufacturers, and descriptions.."

                        content bind query

                        requestFocus()
                    }

                    button {
                        spacing = 0.rem

                        icon { source = Icon.close }
                        onClick { query.value = "" }
                    }
                }
            }

            expanding - recyclerView {
                reactiveScope {
                    if(lastVisibleIndex() > foundProductsMeta().limit - 20)
                        foundProductsMeta().limit = lastVisibleIndex() + 100
                }
                ::exists { query().isNotBlank() }
                ::columns {
                    if (HasNarrowContent.narrow()) 1
                    else 2
                }

                children(foundProducts) {
                    card - productCard(it, imageHeight = 5.rem)
                }
            }
        }
    }
}