package com.virtualrain.theming

import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem

val Icon.Companion.heartFilled: Icon
    get() = Icon(
        width = 2.rem,
        height = 2.rem,
        viewBoxMinX = 0,
        viewBoxMinY = 0,
        viewBoxWidth = 24,
        viewBoxHeight = 24,
        pathDatas = listOf(
            "m12 21.35-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54z"
        )
    )

val Icon.Companion.heart: Icon
    get() = Icon(
        width = 2.rem,
        height = 2.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf(
            "m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
        )
    )

val Icon.Companion.cart: Icon
    get() = Icon(
        width = 2.rem,
        height = 2.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf(
            "M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z"
        )
    )

val Icon.Companion.addCart: Icon
    get() = Icon(
        width = 2.rem,
        height = 2.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf(
            "M440-600v-120H320v-80h120v-120h80v120h120v80H520v120h-80ZM280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM40-800v-80h131l170 360h280l156-280h91L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68.5-39t-1.5-79l54-98-144-304H40Z"
        )
    )

val Icon.Companion.save: Icon
    get() = Icon(
        width = 2.rem,
        height = 2.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf(
            "M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z"
        )
    )

val Icon.Companion.edit: Icon
    get() = Icon(
        width = 2.rem,
        height = 2.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf(
            "M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"
        )
    )

val Icon.Companion.ship: Icon
    get() = Icon(
        width = 2.rem,
        height = 2.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf(
            "M240-160q-50 0-85-35t-35-85H40v-440q0-33 23.5-56.5T120-800h560v160h120l120 160v200h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T280-280q0-17-11.5-28.5T240-320q-17 0-28.5 11.5T200-280q0 17 11.5 28.5T240-240ZM120-360h32q17-18 39-29t49-11q27 0 49 11t39 29h272v-360H120v360Zm600 120q17 0 28.5-11.5T760-280q0-17-11.5-28.5T720-320q-17 0-28.5 11.5T680-280q0 17 11.5 28.5T720-240Zm-40-200h170l-90-120h-80v120ZM360-540Z"
        )
    )

val Icon.Companion.location: Icon
    get() = Icon(
        width = 2.rem,
        height = 2.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf(
            "M480-301q99-80 149.5-154T680-594q0-90-56-148t-144-58q-88 0-144 58t-56 148q0 65 50.5 139T480-301Zm0 101Q339-304 269.5-402T200-594q0-125 78-205.5T480-880q124 0 202 80.5T760-594q0 94-69.5 192T480-200Zm0-320q33 0 56.5-23.5T560-600q0-33-23.5-56.5T480-680q-33 0-56.5 23.5T400-600q0 33 23.5 56.5T480-520ZM200-80v-80h560v80H200Zm280-520Z"
        )
    )


val Icon.Companion.chevronUp: Icon
    get() = Icon(
        width = 2.rem,
        height = 2.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf(
            "M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z"
        )
    )

val Icon.Companion.chevronDown: Icon
    get() = Icon(
        width = 2.rem,
        height = 2.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf(
            "M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"
        )
    )

val Icon.Companion.show: Icon
    get() = Icon(
        width = 2.rem,
        height = 2.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf(
            "M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"
        )
    )

val Icon.Companion.hide: Icon
    get() = Icon(
        width = 2.rem,
        height = 2.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf(
            "m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"
        )
    )

val Icon.Companion.link: Icon
    get() = Icon(
        width = 1.5.rem,
        height = 1.5.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf(
            "M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z"
        )
    )

val Icon.Companion.addLink: Icon
    get() = Icon(
        width = 1.5.rem,
        height = 1.5.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf(
            "M680-160v-120H560v-80h120v-120h80v120h120v80H760v120h-80ZM440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm560-40h-80q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480Z"
        )
    )

val Icon.Companion.upload: Icon
    get() = Icon(
        width = 1.5.rem,
        height = 1.5.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf(
            "M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"
        )
    )