package com.virtualrain.views.components

import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.eq
import com.lightningkite.lightningdb.modification
import com.lightningkite.serialization.lensPath
import com.lightningkite.serialization.map
import com.virtualrain.mappings.*
import com.virtualrain.models.*
import com.virtualrain.sdk.currentSession
import com.virtualrain.sdk.erp.PricingCache
import com.virtualrain.sdk.utils.activeWarehouses
import com.virtualrain.sdk.utils.preferredWarehouse
import com.virtualrain.theming.ghost
import com.virtualrain.views.screens.cart.Carts
import com.virtualrain.views.screens.common.userHasAccount
import com.virtualrain.views.textFormatting.formatName
import kotlinx.coroutines.launch

fun RView.quantityField(product: Readable<Product>, expand: Boolean = false): Readable<CartItem?> {
    val currentQuantity = shared {
        val pid = product()._id
        Carts.Selected().items.find { it.product == pid }?.quantity
    }
    val desiredQuantity = LateInitProperty<Int?>()
    reactive { desiredQuantity.value = currentQuantity() }
    val pricing = shared {
        val currentCart = Carts.selected.awaitNotNull()
        val productId = product()._id
        val submission = currentCart.copy(
            items = currentCart.items.filter { it.product != productId }.toSet() + CartItem(
                product = product()._id,
                productErpId = product().erpId,
                quantity = desiredQuantity()?.takeIf { it > 0 } ?: 1
            )
        )
        println("Submission is ${submission.items.joinToString("\n") { it.productErpId + ": " + it.quantity }}")

        currentSession().pricing.run {
            request(submission)().also { println("Submission result is $it") }.find { it.product == productId }
        }
    }

    fieldTheme - row {
        spacing = 0.5.rem
        ::exists { currentSession().mayOrder }

        if (expand) expanding else sizeConstraints(width = 6.rem)
        padded
        val n = numberInput {
            keyboardHints = KeyboardHints.integer
            hint = "Quantity"
            align = Align.Start

            content bind desiredQuantity.asDouble()
        }
        sizeConstraints(width = 7.5.rem) - buttonTheme - button {
            spacing = 0.rem
            centered - text {
                ::content {
                    if (desiredQuantity() == currentQuantity()) {
                        if (currentQuantity() == null) "Add to Cart"
                        else "In Cart"
                    } else {
                        if (desiredQuantity() == 0 || desiredQuantity() == null) "Remove from Cart"
                        else if (currentQuantity() != 0 || currentQuantity() == null) "Update Cart"
                        else "Add to Cart"
                    }
                }
            }

            ::enabled { currentQuantity() == null || currentQuantity() != desiredQuantity() }

            onClickAssociatedField(n, "Submit", Icon.send) {
                val pricing = pricing()
                Carts.selected.modify(modification<Cart> {
                    val q = desiredQuantity() ?: 1
                    if (q > 0 && currentQuantity() != null) {
                        println("UPDATE EXISTING")
                        it.items.forEachIf(
                            condition = { it.product eq product()._id },
                            modification = {
                                it.quantity assign (pricing?.quantity ?: q)
                                it.previousLineTotal assign pricing?.previousLineTotal
                                it.previousListPrice assign pricing?.previousListPrice
                                it.previousPrice assign pricing?.previousPrice
                                it.previousDiscountType assign pricing?.previousDiscountType
                            }
                        )
                    } else if (q > 0 && currentQuantity() == null) {
                        println("INSERT")
                        it.items.addAll(setOf(pricing ?: CartItem(product()._id, product().erpId, q)))
                    } else {
                        println("REMOVE")
                        it.items.removeAll { it.product eq product()._id }
                    }
                })
            }
        }
    }

    return pricing
}

fun ViewWriter.selectSourceWarehouse(expand: Boolean = false) {

    val warehouses = shared { activeWarehouses()() }

    label {
        content = "Warehouse"

        if (expand) expanding
        fieldTheme - select {
            ::enabled { currentSession().mayOrder }
            bind(
                edits = Carts.Selected.lensPath { it.warehouse }.fromData(warehouses) { preferredWarehouse() },
                data = warehouses,
                render = { it.formatName() }
            )
        }
    }
}