package com.virtualrain.views.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.virtualrain.defaultImage
import com.virtualrain.models.*
import com.virtualrain.sdk.currentSession
import com.virtualrain.theming.heartFilled
import com.virtualrain.views.screens.products.CategoryScreen
import com.virtualrain.views.screens.products.ProductScreen
import com.virtualrain.views.screens.products.favorites

fun ViewWriter.productLink(product: Readable<Product>, contents: Link.() -> Unit) = link {
    ::to {
        val prod = product();
        { ProductScreen(prod._id).also { it.product.value = prod } }
    }

    contents()
}

fun ViewWriter.productImage(product: Readable<Product>, setup: RView.() -> Unit = {}) {
    stack {
        spacing = 0.rem
        themeChoice += ThemeDerivation {
            it.copy(
                id = "whiteBackImage",
                background = Color.white
            ).withBack
        }

        image {
            setup()
            scaleType = ImageScaleType.Fit
            ::source {
                val prod = product()
                ImageRemote((prod.image ?: defaultImage()).location)
            }
        }
    }
}

fun ViewWriter.productCard(product: Readable<Product>, imageHeight: Dimension) = productLink(product) {
    row {
        sizeConstraints(width = imageHeight, height = imageHeight) - productImage(product)

        expanding - col {
            spacing = 0.px
            text { ::content { product().title } }
            subtext { ::content { product().manufacturer ?: "No Part Number" } }
            subtext { ::content { product().erpId ?: "No Part Number" } }
        }

        atTopEnd - onlyWhen {
            val id = product()._id
            favorites()?.any { it._id.product == id } ?: false
        } - icon {
            source = Icon.heartFilled.copy(width = 1.rem, height = 1.rem)
        }
    }
}

fun Readable<Product>.inFavorites() = shared {
    val prod = this@inFavorites()._id
    favorites()?.any { it.product == prod } ?: false
}.withWrite {
    val session = currentSession()
    val accountId = session.accountId ?: return@withWrite
    val prod = this@inFavorites()._id
    val id = AccountProduct(accountId, prod)

    if (it) {
        session.favorites.insert(Favorite(id))
        session.favorites.bulkModify(
            MassModification(
                condition { (it._id.account eq accountId) and (it.priority gte 0) and (it._id neq id) },
                modification { it.priority plusAssign 1 }
            )
        )
    } else {
        val existing = session.favorites[id]() ?: return@withWrite
        session.favorites.get(id).delete()
        session.favorites.bulkModify(
            MassModification(
                condition { (it._id.account eq accountId) and (it.priority gt existing.priority) and (it._id neq id) },
                modification { it.priority plusAssign -1 }
            )
        )
    }
}

fun ViewWriter.productCategoryCard(category: Readable<ProductCategory>) =
    card - link {
        spacing = 0.rem
        ::to {
            val id = category()._id
            { CategoryScreen(id) }
        }

        productCategoryCardContent(category)
    }

fun ViewWriter.productCategoryCardContent(category: Readable<ProductCategory>, usePath: Boolean = false) =
    sizeConstraints(height = 5.rem) - stack {
        spacing = 0.rem
        stack {
            image {
                scaleType = ImageScaleType.Crop
                ::source { category().image?.location?.let(::ImageRemote) }
            }
            stack {
                themeChoice += ThemeDerivation {
                    it.copy(
                        background = Color.white.withAlpha(0.5f)
                    ).withBack
                }
            }
        }
        stack {
            gravity(Align.Start, Align.Center) - card - h3 {
                themeChoice += ThemeDerivation {
                    it.copy(
                        background = Color.white.withAlpha(0.75f)
                    ).withBack
                }
                ::content { if (usePath) category().path.trim(' ', '-') else category().name.trim(' ', '-') }
            }
        }
    }