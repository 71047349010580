@file:UseContextualSerialization(UUID::class, Instant::class, ServerFile::class)

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization

annotation class Denormalized

@GenerateDataClassPaths
@Serializable
data class Distributor(
    override val _id: String = "singleton",
    val companyName: String,
    val email: String,
    val address: Address,
    val emailOnNewAccount: Set<String> = emptySet(),
    val emailOnNewOrder: Set<String> = emptySet(),
    val logo: ServerFile? = null,
) : HasId<String>

sealed interface CatalogItem {
    val name: String
}

@GenerateDataClassPaths
@Serializable
@AdminTitleFields(["path"])
data class ProductCategory(
    override val _id: UUID = uuid(),
    override val name: String,
    @Denormalized val productCount: Int = 0,
    @Denormalized val path: String = name,
    val image: ServerFile? = null,
    // The parent category, or null if this is a top level category
    @References(ProductCategory::class) val parent: UUID? = null,
) : HasId<UUID>, CatalogItem

@Serializable
enum class QuantityType(vararg erpNames: String) {
    Each("EA", "Each"),
    Foot("FT"),
    Hour("HR"),
    Piece("PC"),
    Kilometer("KM"),
    Rate("RATE"),
    Coil("COIL"),
    Box("BOX")
    ;

    val erpNames = erpNames.toSet()

    companion object {
        val erpLookup = entries.flatMap { entry -> entry.erpNames.map { it to entry } }.toMap()
    }
}

@GenerateDataClassPaths
@Serializable
data class Product(
    override val _id: UUID = uuid(),
    val erpId: String, // this is the part/product number
    @MultipleReferences(ProductCategory::class) val categories: Set<UUID>,
    val title: String,
    @Multiline val manufacturerDescription: String,
    val manufacturer: String,
    val sku: String,
    val createdAt: Instant = Clock.System.now(),
    val updatedAt: Instant = Clock.System.now(),
    val image: ServerFile? = null,
    val imageLarge: ServerFile? = null,
    val quantityType: QuantityType = QuantityType.Each,
    val outOfDate: Boolean = false,
    val active: Boolean = true,
) : HasId<UUID>, CatalogItem {
    override val name: String get() = title
}

@GenerateDataClassPaths
@Serializable
data class PriceIncrease(
    override val _id: UUID = uuid(),
    val increasesAt: Instant,
    val message: String,
    @MultipleReferences(Product::class) val products: Set<UUID>,
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class Warehouse(
    override val _id: UUID = uuid(),
    val erpId: String? = null,
    val name: String,
    val address: Address,
    val public: Boolean = true,
    @MultipleReferences(CustomerAccount::class) val permittedAccounts: Set<UUID> = setOf(),
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class Inventory(
    override val _id: ProductWarehouse,
    val count: Int = 0
) : HasId<ProductWarehouse>

@Serializable
data class ProductWarehouse(
    @References(Product::class) val product: UUID,
    @References(Warehouse::class) val warehouse: UUID,
) : Comparable<ProductWarehouse> {
    companion object {
        val comparator = compareBy<ProductWarehouse> { it.product }.thenBy { it.warehouse }
    }

    override fun compareTo(other: ProductWarehouse): Int = comparator.compare(this, other)
}

typealias IsoCode2Letter = String

@Serializable
@GenerateDataClassPaths
data class Address(
    val individualName: String? = null,
    val businessName: String,
    val street: String,
    val unitOrSuite: String? = null,
    val city: String,
    val subcountry: IsoCode2Letter,
    val country: IsoCode2Letter,
    val postalCode: String,
) {
    companion object {
        val EMPTY = Address(businessName = "", street = "", city = "", subcountry = "", country = "", postalCode = "")
    }
}

sealed interface BelongsToAccount {
    val account: UUID?
}

@SerialInfo
@Retention(AnnotationRetention.BINARY)
@Target(AnnotationTarget.PROPERTY, AnnotationTarget.FIELD)
annotation class GloballyUnique(val fieldName: String)

@GenerateDataClassPaths
@Serializable
@AdminTitleFields(["address.businessName"])
data class CustomerAccount(
    override val _id: UUID = uuid(),
    override val email: String,
    override val phoneNumber: String,
    val erpId: Long? = null,
    val address: Address,
    val active: Boolean = true,
    @MultipleReferences(User::class) val representatives: Set<UUID> = emptySet(),
    @References(ShippingAddress::class) val preferredShippingAddress: UUID? = null,
    @References(Warehouse::class) val preferredPickupLocation: UUID? = null,
) : HasId<UUID>, HasEmail, HasPhoneNumber {
    val businessName: String get() = address.businessName
}

@Serializable
@GenerateDataClassPaths
data class User(
    override val _id: UUID = uuid(),
    val firstName: String,
    val lastName: String,
    @References(CustomerAccount::class) override val account: UUID? = null,
    val role: UserRole = UserRole.Customer,
    val emailOnNewOrderStatus: Boolean = false,
    val emailOnNewUsers: Boolean = false,
    val showPricing: Boolean = true,
    val showInventory: Boolean = true,
    val active: Boolean = true,
    val createdAt: Instant = Instant.fromEpochMilliseconds(0),
    val desiredCompany: String? = null,     // Used for new users who are just joining Vanden-Bussche, CAN BE NAME OR ERP-ID
    @Unique override val email: String,
    override val phoneNumber: String = "",
) : HasId<UUID>, BelongsToAccount, HasEmail, HasPhoneNumber {
    val name get() = "$firstName $lastName"
}
@Serializable
enum class UserRole(val display: String) {
    AccountReadOnly("Account Read Only"),
    Customer("Customer"),       // A customer
    AccountAdmin("Account Admin"),   // An admin of its account
    Representative("Vanden-Bussche Representative"),          // An admin of the site
    Admin("Admin"),          // An admin of the site
    Root("Super Admin"),
}
@Serializable
data class CreateUser(val user: User, val proof: Proof)

@Serializable
@GenerateDataClassPaths
data class ShippingAddress(
    override val _id: UUID = uuid(),
    val erpId: String? = null,
    val name: String? = null,
    @References(CustomerAccount::class) override val account: UUID? = null,
    val address: Address,
) : HasId<UUID>, BelongsToAccount


@Serializable
enum class OrderStatusCode(val display: String, val erpId: Int, val erpName: String, val description: String) {
    Entered("Entered", 0, "Ent", "This is an open quote"),
    Ordered("Ordered", 1, "Ord", "This order has not yet printed in the warehouse"),
    Picked("Picked", 2, "Pck", "This order has been printed in the warehouse and is being picked and packaged"),
    Shipped("Shipped", 3, "Shp", "This order has left the warehouse and is not in transit in shipping"),

    //    Prereceived("Prereceived", 4, "Pre", "The invoice for this order has been sent"),
//    Exception("Exception", 5, "Exc", "The invoice for this order has been sent"),
    Invoiced("Invoiced", 4, "Inv", "The invoice for this order has been sent"),
    Paid("Paid", 5, "Pd", "The invoice for this order has been paid"),
    Cancelled("Cancelled", 9, "Can", "This order has been cancelled"),
    Unknown("Unknown", 10, "", "This order's status is unknown'")
    ;

    companion object {
        val erpLookup = entries.associateBy { it.erpName }
        val erpIntLookup = Array(10) { index -> values().find { it.erpId == index } ?: OrderStatusCode.Unknown }
    }
}

//t-oeordV4.stagecd –
//1 – Ready for Pick Slip (stage 0,1)
//2 – Pick Slip Printed 3 – Ready for Invoice 4 – Invoice Printed
//t-oeordV4.approvty 9 – Order Held
//(stage 2) (stage 3) (stage 4, 5)
//( <> Y )

// TRANSFERS
//Count Requested – A count of the WT orders in Requested (0) stage
//Count Ordered – A count of the WT orders in Ordered (1) stage
//Count Picked – A count of the WT orders in Picked (2) stage
//Count Shipped – A count of the WT orders in Shipped (3) stage
//Count Preceived – A count of the WT orders in Prereceived (4) stage
//Count Exception – A count of the WT orders in Exception (5) stage

@Serializable
@GenerateDataClassPaths
data class OrderStatus(
    val code: OrderStatusCode,
    val at: Instant = now(),
)

@Serializable
data class OrderStatusRequestInfo(
    val custNo: Int,
    val orderNo: Int
)

interface Priceable {
    @References(CustomerAccount::class)
    val account: UUID
    val items: Set<CartItem>

    @References(Warehouse::class)
    val warehouse: UUID?

    @References(ShippingAddress::class)
    val shipTo: UUID? // shipping location erp id
    val shipASAP: Boolean?
}

@GenerateDataClassPaths
@Serializable
data class Order(
    override val _id: UUID = uuid(),
    @References(CustomerAccount::class) override val account: UUID,
    @References(User::class) val orderedBy: UUID? = null,

    val erpId: String? = null,
    @References(Cart::class) val fromCart: UUID? = null,

    @References(Warehouse::class) override val warehouse: UUID,
    @References(ShippingAddress::class) override val shipTo: UUID? = null,

    override val items: Set<CartItem>,

    val listPriceTotal: PriceInCents = 0.cents,
    val total: PriceInCents,

    @References(VBErpContact::class) val contactErpID: Double? = null,
    val contactEmail: String? = null,
    override val shipASAP: Boolean = false,

    val orderedAt: Instant = now(),
    val status: OrderStatus = OrderStatus(OrderStatusCode.Ordered),

    val comment: String = "",
    val poNum: String = "",

    val hidden: Boolean = false,

    val requestJson: String = "",
    val responseJson: String = ""
) : HasId<UUID>, BelongsToAccount, Priceable

@Serializable
data class PricingRequestInfo(
    @References(CustomerAccount::class) override val account: UUID,
    override val items: Set<CartItem>,
    /** warehouse erp id, should be included for multiple pricing because
     * otherwise it will check every warehouse
     */
    @References(Warehouse::class) override val warehouse: UUID? = null,
    @References(ShippingAddress::class) override val shipTo: UUID? = null, // shipping location erp id
    override val shipASAP: Boolean? = null,
) : Priceable

fun Priceable.toPricingRequestInfo() = PricingRequestInfo(
    account = account,
    items = items,
    warehouse = warehouse,
    shipTo = shipTo,
    shipASAP = shipASAP,
)

@GenerateDataClassPaths
@Serializable
data class Cart(
    override val _id: UUID = uuid(),
    @References(CustomerAccount::class) override val account: UUID,
    @References(Warehouse::class) override val warehouse: UUID,
    @References(ShippingAddress::class) override val shipTo: UUID? = null, // shipping location erp id
    override val shipASAP: Boolean? = null,
    val name: String? = null,
    val keepAfterOrder: Boolean = false,
    val createdAt: Instant = now(),
    @References(Order::class) val previousOrder: UUID? = null,     // Used to find the previous price of carts from the source of truth
    override val items: Set<CartItem> = emptySet()
) : HasId<UUID>, BelongsToAccount, Priceable

@GenerateDataClassPaths
@Serializable
data class CartItem(
    @References(Product::class) val product: UUID,
    @Denormalized @References(Product::class) val productErpId: String? = null,
    val quantity: Int,
    val previousPrice: PriceInCents? = null,
    val previousLineTotal: PriceInCents? = null,
    val previousListPrice: PriceInCents? = null,
    val previousDiscountType: String? = null
)
fun Collection<CartItem>.price() = fold(0.dollars) { a, it -> a + (it.previousLineTotal ?: 0.dollars) }
fun Collection<CartItem>.priceString() = fold(0.dollars) { a, it -> a + (it.previousLineTotal ?: 0.dollars) }.toString() + (if(any { it.previousLineTotal.isNullOrZero() }) "+" else "")

    @Serializable
@GenerateDataClassPaths
data class AccountProduct(
    @References(CustomerAccount::class) val account: UUID,
    @References(Product::class) val product: UUID,
) : Comparable<AccountProduct> {
    companion object {
        val comparator = compareBy<AccountProduct> { it.account }.thenBy { it.product }
    }

    override fun compareTo(other: AccountProduct): Int = comparator.compare(this, other)
}

@GenerateDataClassPaths
@Serializable
data class Favorite(
    override val _id: AccountProduct,
    val priority: Int = 0,
) : HasId<AccountProduct>, BelongsToAccount {
    override val account: UUID get() = _id.account
    val product: UUID get() = _id.product
}

@Serializable
enum class PopupBehavior(val displayName: String) {
//    Once("Once"),
//    OnLogin("On Login"),
    OnOpen("On Open"),
    Always("Always")
}

@Serializable
@GenerateDataClassPaths
data class PopupMessage(
    override val _id: UUID = uuid(),
    val title: String = "Special",
    val message: String,
    val startTime: Instant,
    val stopTime: Instant,
    val behavior: PopupBehavior = PopupBehavior.Always,
    val image: ServerFile? = null,
    @References(Product::class) val product: UUID? = null,
    @References(ProductCategory::class) val category: UUID? = null,
) : HasId<UUID>

@Serializable
@GenerateDataClassPaths
data class SyncTaskStatus(
    override val _id: String = "main",
    val startedAt: Instant? = null,
    val finishedAt: Instant? = null,
    val duration: Duration? = null,
    val exception: String? = null
) : HasId<String> {
    val status: String
        get() = when {
            startedAt == null -> "Unstarted"
            finishedAt == null -> "Working..."
            exception != null -> "Failed"
            else -> "Completed in $duration"
        }
}