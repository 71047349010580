package com.virtualrain.models

import com.lightningkite.serialization.*
import kotlinx.serialization.builtins.NothingSerializer

fun prepareModelsShared() { SharedModelsObject }
object SharedModelsObject {
    init {
        SerializationRegistry.master.registerShared()
        com.virtualrain.models.prepareDistributorFields()
        com.virtualrain.models.prepareProductCategoryFields()
        com.virtualrain.models.prepareProductFields()
        com.virtualrain.models.preparePriceIncreaseFields()
        com.virtualrain.models.prepareWarehouseFields()
        com.virtualrain.models.prepareInventoryFields()
        com.virtualrain.models.prepareAddressFields()
        SerializableAnnotation.parser<com.virtualrain.models.GloballyUnique>("com.virtualrain.models.GloballyUnique") { SerializableAnnotation("com.virtualrain.models.GloballyUnique", values = mapOf("fieldName" to SerializableAnnotationValue(it.fieldName))) }
        com.virtualrain.models.prepareCustomerAccountFields()
        com.virtualrain.models.prepareUserFields()
        com.virtualrain.models.prepareShippingAddressFields()
        com.virtualrain.models.prepareOrderStatusFields()
        com.virtualrain.models.prepareOrderFields()
        com.virtualrain.models.prepareCartFields()
        com.virtualrain.models.prepareCartItemFields()
        com.virtualrain.models.prepareAccountProductFields()
        com.virtualrain.models.prepareFavoriteFields()
        com.virtualrain.models.preparePopupMessageFields()
        com.virtualrain.models.prepareSyncTaskStatusFields()
    }
}

fun SerializationRegistry.registerShared() {
    register(com.virtualrain.models.PriceInCents.serializer())
    register(com.virtualrain.models.VBErpContact.serializer())
    register(com.virtualrain.models.VBSpreadsheetProduct.serializer())
    register(com.virtualrain.models.Distributor.serializer())
    register(com.virtualrain.models.ProductCategory.serializer())
    register(com.virtualrain.models.QuantityType.serializer())
    register(com.virtualrain.models.Product.serializer())
    register(com.virtualrain.models.PriceIncrease.serializer())
    register(com.virtualrain.models.Warehouse.serializer())
    register(com.virtualrain.models.Inventory.serializer())
    register(com.virtualrain.models.ProductWarehouse.serializer())
    register(com.virtualrain.models.Address.serializer())
    register(com.virtualrain.models.CustomerAccount.serializer())
    register(com.virtualrain.models.User.serializer())
    register(com.virtualrain.models.UserRole.serializer())
    register(com.virtualrain.models.CreateUser.serializer())
    register(com.virtualrain.models.ShippingAddress.serializer())
    register(com.virtualrain.models.OrderStatusCode.serializer())
    register(com.virtualrain.models.OrderStatus.serializer())
    register(com.virtualrain.models.OrderStatusRequestInfo.serializer())
    register(com.virtualrain.models.Order.serializer())
    register(com.virtualrain.models.PricingRequestInfo.serializer())
    register(com.virtualrain.models.Cart.serializer())
    register(com.virtualrain.models.CartItem.serializer())
    register(com.virtualrain.models.AccountProduct.serializer())
    register(com.virtualrain.models.Favorite.serializer())
    register(com.virtualrain.models.PopupBehavior.serializer())
    register(com.virtualrain.models.PopupMessage.serializer())
    register(com.virtualrain.models.SyncTaskStatus.serializer())
}
