package com.virtualrain

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.Action
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.direct.TextField
import com.lightningkite.kiteui.views.direct.TextView
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.now
import com.lightningkite.serialization.*
import kotlin.coroutines.coroutineContext
import kotlin.jvm.JvmName
import kotlin.time.Duration
import kotlinx.coroutines.*
import kotlinx.datetime.Instant
import kotlinx.serialization.KSerializer

fun defaultImage(width: Int = 200, height: Int? = null): ServerFile = ServerFile("https://picsum.photos/" + if (height == null) "$width" else "$width/$height")

fun ImmediateWritable<Boolean>.toggle() { value = !value }
suspend fun Writable<Boolean>.toggle() = set(!awaitOnce())

class SignalingList<T>(private val list: MutableList<T>): MutableList<T> by list, ImmediateWritable<List<T>>, BaseListenable() {
    constructor() : this(ArrayList<T>())
    constructor(vararg startingItems: T) : this(ArrayList(startingItems.toList()))

    override var value: List<T>
        get() = list
        set(value) { retainAll(value) }

    private fun <V> changeList(action: MutableList<T>.() -> V): V = list.action().also { invokeAllListeners() }

    override fun clear() = changeList { clear() }
    override fun removeAt(index: Int): T = changeList { removeAt(index) }
    override fun set(index: Int, element: T): T = changeList { set(index, element) }
    override fun retainAll(elements: Collection<T>): Boolean = changeList { retainAll(elements) }
    override fun removeAll(elements: Collection<T>): Boolean = changeList { removeAll(elements) }
    override fun remove(element: T): Boolean = changeList { remove(element) }
    override fun addAll(elements: Collection<T>): Boolean = changeList { addAll(elements) }
    override fun addAll(index: Int, elements: Collection<T>): Boolean = changeList { addAll(index, elements) }
    override fun add(index: Int, element: T) = changeList { add(index, element) }
    override fun add(element: T): Boolean = changeList { add(element) }
}

private class NamedDataClassPath<K, V>(
    val name: String,
    val wraps: DataClassPath<K, V>
): DataClassPath<K ,V>() {
    override val properties: List<SerializableProperty<*, *>> get() = wraps.properties
    override val serializer: KSerializer<V> get() = wraps.serializer

    override fun equals(other: Any?): Boolean =
        other is NamedDataClassPath<*, *> && (other.name == name)

    override fun hashCode(): Int = wraps.hashCode() + 1

    override fun toString(): String = name

    override fun set(key: K, value: V): K = wraps.set(key, value)
    override fun mapModification(modification: Modification<V>): Modification<K> = wraps.mapModification(modification)
    override fun mapCondition(condition: Condition<V>): Condition<K> = wraps.mapCondition(condition)
    override fun get(key: K): V? = wraps.get(key)
}

fun <K, V> DataClassPath<K, V>.setName(name: String): DataClassPath<K, V> = NamedDataClassPath(name, this)

fun Readable<*>.onNextSuccess(action: ()->Unit) {
    var remover = {}
    remover = addListener {
        if (state.success) {
            action()
            remover()
        }
    }
}

