
package com.virtualrain.sdk

import com.lightningkite.*
import com.lightningkite.kiteui.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.*
import com.lightningkite.lightningserver.auth.*
import com.lightningkite.serialization.*
import kotlinx.datetime.*
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import com.lightningkite.lightningserver.files.UploadInformation
import com.virtualrain.models.CreateUser
import com.virtualrain.models.User
import kotlin.String
import kotlin.Boolean
import com.virtualrain.models.ShippingAddress
import com.lightningkite.UUID
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.QueryPartial
import com.lightningkite.serialization.Partial
import com.lightningkite.lightningdb.MassModification
import kotlin.Int
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.EntryChange
import com.lightningkite.lightningdb.Condition
import kotlin.Unit
import com.lightningkite.lightningdb.GroupCountQuery
import com.lightningkite.lightningdb.AggregateQuery
import kotlin.Double
import com.lightningkite.lightningdb.GroupAggregateQuery
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.auth.subject.IdAndAuthMethods
import com.lightningkite.lightningserver.auth.subject.LogInRequest
import com.lightningkite.lightningserver.auth.subject.ProofsCheckResult
import com.lightningkite.lightningserver.auth.oauth.OauthTokenRequest
import com.lightningkite.lightningserver.auth.oauth.OauthResponse
import com.virtualrain.models.PricingRequestInfo
import com.virtualrain.models.CartItem
import kotlin.Long
import com.virtualrain.models.VBErpContact
import com.lightningkite.lightningserver.serverhealth.ServerHealth
import com.virtualrain.models.ProductCategory
import com.virtualrain.models.Product
import com.lightningkite.lightningserver.files.ServerFile
import com.virtualrain.models.VBSpreadsheetProduct
import com.virtualrain.models.Warehouse
import com.virtualrain.models.Inventory
import com.virtualrain.models.ProductWarehouse
import com.virtualrain.models.CustomerAccount
import com.virtualrain.models.Order
import com.virtualrain.models.Cart
import com.virtualrain.models.Favorite
import com.virtualrain.models.AccountProduct
import com.virtualrain.models.PopupMessage
import com.virtualrain.models.PriceIncrease
import com.virtualrain.models.SyncTaskStatus
import com.lightningkite.lightningdb.CollectionUpdates
import com.lightningkite.lightningserver.auth.subject.SubSessionRequest
import com.lightningkite.lightningserver.auth.subject.Session

open class AbstractAnonymousSession(val api: Api) {
    val productCategory: AbstractAnonymousSessionProductCategoryApi = AbstractAnonymousSessionProductCategoryApi(api.productCategory)
    val product: AbstractAnonymousSessionProductApi = AbstractAnonymousSessionProductApi(api.product)
    val warehouse: AbstractAnonymousSessionWarehouseApi = AbstractAnonymousSessionWarehouseApi(api.warehouse)
    val inventory: AbstractAnonymousSessionInventoryApi = AbstractAnonymousSessionInventoryApi(api.inventory)
    val customerAccount: AbstractAnonymousSessionCustomerAccountApi = AbstractAnonymousSessionCustomerAccountApi(api.customerAccount)
    val user: AbstractAnonymousSessionUserApi = AbstractAnonymousSessionUserApi(api.user)
    val shippingAddress: AbstractAnonymousSessionShippingAddressApi = AbstractAnonymousSessionShippingAddressApi(api.shippingAddress)
    val order: AbstractAnonymousSessionOrderApi = AbstractAnonymousSessionOrderApi(api.order)
    val cart: AbstractAnonymousSessionCartApi = AbstractAnonymousSessionCartApi(api.cart)
    val favorite: AbstractAnonymousSessionFavoriteApi = AbstractAnonymousSessionFavoriteApi(api.favorite)
    val popupMessage: AbstractAnonymousSessionPopupMessageApi = AbstractAnonymousSessionPopupMessageApi(api.popupMessage)
    val priceIncrease: AbstractAnonymousSessionPriceIncreaseApi = AbstractAnonymousSessionPriceIncreaseApi(api.priceIncrease)
    val syncTaskStatus: AbstractAnonymousSessionSyncTaskStatusApi = AbstractAnonymousSessionSyncTaskStatusApi(api.syncTaskStatus)
    val emailProof: AbstractAnonymousSessionEmailProofApi = AbstractAnonymousSessionEmailProofApi(api.emailProof)
    val userAuth: AbstractAnonymousSessionUserAuthApi = AbstractAnonymousSessionUserAuthApi(api.userAuth)
    val userSession: AbstractAnonymousSessionUserSessionApi = AbstractAnonymousSessionUserSessionApi(api.userSession)
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest()
    open class AbstractAnonymousSessionProductCategoryApi(val api: Api.ProductCategoryApi) {
    }
    open class AbstractAnonymousSessionProductApi(val api: Api.ProductApi) {
    }
    open class AbstractAnonymousSessionWarehouseApi(val api: Api.WarehouseApi) {
    }
    open class AbstractAnonymousSessionInventoryApi(val api: Api.InventoryApi) {
    }
    open class AbstractAnonymousSessionCustomerAccountApi(val api: Api.CustomerAccountApi) {
    }
    open class AbstractAnonymousSessionUserApi(val api: Api.UserApi) {
        suspend fun create(input: CreateUser): User = api.create(input)
        suspend fun emailRegistered(input: String): Boolean = api.emailRegistered(input)
    }
    open class AbstractAnonymousSessionShippingAddressApi(val api: Api.ShippingAddressApi): ClientModelRestEndpoints<ShippingAddress, UUID> {
        override suspend fun default(): ShippingAddress = api.default(null)
        override suspend fun query(input: Query<ShippingAddress>): List<ShippingAddress> = api.query(input, null)
        override suspend fun queryPartial(input: QueryPartial<ShippingAddress>): List<Partial<ShippingAddress>> = api.queryPartial(input, null)
        override suspend fun detail(id: UUID): ShippingAddress = api.detail(id, null)
        override suspend fun insertBulk(input: List<ShippingAddress>): List<ShippingAddress> = api.insertBulk(input, null)
        override suspend fun insert(input: ShippingAddress): ShippingAddress = api.insert(input, null)
        override suspend fun upsert(id: UUID, input: ShippingAddress): ShippingAddress = api.upsert(id, input, null)
        override suspend fun bulkReplace(input: List<ShippingAddress>): List<ShippingAddress> = api.bulkReplace(input, null)
        override suspend fun replace(id: UUID, input: ShippingAddress): ShippingAddress = api.replace(id, input, null)
        override suspend fun bulkModify(input: MassModification<ShippingAddress>): Int = api.bulkModify(input, null)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ShippingAddress>): EntryChange<ShippingAddress> = api.modifyWithDiff(id, input, null)
        override suspend fun modify(id: UUID, input: Modification<ShippingAddress>): ShippingAddress = api.modify(id, input, null)
        override suspend fun bulkDelete(input: Condition<ShippingAddress>): Int = api.bulkDelete(input, null)
        override suspend fun delete(id: UUID): Unit = api.delete(id, null)
        override suspend fun count(input: Condition<ShippingAddress>): Int = api.count(input, null)
        override suspend fun groupCount(input: GroupCountQuery<ShippingAddress>): Map<String, Int> = api.groupCount(input, null)
        override suspend fun aggregate(input: AggregateQuery<ShippingAddress>): Double? = api.aggregate(input, null)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ShippingAddress>): Map<String, Double?> = api.groupAggregate(input, null)
    }
    open class AbstractAnonymousSessionOrderApi(val api: Api.OrderApi) {
    }
    open class AbstractAnonymousSessionCartApi(val api: Api.CartApi) {
    }
    open class AbstractAnonymousSessionFavoriteApi(val api: Api.FavoriteApi) {
    }
    open class AbstractAnonymousSessionPopupMessageApi(val api: Api.PopupMessageApi) {
    }
    open class AbstractAnonymousSessionPriceIncreaseApi(val api: Api.PriceIncreaseApi) {
    }
    open class AbstractAnonymousSessionSyncTaskStatusApi(val api: Api.SyncTaskStatusApi) {
    }
    open class AbstractAnonymousSessionEmailProofApi(val api: Api.EmailProofApi): EmailProofClientEndpoints {
        override suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    open class AbstractAnonymousSessionUserAuthApi(val api: Api.UserAuthApi): UserAuthClientEndpoints<UUID> {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = api.logInV2(input)
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = api.checkProofs(input)
        override suspend fun openSession(input: String): String = api.openSession(input)
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        override suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
    }
    open class AbstractAnonymousSessionUserSessionApi(val api: Api.UserSessionApi) {
    }
}

abstract class AbstractUserSession(api: Api, userToken: String, userAccessToken: suspend () -> String) {
    abstract val api: Api
    abstract val userToken: String
    abstract val userAccessToken: suspend () -> String
    val productCategory: UserSessionProductCategoryApi = UserSessionProductCategoryApi(api.productCategory, userToken, userAccessToken)
    val product: UserSessionProductApi = UserSessionProductApi(api.product, userToken, userAccessToken)
    val warehouse: UserSessionWarehouseApi = UserSessionWarehouseApi(api.warehouse, userToken, userAccessToken)
    val inventory: UserSessionInventoryApi = UserSessionInventoryApi(api.inventory, userToken, userAccessToken)
    val customerAccount: UserSessionCustomerAccountApi = UserSessionCustomerAccountApi(api.customerAccount, userToken, userAccessToken)
    val user: UserSessionUserApi = UserSessionUserApi(api.user, userToken, userAccessToken)
    val shippingAddress: UserSessionShippingAddressApi = UserSessionShippingAddressApi(api.shippingAddress, userToken, userAccessToken)
    val order: UserSessionOrderApi = UserSessionOrderApi(api.order, userToken, userAccessToken)
    val cart: UserSessionCartApi = UserSessionCartApi(api.cart, userToken, userAccessToken)
    val favorite: UserSessionFavoriteApi = UserSessionFavoriteApi(api.favorite, userToken, userAccessToken)
    val popupMessage: UserSessionPopupMessageApi = UserSessionPopupMessageApi(api.popupMessage, userToken, userAccessToken)
    val priceIncrease: UserSessionPriceIncreaseApi = UserSessionPriceIncreaseApi(api.priceIncrease, userToken, userAccessToken)
    val syncTaskStatus: UserSessionSyncTaskStatusApi = UserSessionSyncTaskStatusApi(api.syncTaskStatus, userToken, userAccessToken)
    val emailProof: UserSessionEmailProofApi = UserSessionEmailProofApi(api.emailProof, userToken, userAccessToken)
    val userAuth: UserSessionUserAuthApi = UserSessionUserAuthApi(api.userAuth, userToken, userAccessToken)
    val userSession: UserSessionUserSessionApi = UserSessionUserSessionApi(api.userSession, userToken, userAccessToken)
    suspend fun getERPPricing(input: PricingRequestInfo): List<CartItem> = api.getERPPricing(input, userAccessToken)
    suspend fun getContactsForAccount(input: Long): List<VBErpContact> = api.getContactsForAccount(input, userAccessToken)
    suspend fun getServerHealth(): ServerHealth = api.getServerHealth(userAccessToken)
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest()
    suspend fun syncNow(): String = api.syncNow(userAccessToken)
    suspend fun getSyncTasks(): List<String> = api.getSyncTasks(userAccessToken)
    suspend fun syncNowPartial(id: String): String = api.syncNowPartial(id, userAccessToken)
    class UserSessionProductCategoryApi(val api: Api.ProductCategoryApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<ProductCategory, UUID> {
        override suspend fun default(): ProductCategory = api.default(userAccessToken)
        override suspend fun query(input: Query<ProductCategory>): List<ProductCategory> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<ProductCategory>): List<Partial<ProductCategory>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): ProductCategory = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<ProductCategory>): List<ProductCategory> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: ProductCategory): ProductCategory = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: ProductCategory): ProductCategory = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<ProductCategory>): List<ProductCategory> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: ProductCategory): ProductCategory = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<ProductCategory>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ProductCategory>): EntryChange<ProductCategory> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<ProductCategory>): ProductCategory = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<ProductCategory>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<ProductCategory>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<ProductCategory>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<ProductCategory>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ProductCategory>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionProductApi(val api: Api.ProductApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Product, UUID> {
        override suspend fun default(): Product = api.default(userAccessToken)
        override suspend fun query(input: Query<Product>): List<Product> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Product>): List<Partial<Product>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Product = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Product>): List<Product> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Product): Product = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Product): Product = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Product>): List<Product> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Product): Product = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Product>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Product>): EntryChange<Product> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Product>): Product = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Product>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Product>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Product>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Product>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Product>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun uploadProductSpreadsheet(input: ServerFile): Unit = api.uploadProductSpreadsheet(input, userAccessToken)
        suspend fun exportProductSpreadsheet(): List<VBSpreadsheetProduct> = api.exportProductSpreadsheet(userAccessToken)
    }
    class UserSessionWarehouseApi(val api: Api.WarehouseApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Warehouse, UUID> {
        override suspend fun default(): Warehouse = api.default(userAccessToken)
        override suspend fun query(input: Query<Warehouse>): List<Warehouse> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Warehouse>): List<Partial<Warehouse>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Warehouse = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Warehouse>): List<Warehouse> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Warehouse): Warehouse = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Warehouse): Warehouse = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Warehouse>): List<Warehouse> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Warehouse): Warehouse = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Warehouse>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Warehouse>): EntryChange<Warehouse> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Warehouse>): Warehouse = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Warehouse>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Warehouse>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Warehouse>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Warehouse>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Warehouse>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionInventoryApi(val api: Api.InventoryApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Inventory, ProductWarehouse> {
        override suspend fun default(): Inventory = api.default(userAccessToken)
        override suspend fun query(input: Query<Inventory>): List<Inventory> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Inventory>): List<Partial<Inventory>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: ProductWarehouse): Inventory = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Inventory>): List<Inventory> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Inventory): Inventory = api.insert(input, userAccessToken)
        override suspend fun upsert(id: ProductWarehouse, input: Inventory): Inventory = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Inventory>): List<Inventory> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: ProductWarehouse, input: Inventory): Inventory = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Inventory>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: ProductWarehouse, input: Modification<Inventory>): EntryChange<Inventory> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: ProductWarehouse, input: Modification<Inventory>): Inventory = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Inventory>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: ProductWarehouse): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Inventory>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Inventory>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Inventory>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Inventory>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionCustomerAccountApi(val api: Api.CustomerAccountApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<CustomerAccount, UUID> {
        override suspend fun default(): CustomerAccount = api.default(userAccessToken)
        override suspend fun query(input: Query<CustomerAccount>): List<CustomerAccount> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<CustomerAccount>): List<Partial<CustomerAccount>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): CustomerAccount = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<CustomerAccount>): List<CustomerAccount> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: CustomerAccount): CustomerAccount = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: CustomerAccount): CustomerAccount = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<CustomerAccount>): List<CustomerAccount> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: CustomerAccount): CustomerAccount = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<CustomerAccount>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<CustomerAccount>): EntryChange<CustomerAccount> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<CustomerAccount>): CustomerAccount = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<CustomerAccount>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<CustomerAccount>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<CustomerAccount>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<CustomerAccount>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<CustomerAccount>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionUserApi(val api: Api.UserApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<User, UUID> {
        override suspend fun default(): User = api.default(userAccessToken)
        override suspend fun query(input: Query<User>): List<User> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<User>): List<Partial<User>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): User = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<User>): List<User> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: User): User = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: User): User = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<User>): List<User> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: User): User = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<User>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<User>): EntryChange<User> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<User>): User = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<User>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<User>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<User>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<User>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<User>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun create(input: CreateUser): User = api.create(input)
        suspend fun emailRegistered(input: String): Boolean = api.emailRegistered(input)
    }
    class UserSessionShippingAddressApi(val api: Api.ShippingAddressApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<ShippingAddress, UUID> {
        override suspend fun default(): ShippingAddress = api.default(userAccessToken)
        override suspend fun query(input: Query<ShippingAddress>): List<ShippingAddress> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<ShippingAddress>): List<Partial<ShippingAddress>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): ShippingAddress = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<ShippingAddress>): List<ShippingAddress> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: ShippingAddress): ShippingAddress = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: ShippingAddress): ShippingAddress = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<ShippingAddress>): List<ShippingAddress> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: ShippingAddress): ShippingAddress = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<ShippingAddress>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ShippingAddress>): EntryChange<ShippingAddress> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<ShippingAddress>): ShippingAddress = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<ShippingAddress>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<ShippingAddress>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<ShippingAddress>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<ShippingAddress>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ShippingAddress>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionOrderApi(val api: Api.OrderApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Order, UUID> {
        override suspend fun default(): Order = api.default(userAccessToken)
        override suspend fun query(input: Query<Order>): List<Order> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Order>): List<Partial<Order>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Order = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Order>): List<Order> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Order): Order = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Order): Order = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Order>): List<Order> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Order): Order = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Order>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Order>): EntryChange<Order> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Order>): Order = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Order>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Order>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Order>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Order>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Order>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionCartApi(val api: Api.CartApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Cart, UUID> {
        override suspend fun default(): Cart = api.default(userAccessToken)
        override suspend fun query(input: Query<Cart>): List<Cart> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Cart>): List<Partial<Cart>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Cart = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Cart>): List<Cart> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Cart): Cart = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Cart): Cart = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Cart>): List<Cart> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Cart): Cart = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Cart>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Cart>): EntryChange<Cart> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Cart>): Cart = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Cart>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Cart>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Cart>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Cart>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Cart>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionFavoriteApi(val api: Api.FavoriteApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Favorite, AccountProduct> {
        override suspend fun default(): Favorite = api.default(userAccessToken)
        override suspend fun query(input: Query<Favorite>): List<Favorite> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Favorite>): List<Partial<Favorite>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: AccountProduct): Favorite = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Favorite>): List<Favorite> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Favorite): Favorite = api.insert(input, userAccessToken)
        override suspend fun upsert(id: AccountProduct, input: Favorite): Favorite = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Favorite>): List<Favorite> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: AccountProduct, input: Favorite): Favorite = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Favorite>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: AccountProduct, input: Modification<Favorite>): EntryChange<Favorite> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: AccountProduct, input: Modification<Favorite>): Favorite = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Favorite>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: AccountProduct): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Favorite>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Favorite>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Favorite>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Favorite>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionPopupMessageApi(val api: Api.PopupMessageApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<PopupMessage, UUID> {
        override suspend fun default(): PopupMessage = api.default(userAccessToken)
        override suspend fun query(input: Query<PopupMessage>): List<PopupMessage> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<PopupMessage>): List<Partial<PopupMessage>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): PopupMessage = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<PopupMessage>): List<PopupMessage> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: PopupMessage): PopupMessage = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: PopupMessage): PopupMessage = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<PopupMessage>): List<PopupMessage> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: PopupMessage): PopupMessage = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<PopupMessage>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PopupMessage>): EntryChange<PopupMessage> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<PopupMessage>): PopupMessage = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<PopupMessage>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<PopupMessage>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<PopupMessage>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<PopupMessage>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<PopupMessage>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionPriceIncreaseApi(val api: Api.PriceIncreaseApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<PriceIncrease, UUID> {
        override suspend fun default(): PriceIncrease = api.default(userAccessToken)
        override suspend fun query(input: Query<PriceIncrease>): List<PriceIncrease> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<PriceIncrease>): List<Partial<PriceIncrease>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): PriceIncrease = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<PriceIncrease>): List<PriceIncrease> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: PriceIncrease): PriceIncrease = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: PriceIncrease): PriceIncrease = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<PriceIncrease>): List<PriceIncrease> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: PriceIncrease): PriceIncrease = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<PriceIncrease>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PriceIncrease>): EntryChange<PriceIncrease> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<PriceIncrease>): PriceIncrease = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<PriceIncrease>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<PriceIncrease>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<PriceIncrease>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<PriceIncrease>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<PriceIncrease>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionSyncTaskStatusApi(val api: Api.SyncTaskStatusApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<SyncTaskStatus, String>, ClientModelRestEndpointsPlusUpdatesWebsocket<SyncTaskStatus, String> {
        override suspend fun default(): SyncTaskStatus = api.default(userAccessToken)
        override suspend fun query(input: Query<SyncTaskStatus>): List<SyncTaskStatus> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<SyncTaskStatus>): List<Partial<SyncTaskStatus>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: String): SyncTaskStatus = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<SyncTaskStatus>): List<SyncTaskStatus> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: SyncTaskStatus): SyncTaskStatus = api.insert(input, userAccessToken)
        override suspend fun upsert(id: String, input: SyncTaskStatus): SyncTaskStatus = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<SyncTaskStatus>): List<SyncTaskStatus> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: String, input: SyncTaskStatus): SyncTaskStatus = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<SyncTaskStatus>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: String, input: Modification<SyncTaskStatus>): EntryChange<SyncTaskStatus> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: String, input: Modification<SyncTaskStatus>): SyncTaskStatus = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<SyncTaskStatus>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: String): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<SyncTaskStatus>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<SyncTaskStatus>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<SyncTaskStatus>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<SyncTaskStatus>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        override fun updates(): TypedWebSocket<Condition<SyncTaskStatus>, CollectionUpdates<SyncTaskStatus, String>> = api.updates(userToken)
    }
    class UserSessionEmailProofApi(val api: Api.EmailProofApi,val userToken:String, val userAccessToken: suspend () -> String): EmailProofClientEndpoints {
        override suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    class UserSessionUserAuthApi(val api: Api.UserAuthApi,val userToken:String, val userAccessToken: suspend () -> String): UserAuthClientEndpoints<UUID>, AuthenticatedUserAuthClientEndpoints<User, UUID> {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = api.logInV2(input)
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = api.checkProofs(input)
        override suspend fun openSession(input: String): String = api.openSession(input)
        override suspend fun createSubSession(input: SubSessionRequest): String = api.createSubSession(input, userAccessToken)
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        override suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
        override suspend fun getSelf(): User = api.getSelf(userAccessToken)
        override suspend fun terminateSession(): Unit = api.terminateSession(userAccessToken)
        override suspend fun terminateOtherSession(sessionId: UUID): Unit = api.terminateOtherSession(sessionId, userAccessToken)
    }
    class UserSessionUserSessionApi(val api: Api.UserSessionApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Session<User, UUID>, UUID> {
        override suspend fun query(input: Query<Session<User, UUID>>): List<Session<User, UUID>> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Session<User, UUID>>): List<Partial<Session<User, UUID>>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Session<User, UUID> = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Session<User, UUID>>): List<Session<User, UUID>> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Session<User, UUID>): Session<User, UUID> = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Session<User, UUID>): Session<User, UUID> = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Session<User, UUID>>): List<Session<User, UUID>> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Session<User, UUID>): Session<User, UUID> = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Session<User, UUID>>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Session<User, UUID>>): EntryChange<Session<User, UUID>> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Session<User, UUID>>): Session<User, UUID> = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Session<User, UUID>>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Session<User, UUID>>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Session<User, UUID>>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Session<User, UUID>>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Session<User, UUID>>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
}

