package com.virtualrain.views.screens.cart

import com.lightningkite.UUID
import com.lightningkite.kiteui.AppScope
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.WritableModel
import com.lightningkite.serialization.*
import com.virtualrain.Logout
import com.virtualrain.mappings.*
import com.virtualrain.models.*
import com.virtualrain.sdk.currentSession
import com.virtualrain.sdk.flatten
import com.virtualrain.sdk.flattenNullable
import com.virtualrain.sdk.utils.*
import com.virtualrain.views.screens.cart.Carts.allCarts
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.nullable

object Carts {
    val allCarts: Readable<List<Cart>> = shared {
        val session = currentSession()
        if (session.accountId == null) return@shared emptyList()

        session.carts.query(
            Query(
                condition { it.account eq session.accountId },
                orderBy = sort { it.createdAt.ascending() }
            )
        ).await()
    }
    val selectedID = PersistentProperty<UUID?>("selectedCart", null, UUIDSerializer.nullable)
    init {
        AppScope.reactiveSuspending {
            selectedID()?.let {
                if(currentSession().carts[it]() == null) autoselectCart()
            } ?: autoselectCart()
        }
    }
    private suspend fun autoselectCart(notId: UUID? = null) {
        val session = currentSession()
        val carts = allCarts()
        selectedID.value = carts.firstOrNull { it.name == null }?._id
            ?: carts.firstOrNull { it._id != notId }?._id
                    ?: session.accountId?.let { accountId ->
                session.carts.insert(
                    Cart(
                        account = accountId,
                        warehouse = preferredWarehouse()._id
                    )
                )()!!._id
            }
    }
    val selected = shared {
        selectedID()?.let {
            currentSession().carts[it]
        }
    }.flattenNullable()
    @Deprecated("Preferable to use selected.waitForNotNull") val Selected = selected.waitForNotNull
    suspend fun delete(id: UUID) { currentSession().carts[id].delete() }
    suspend fun deleteSelected() = selected.delete()
    val selectedSourceWarehouse = shared {
        (Carts.selected()?.warehouse ?: currentSession().account()?.preferredPickupLocation)?.let { currentSession().warehouses[it]() } ?: notFoundError<Warehouse>()
    }
}

fun Warehouse.inStock(product: UUID): Readable<Int> {
    val id = ProductWarehouse(product, _id)
    return shared { currentSession().inventories[id]()?.count ?: 0 }
}

val CartItem.inStock get() = shared { Carts.selectedSourceWarehouse().inStock(product)() }
fun CartItem.inStock(warehouseId: UUID) = shared { currentSession().inventories[ProductWarehouse(product, warehouseId)]()?.count ?: 0 }
val Collection<CartItem>.anyUnderstocked get() = shared { any { it.quantity > it.inStock() } }
fun Collection<CartItem>.anyUnderstocked(warehouseId: UUID) = shared { any { it.quantity > it.inStock(warehouseId)() } }
