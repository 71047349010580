package com.virtualrain

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.PopoverPreferredDirection
import com.lightningkite.kiteui.reactive.ReactiveContext
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.hintPopover
import com.lightningkite.kiteui.views.direct.subtext

@ViewModifierDsl3
fun ViewWriter.tooltip(
    tip: String,
    preferredDirection: PopoverPreferredDirection = PopoverPreferredDirection.belowRight,
): ViewWrapper = hintPopover(preferredDirection) { subtext(tip) }

@ViewModifierDsl3
fun ViewWriter.tooltip(
    preferredDirection: PopoverPreferredDirection = PopoverPreferredDirection.belowRight,
    tip: ReactiveContext.() -> String
): ViewWrapper = hintPopover(preferredDirection) { subtext { ::content { tip() } } }