// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareOrderFields() {
    val props: Array<SerializableProperty<Order, *>> = arrayOf(Order__id, Order_account, Order_orderedBy, Order_erpId, Order_fromCart, Order_warehouse, Order_shipTo, Order_items, Order_listPriceTotal, Order_total, Order_contactErpID, Order_contactEmail, Order_shipASAP, Order_orderedAt, Order_status, Order_comment, Order_poNum, Order_hidden, Order_requestJson, Order_responseJson)
    Order.serializer().properties { props }
}
val <K> DataClassPath<K, Order>._id: DataClassPath<K, UUID> get() = this[Order__id]
val <K> DataClassPath<K, Order>.account: DataClassPath<K, UUID> get() = this[Order_account]
val <K> DataClassPath<K, Order>.orderedBy: DataClassPath<K, UUID?> get() = this[Order_orderedBy]
val <K> DataClassPath<K, Order>.erpId: DataClassPath<K, String?> get() = this[Order_erpId]
val <K> DataClassPath<K, Order>.fromCart: DataClassPath<K, UUID?> get() = this[Order_fromCart]
val <K> DataClassPath<K, Order>.warehouse: DataClassPath<K, UUID> get() = this[Order_warehouse]
val <K> DataClassPath<K, Order>.shipTo: DataClassPath<K, UUID?> get() = this[Order_shipTo]
val <K> DataClassPath<K, Order>.items: DataClassPath<K, Set<CartItem>> get() = this[Order_items]
val <K> DataClassPath<K, Order>.listPriceTotal: DataClassPath<K, PriceInCents> get() = this[Order_listPriceTotal]
val <K> DataClassPath<K, Order>.total: DataClassPath<K, PriceInCents> get() = this[Order_total]
val <K> DataClassPath<K, Order>.contactErpID: DataClassPath<K, Double?> get() = this[Order_contactErpID]
val <K> DataClassPath<K, Order>.contactEmail: DataClassPath<K, String?> get() = this[Order_contactEmail]
val <K> DataClassPath<K, Order>.shipASAP: DataClassPath<K, Boolean> get() = this[Order_shipASAP]
val <K> DataClassPath<K, Order>.orderedAt: DataClassPath<K, Instant> get() = this[Order_orderedAt]
val <K> DataClassPath<K, Order>.status: DataClassPath<K, OrderStatus> get() = this[Order_status]
val <K> DataClassPath<K, Order>.comment: DataClassPath<K, String> get() = this[Order_comment]
val <K> DataClassPath<K, Order>.poNum: DataClassPath<K, String> get() = this[Order_poNum]
val <K> DataClassPath<K, Order>.hidden: DataClassPath<K, Boolean> get() = this[Order_hidden]
val <K> DataClassPath<K, Order>.requestJson: DataClassPath<K, String> get() = this[Order_requestJson]
val <K> DataClassPath<K, Order>.responseJson: DataClassPath<K, String> get() = this[Order_responseJson]
inline val Order.Companion.path: DataClassPath<Order, Order> get() = path<Order>()


object Order__id: SerializableProperty<Order, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Order): UUID = receiver._id
    override fun setCopy(receiver: Order, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object Order_account: SerializableProperty<Order, UUID> {
    override val name: String = "account"
    override fun get(receiver: Order): UUID = receiver.account
    override fun setCopy(receiver: Order, value: UUID) = receiver.copy(account = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("account")
}
object Order_orderedBy: SerializableProperty<Order, UUID?> {
    override val name: String = "orderedBy"
    override fun get(receiver: Order): UUID? = receiver.orderedBy
    override fun setCopy(receiver: Order, value: UUID?) = receiver.copy(orderedBy = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("orderedBy")
    override val default: UUID? = null
}
object Order_erpId: SerializableProperty<Order, String?> {
    override val name: String = "erpId"
    override fun get(receiver: Order): String? = receiver.erpId
    override fun setCopy(receiver: Order, value: String?) = receiver.copy(erpId = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("erpId")
    override val default: String? = null
}
object Order_fromCart: SerializableProperty<Order, UUID?> {
    override val name: String = "fromCart"
    override fun get(receiver: Order): UUID? = receiver.fromCart
    override fun setCopy(receiver: Order, value: UUID?) = receiver.copy(fromCart = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("fromCart")
    override val default: UUID? = null
}
object Order_warehouse: SerializableProperty<Order, UUID> {
    override val name: String = "warehouse"
    override fun get(receiver: Order): UUID = receiver.warehouse
    override fun setCopy(receiver: Order, value: UUID) = receiver.copy(warehouse = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("warehouse")
}
object Order_shipTo: SerializableProperty<Order, UUID?> {
    override val name: String = "shipTo"
    override fun get(receiver: Order): UUID? = receiver.shipTo
    override fun setCopy(receiver: Order, value: UUID?) = receiver.copy(shipTo = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("shipTo")
    override val default: UUID? = null
}
object Order_items: SerializableProperty<Order, Set<CartItem>> {
    override val name: String = "items"
    override fun get(receiver: Order): Set<CartItem> = receiver.items
    override fun setCopy(receiver: Order, value: Set<CartItem>) = receiver.copy(items = value)
    override val serializer: KSerializer<Set<CartItem>> = SetSerializer(CartItem.serializer())
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("items")
}
object Order_listPriceTotal: SerializableProperty<Order, PriceInCents> {
    override val name: String = "listPriceTotal"
    override fun get(receiver: Order): PriceInCents = receiver.listPriceTotal
    override fun setCopy(receiver: Order, value: PriceInCents) = receiver.copy(listPriceTotal = value)
    override val serializer: KSerializer<PriceInCents> = PriceInCents.serializer()
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("listPriceTotal")
    override val default: PriceInCents = 0.cents
}
object Order_total: SerializableProperty<Order, PriceInCents> {
    override val name: String = "total"
    override fun get(receiver: Order): PriceInCents = receiver.total
    override fun setCopy(receiver: Order, value: PriceInCents) = receiver.copy(total = value)
    override val serializer: KSerializer<PriceInCents> = PriceInCents.serializer()
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("total")
}
object Order_contactErpID: SerializableProperty<Order, Double?> {
    override val name: String = "contactErpID"
    override fun get(receiver: Order): Double? = receiver.contactErpID
    override fun setCopy(receiver: Order, value: Double?) = receiver.copy(contactErpID = value)
    override val serializer: KSerializer<Double?> = Double.serializer().nullable2
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("contactErpID")
    override val default: Double? = null
}
object Order_contactEmail: SerializableProperty<Order, String?> {
    override val name: String = "contactEmail"
    override fun get(receiver: Order): String? = receiver.contactEmail
    override fun setCopy(receiver: Order, value: String?) = receiver.copy(contactEmail = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("contactEmail")
    override val default: String? = null
}
object Order_shipASAP: SerializableProperty<Order, Boolean> {
    override val name: String = "shipASAP"
    override fun get(receiver: Order): Boolean = receiver.shipASAP
    override fun setCopy(receiver: Order, value: Boolean) = receiver.copy(shipASAP = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("shipASAP")
    override val default: Boolean = false
}
object Order_orderedAt: SerializableProperty<Order, Instant> {
    override val name: String = "orderedAt"
    override fun get(receiver: Order): Instant = receiver.orderedAt
    override fun setCopy(receiver: Order, value: Instant) = receiver.copy(orderedAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("orderedAt")
    override val default: Instant = now()
}
object Order_status: SerializableProperty<Order, OrderStatus> {
    override val name: String = "status"
    override fun get(receiver: Order): OrderStatus = receiver.status
    override fun setCopy(receiver: Order, value: OrderStatus) = receiver.copy(status = value)
    override val serializer: KSerializer<OrderStatus> = OrderStatus.serializer()
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("status")
    override val default: OrderStatus = OrderStatus(OrderStatusCode.Ordered)
}
object Order_comment: SerializableProperty<Order, String> {
    override val name: String = "comment"
    override fun get(receiver: Order): String = receiver.comment
    override fun setCopy(receiver: Order, value: String) = receiver.copy(comment = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("comment")
    override val default: String = ""
}
object Order_poNum: SerializableProperty<Order, String> {
    override val name: String = "poNum"
    override fun get(receiver: Order): String = receiver.poNum
    override fun setCopy(receiver: Order, value: String) = receiver.copy(poNum = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("poNum")
    override val default: String = ""
}
object Order_hidden: SerializableProperty<Order, Boolean> {
    override val name: String = "hidden"
    override fun get(receiver: Order): Boolean = receiver.hidden
    override fun setCopy(receiver: Order, value: Boolean) = receiver.copy(hidden = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("hidden")
    override val default: Boolean = false
}
object Order_requestJson: SerializableProperty<Order, String> {
    override val name: String = "requestJson"
    override fun get(receiver: Order): String = receiver.requestJson
    override fun setCopy(receiver: Order, value: String) = receiver.copy(requestJson = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("requestJson")
    override val default: String = ""
}
object Order_responseJson: SerializableProperty<Order, String> {
    override val name: String = "responseJson"
    override fun get(receiver: Order): String = receiver.responseJson
    override fun setCopy(receiver: Order, value: String) = receiver.copy(responseJson = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Order.serializer().tryFindAnnotations("responseJson")
    override val default: String = ""
}
