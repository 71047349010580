// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareUserFields() {
    val props: Array<SerializableProperty<User, *>> = arrayOf(User__id, User_firstName, User_lastName, User_account, User_role, User_emailOnNewOrderStatus, User_emailOnNewUsers, User_showPricing, User_showInventory, User_active, User_createdAt, User_desiredCompany, User_email, User_phoneNumber)
    User.serializer().properties { props }
}
val <K> DataClassPath<K, User>._id: DataClassPath<K, UUID> get() = this[User__id]
val <K> DataClassPath<K, User>.firstName: DataClassPath<K, String> get() = this[User_firstName]
val <K> DataClassPath<K, User>.lastName: DataClassPath<K, String> get() = this[User_lastName]
val <K> DataClassPath<K, User>.account: DataClassPath<K, UUID?> get() = this[User_account]
val <K> DataClassPath<K, User>.role: DataClassPath<K, UserRole> get() = this[User_role]
val <K> DataClassPath<K, User>.emailOnNewOrderStatus: DataClassPath<K, Boolean> get() = this[User_emailOnNewOrderStatus]
val <K> DataClassPath<K, User>.emailOnNewUsers: DataClassPath<K, Boolean> get() = this[User_emailOnNewUsers]
val <K> DataClassPath<K, User>.showPricing: DataClassPath<K, Boolean> get() = this[User_showPricing]
val <K> DataClassPath<K, User>.showInventory: DataClassPath<K, Boolean> get() = this[User_showInventory]
val <K> DataClassPath<K, User>.active: DataClassPath<K, Boolean> get() = this[User_active]
val <K> DataClassPath<K, User>.createdAt: DataClassPath<K, Instant> get() = this[User_createdAt]
val <K> DataClassPath<K, User>.desiredCompany: DataClassPath<K, String?> get() = this[User_desiredCompany]
val <K> DataClassPath<K, User>.email: DataClassPath<K, String> get() = this[User_email]
val <K> DataClassPath<K, User>.phoneNumber: DataClassPath<K, String> get() = this[User_phoneNumber]
inline val User.Companion.path: DataClassPath<User, User> get() = path<User>()


object User__id: SerializableProperty<User, UUID> {
    override val name: String = "_id"
    override fun get(receiver: User): UUID = receiver._id
    override fun setCopy(receiver: User, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object User_firstName: SerializableProperty<User, String> {
    override val name: String = "firstName"
    override fun get(receiver: User): String = receiver.firstName
    override fun setCopy(receiver: User, value: String) = receiver.copy(firstName = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("firstName")
}
object User_lastName: SerializableProperty<User, String> {
    override val name: String = "lastName"
    override fun get(receiver: User): String = receiver.lastName
    override fun setCopy(receiver: User, value: String) = receiver.copy(lastName = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("lastName")
}
object User_account: SerializableProperty<User, UUID?> {
    override val name: String = "account"
    override fun get(receiver: User): UUID? = receiver.account
    override fun setCopy(receiver: User, value: UUID?) = receiver.copy(account = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("account")
    override val default: UUID? = null
}
object User_role: SerializableProperty<User, UserRole> {
    override val name: String = "role"
    override fun get(receiver: User): UserRole = receiver.role
    override fun setCopy(receiver: User, value: UserRole) = receiver.copy(role = value)
    override val serializer: KSerializer<UserRole> = UserRole.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("role")
    override val default: UserRole = UserRole.Customer
}
object User_emailOnNewOrderStatus: SerializableProperty<User, Boolean> {
    override val name: String = "emailOnNewOrderStatus"
    override fun get(receiver: User): Boolean = receiver.emailOnNewOrderStatus
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(emailOnNewOrderStatus = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("emailOnNewOrderStatus")
    override val default: Boolean = false
}
object User_emailOnNewUsers: SerializableProperty<User, Boolean> {
    override val name: String = "emailOnNewUsers"
    override fun get(receiver: User): Boolean = receiver.emailOnNewUsers
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(emailOnNewUsers = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("emailOnNewUsers")
    override val default: Boolean = false
}
object User_showPricing: SerializableProperty<User, Boolean> {
    override val name: String = "showPricing"
    override fun get(receiver: User): Boolean = receiver.showPricing
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(showPricing = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("showPricing")
    override val default: Boolean = true
}
object User_showInventory: SerializableProperty<User, Boolean> {
    override val name: String = "showInventory"
    override fun get(receiver: User): Boolean = receiver.showInventory
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(showInventory = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("showInventory")
    override val default: Boolean = true
}
object User_active: SerializableProperty<User, Boolean> {
    override val name: String = "active"
    override fun get(receiver: User): Boolean = receiver.active
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(active = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("active")
    override val default: Boolean = true
}
object User_createdAt: SerializableProperty<User, Instant> {
    override val name: String = "createdAt"
    override fun get(receiver: User): Instant = receiver.createdAt
    override fun setCopy(receiver: User, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("createdAt")
    override val default: Instant = Instant.fromEpochMilliseconds(0)
}
object User_desiredCompany: SerializableProperty<User, String?> {
    override val name: String = "desiredCompany"
    override fun get(receiver: User): String? = receiver.desiredCompany
    override fun setCopy(receiver: User, value: String?) = receiver.copy(desiredCompany = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("desiredCompany")
    override val default: String? = null
}
object User_email: SerializableProperty<User, String> {
    override val name: String = "email"
    override fun get(receiver: User): String = receiver.email
    override fun setCopy(receiver: User, value: String) = receiver.copy(email = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("email")
}
object User_phoneNumber: SerializableProperty<User, String> {
    override val name: String = "phoneNumber"
    override fun get(receiver: User): String = receiver.phoneNumber
    override fun setCopy(receiver: User, value: String) = receiver.copy(phoneNumber = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("phoneNumber")
    override val default: String = ""
}
