package com.virtualrain.views.components

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.now
import com.virtualrain.defaultImage
import com.virtualrain.models.PopupMessage
import com.virtualrain.theming.SpecialsSemantic
import com.virtualrain.theming.lightOutline
import com.virtualrain.views.screens.products.CategoryScreen
import com.virtualrain.views.screens.products.ProductScreen
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant


class Special(val container: RView, val message: Readable<PopupMessage?>) {
    private val linked: Readable<(() -> Screen)?> = shared {
        val m = message()
        m?.product?.let { { ProductScreen(it) } } ?: m?.category?.let { { CategoryScreen(it) } }
    }

    private val _exists = Property(true)
    var exists by _exists

    private val _enabled = Property(true)
    var enabled by _enabled

    var showTimer = true

    val now = Property(now())

    init {
        container.launch {
            while (showTimer) {
                delay(1000)
                now.value = now()
            }
        }
    }

    val stop = message.lens { it?.stopTime }    // lensed to prevent signals on every message update
    val timeRemaining = shared {
        stop()?.let { it - this@Special.now() }?.takeIf { it >= Duration.ZERO } ?: run {
            _onTimeout?.let {
                it.invoke()
                _onTimeout = null
            }
            Duration.ZERO
        }
    }

    private val _imageSource = LazyProperty<ImageSource?> { message()?.image?.let { ImageRemote(it.location) } }
    var imageSource: ImageSource?
        get() = _imageSource.state.onSuccess { it }
        set(value) {
            if (value == null) _imageSource.reset()
            else _imageSource.value = value
        }

    private var _onTimeout: (() -> Unit)? = null
    fun onTimeout(action: () -> Unit) {
        _onTimeout = action
    }

    fun build() = with(container) {
        SpecialsSemantic.onNext - button {
            dynamicTheme {
                if (timeRemaining() == Duration.ZERO) DisabledSemantic else null
            }

            exists = false
            ::exists { _exists() and (message() != null) }
            ::enabled { _enabled() and (linked() != null) }

            onClick { linked()?.let { mainScreenNavigator.navigate(it()) } }

            rowCollapsingToColumn(40.rem) {
                centered - sizeConstraints(maxWidth = 10.rem) - image {
                    scaleType = ImageScaleType.Fit
                    ::exists { _imageSource() != null }
                    ::source { _imageSource() }
                }

                expanding - col {
                    bold - rowCollapsingToColumn(40.rem) {
                        centered - expanding - h2 { ::content { message()?.title ?: "" } }

                        if (showTimer) centered - sizeConstraints(minWidth = 15.rem) - lightOutline - text {
                            align = Align.Center
                            ::content {
                                val t = timeRemaining()
                                t.toComponents { days, hours, minutes, seconds, nanoseconds ->
                                    buildString {
                                        if(days > 0) append("$days days ")
                                        if(days > 0 || hours > 0) append("$hours:")
                                        if(days > 0 || hours > 0 || minutes > 0) append("$minutes:")
                                        append("$seconds left")
                                    }
                                }
                            }
                        }
                    }
                    text {
                        ::exists { message()?.message?.isNotBlank() ?: false }
                        ::setBasicHtmlContent { message()?.message ?: "" }
                    }
                }
            }
        }
    }
}

fun RView.special(message: Readable<PopupMessage?>, setup: Special.() -> Unit = {}) {
    Special(this, message).apply {
        setup()
        build()
    }
}