package com.virtualrain.views.screens.account

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import com.virtualrain.mappings.dynamicLens
import com.virtualrain.models.*
import com.virtualrain.sdk.currentSession
import com.virtualrain.sdk.utils.updateError
import com.virtualrain.theming.edit
import com.virtualrain.validation.interceptWrite
import com.virtualrain.views.components.editLocationDialog
import com.virtualrain.views.emptyView
import com.virtualrain.views.screens.common.ContentDeterminedByAccount
import com.virtualrain.views.screens.common.HasNarrowContent
import com.virtualrain.views.textFormatting.format


@Routable("/Shipping-Locations")
class ShippingLocationsScreen : ContentDeterminedByAccount, HasNarrowContent {
    val locations = shared {
        val session = currentSession()

        session.shippingAddresses.query(
            Query(
                condition { it.account eq session.accountId },
                sort { it.name.notNull.ascending() },
                limit = 1000
            )
        )()
    }

    override val noAccountMessage = "You don't have an account, so you can't have saved locations"

    override fun ViewWriter.hasAccount() {

        val preferredShippingLocation = LazyProperty {
            val session = currentSession()

            session.customerAccounts[session.accountId!!]()?.preferredShippingAddress ?: return@LazyProperty null
        }.interceptWrite { id ->
            val session = currentSession()

            value = session.customerAccounts[session.accountId!!].modify(
                modification { it.preferredShippingAddress assign id }
            )?.preferredShippingAddress ?: updateError<CustomerAccount>()
        }

        col {
            stack {
                gravity(Align.End, Align.Center) - important - button {
                    centered - row {
                        icon(Icon.add, "Add new location")
                        centered - text("Add new location")
                    }
                    ::enabled { currentSession().mayOrder }
                    onClick {
                        val session = currentSession()
                        val newLoc = Property(
                            ShippingAddress(
                                account = session.accountId,
                                address = Address(
                                    businessName = "",
                                    street = "",
                                    city = "",
                                    subcountry = "",
                                    country = "",
                                    postalCode = "",
                                )
                            )
                        )
                        editLocationDialog(newLoc, isNewLocation = true)
                    }
                }
            }

            expanding - col {
                spacing = 0.5.rem

                expanding - onlyWhen { locations().isEmpty() } - emptyView { centered - h3("No locations yet") }

                expanding - recyclerView {
                    ::exists { locations().isNotEmpty() }

                    children(locations) { loc ->
                        card - rowCollapsingToColumn(40.rem) {
                            expanding - row {
                                expanding - col {
                                    bold - h6 {
                                        ::content {
                                            val l = loc()
                                            l.name ?: l.address.businessName
                                        }
                                    }

                                    text {
                                        ::content { loc().address.format() }
                                        ::ellipsis { narrow() }
                                    }
                                }
                            }

                            centered - row {
                                centered - radioToggleButton {
                                    icon {
                                        description = "Preferred Shipping Location"
                                        ::source { if(checked()) Icon.starFilled else Icon.star }
                                    }
                                    ::enabled { currentSession().mayOrder }
                                    checked bind preferredShippingLocation.dynamicLens(
                                        get = { loc()._id == it },
                                        set = { if (it) loc()._id else null }
                                    )
                                }

                                button {
                                    icon(Icon.edit, "Edit Location")
                                    ::enabled { currentSession().mayOrder }
                                    onClick {
                                        editLocationDialog(LazyProperty { loc() })
                                    }
                                }

                                button {
                                    icon(Icon.delete, "Remove Location")
                                    ::enabled { currentSession().mayOrder }
                                    onClick {
                                        val location = loc()
                                        currentSession().shippingAddresses[location._id].delete()
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}