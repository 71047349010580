package com.virtualrain.views.screens.cart

import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.asDouble
import com.lightningkite.kiteui.reactive.lensByElementAssumingSetNeverManipulates
import com.lightningkite.kiteui.reactive.lensByElementWithIdentity
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import com.virtualrain.mappings.*
import com.virtualrain.models.*
import com.virtualrain.sdk.currentSession
import com.virtualrain.sdk.utils.creationError
import com.virtualrain.sdk.utils.preferredWarehouse
import com.virtualrain.theming.*
import com.virtualrain.validation.Validator
import com.virtualrain.views.components.detail
import com.virtualrain.views.components.productCard
import com.virtualrain.views.components.productImage
import com.virtualrain.views.components.selectSourceWarehouse
import com.virtualrain.views.emptyView
import com.virtualrain.views.screens.common.HasNarrowLayoutDeterminedByAccount
import com.virtualrain.views.screens.products.ProductScreen
import com.virtualrain.views.textFormatting.formatName
import kotlin.time.Duration.Companion.milliseconds

inline fun <T> Iterable<T>.sumPrice(transform: (T) -> PriceInCents): PriceInCents {
    var total = 0.cents
    for (item in this) {
        total += transform(item)
    }
    return total
}

@Routable("/Carts")
class CartScreen : HasNarrowLayoutDeterminedByAccount, Validator() {
    private val selectedCart = Carts.Selected.debounceWrite(500.milliseconds)

    private val items: Writable<List<CartItem>> = selectedCart.lensPath { it.items }.lens(get = { it.toList() }, set = { it.toSet() })
    private val itemList = items.lensByElementWithIdentity { it.product }

    private val savedCarts = Carts.allCarts

    private val products = shared {
        val session = currentSession()
        val prods: List<UUID> = items().map { it.product }

        session.products.query(
            Query(
                condition { it._id inside prods },
                limit = 1000
            )
        )().associateBy { it._id }
    }

    private val currentWarehouse = shared { selectedCart().warehouse }
    private val prices = shared {
        rerunOn(items)
        rerunOn(currentWarehouse)
        currentSession().pricing
            .request(selectedCart.once())
            .invoke()
            .associateBy { it.product }
    }

    override val noAccountMessage = "You don't have an account, so you can't have any carts"

    /*--COMPONENTS--*/
    private fun ViewWriter.renderSavedCarts() {
        col {
            forEachUpdating(savedCarts) { cart ->
                card - radioToggleButton {
                    cartElementContent(cart)

                    checked bind shared {
                        selectedCart()._id == cart()._id
                    }.withWrite { checked ->
                        if (checked) {
                            Carts.selectedID.value = cart()._id
                        }
                    }
                }
            }
        }
    }

    private fun ViewWriter.cartElementContent(cart: Readable<Cart>) {
        col {
            h6 { ::content { cart().name ?: "Unnamed" } }
            row {
                expanding - subtext {
                    ::content {
                        val count = cart().items.size
                        if (count == 1) "1 item"
                        else "$count items"
                    }
                }

                subtext {
                    val previousOrder = shared {
                        cart().previousOrder?.let {
                            currentSession().orders[it]()
                        }
                    }

                    exists = false
                    ::exists {
                        val ord = previousOrder()
                        (ord != null) and (ord?.items == cart().items)
                    }

                    ::content {
                        "Previously ${previousOrder()?.total}"
                    }
                }
            }
        }
    }

    private fun ViewWriter.addCartButton(setSpacing: Dimension? = null) {
        button {
            themeChoice += ThemeDerivation {
                it.copy(
                    foreground = Color.interpolate(it.background.closestColor(), it.foreground.closestColor(), 0.5f)
                ).withoutBack
            }

            setSpacing?.let { spacing = it }
            centered - icon(Icon.add, "Add new cart")
            onClick {
                val session = currentSession()
                val new = session.carts.insert(
                    Cart(
                        account = session.accountId ?: return@onClick,
                        warehouse = preferredWarehouse()._id
                    )
                )() ?: creationError<Cart>()
                Carts.selectedID.value = new._id
            }
        }
    }

    private fun ViewWriter.cartNameField() {
        textInput {
            hint = "Cart Name"
            ::enabled { currentSession().mayOrder }
            content bind selectedCart.lensPath { it.name }.nullToBlank()
        }
    }

    private fun ViewWriter.keepAfterCheckoutBox() {
        row {
            centered - checkboxTheme - checkbox {
                checked bind selectedCart.lensPath { it.keepAfterOrder }
                ::enabled { currentSession().mayOrder }
            }
            centered - text("Keep After Checkout")
        }
    }

    private fun ViewWriter.deleteCart() {
        confirmDanger(
            title = "Delete Cart",
            body = "Are you sure you want to delete this cart? This cannot be undone.",
            actionName = "I'm sure.",
            action = {
                Carts.deleteSelected()
            }
        )
    }

    private fun ViewWriter.checkoutButton() {
        important - button {
            ::enabled {
                val read = items()
                read.isNotEmpty() and read.all { it.quantity > 0 } && currentSession().mayOrder
            }

            centered - h4("Checkout")

            onClick {
                dialogScreenNavigator.navigate(checkout(selectedCart))
            }
        }
    }

    private fun ViewWriter.cartTotal() {
        bold - h5 {
            ::exists { currentSession().self().showPricing }
            ::content {
                "Total: ${prices().values.priceString()}"
            }
        }
    }

    private fun ReactiveContext.understockedWarningMessage(): String =
        "One or more cart items have a higher quantity than what's in stock at ${
            Carts.selectedSourceWarehouse().formatName()
        } Warehouse. Ordering more than what's in stock can result in delays."

    private fun ViewWriter.cartItems() {
        expanding - col {
            expanding - scrolls - col {
                exists = false
                ::exists { items().isNotEmpty() }

                forEachUpdating(itemList) { item ->
                    val foundProduct = shared { products()[item()().product] }
                    val associatedProduct = foundProduct.waitForNotNull

                    val pricing = shared {
                        prices()[item()().product]
                    }
                    card - rowCollapsingToColumn() {
                        spacing = 0.px

                        expanding - productCard(associatedProduct, 5.rem)

                        padded - row {

                            centered - sizeConstraints(width = 5.rem) - fieldTheme - numberInput {
                                hint = "Quantity"
                                keyboardHints = KeyboardHints.integer
                                ::enabled { currentSession().mayOrder }

                                dynamicTheme {
                                    if (item()().quantity <= 0) InvalidSemantic
                                    else null
                                }

                                fun <T, WRITE : Writable<T>> WRITE.debug(label: String): Writable<T> =
                                    object : Writable<T>, Readable<T> by this {
                                        override val state: ReadableState<T>
                                            get() {
                                                val s = this@debug.state
                                                println("$label state get $s")
                                                return s
                                            }
                                        override suspend fun set(value: T) {
                                            println("$label set $value")
                                            this@debug.set(value)
                                        }
                                    }

                                content bind item.flatten().debug("cart item").lensPath { it.quantity }.debug("quantity").lens<Int, Int?>(
                                    get = { it },
                                    set = { it ?: 0 }
                                ).asDouble().debug("quantity double")
                            }

                            centered - expanding - row {
                                ::exists { currentSession().self().showPricing }
                                centered - text("X")

                                centered - sizeConstraints(width = 5.rem) - text { ::content { pricing()?.previousPrice?.toString() ?: "-" } }

                                expanding - centered - col { separator() }

                                centered - sizeConstraints(width = 6.rem) - bold - h6 {
                                    ::content {
                                        pricing()?.previousLineTotal?.takeUnless { it == 0.cents }?.toString() ?: "-"
                                    }
                                }
                            }

                            centered - important - button {
                                spacing = 0.5.rem
                                icon { source = Icon.delete }
                                onClick {
                                    confirmDanger(
                                        title = "Remove Cart Item",
                                        body = "Are you sure you want to remove this item?",
                                        actionName = "I'm sure.",
                                        action = {
                                            items.modify { it.filter { it.product != item()().product } }
                                        }
                                    )
                                }
                            }
                        }
                    }
                }
            }

            expanding - emptyView {
                ::exists { items().isEmpty() }
                centered - row {
                    centered - icon(Icon.cart, "")
                    centered - h4("No items yet")
                }
            }
        }
    }

    /*--LAYOUTS--*/
    override fun ViewWriter.renderWide() {
        row {
            spacing = 1.5.rem

            // This fixes a bug. why? no idea. Don't remove it unless you want to figure out why prices doesn't notify
            // the individual cart item prices of new pricing results after a cart item has been added directly after a refresh.
            val remover = prices.addListener {}
            onRemove(remover)

            weight(1f) - listContainerTheme - col {
                h2("Carts")

                expanding - scrolls - col {
                    renderSavedCarts()

                    addCartButton()
                }
            }

            weight(3f) - col {
                spacing = 1.1.rem

                card - row {
                    expanding - label {
                        content = "Cart Name"
                        fieldTheme - cartNameField()
                    }

                    selectSourceWarehouse(true)

                    expanding - space()

                    centered - row {
                        lightOutline - button {
                            ::enabled { items().isNotEmpty() }
                            row {
                                centered - icon { source = Icon.close }
                                centered - text("Clear Items")
                            }
                            onClick {
                                confirmDanger("Clear Items", "Are you sure you want to clear all cart items?", "Confirm") {
                                    items set listOf()
                                }
                            }
                        }

                        danger - button {
                            row {
                                centered - icon { source = Icon.deleteForever }
                                centered - text("Delete Cart")
                            }
                            ::enabled { currentSession().mayOrder }
                            onClick { deleteCart() }
                        }
                    }
                }

                cartItems()

                card - col {
                    onlyWhen { items().anyUnderstocked() } - warning - row {
                        centered - icon(Icon.warning, "warning")
                        expanding - centered - text {
                            ::content { understockedWarningMessage() }
                        }
                    }

                    row {
                        expanding - row {
                            spacing = 1.5.rem
                            centered - cartTotal()
                            centered - keepAfterCheckoutBox()
                        }

                        checkoutButton()
                    }
                }
            }
        }
    }

    override fun ViewWriter.renderNarrow() {
        col {
            card - rowCollapsingToColumn(20.rem) {
                weight(4f) - label {
                    content = "Cart Name"
                    fieldTheme - row {
                        spacing = 0.px
                        padded - expanding - cartNameField()

                        important - button {
                            spacing = 0.1.rem
                            centered - unpadded - icon(Icon.chevronDown, "Show Saved Carts")

                            onClick {
                                dialogScreenNavigator.navigate(
                                    object : Screen {
                                        override fun ViewWriter.render() {
                                            dismissBackground {
                                                spacing = 3.rem

                                                listContainerTheme - col {
                                                    row {
                                                        expanding - centered - h4("Saved Carts")
                                                        addCartButton(0.5.rem)
                                                    }

                                                    expanding - col {
                                                        forEachUpdating(savedCarts) { cart ->
                                                            card - button {
                                                                cartElementContent(cart)
                                                                onClick {
                                                                    Carts.selectedID.value = cart()._id
                                                                    screenNavigator.dismiss()
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                )
                            }
                        }
                    }
                }

                weight(3f) - selectSourceWarehouse()
            }
            cartItems()
            card - col {
                rowCollapsingToColumn(23.rem) {
                    spacing = 0.5.rem
                    cartTotal()
                    expanding - stack()
                    SubtextSemantic.onNext - compact - keepAfterCheckoutBox()
                }

                row {
                    expanding - checkoutButton()

                    onlyWhen { items().anyUnderstocked() } - warning - button {
                        centered - icon(Icon.warning, "warning")

                        onClick {
                            dialogScreenNavigator.navigate(
                                object : Screen {
                                    override fun ViewWriter.render() {
                                        dismissBackground {
                                            spacing = 2.rem

                                            centered - warning - col {
                                                text("Warning")
                                                text {
                                                    ::content { understockedWarningMessage() }
                                                }
                                                atEnd - important - button {
                                                    centered - text("Dismiss")

                                                    onClick { screenNavigator.dismiss() }
                                                }
                                            }
                                        }
                                    }
                                }
                            )
                        }
                    }

                    important - button {
                        centered - icon(Icon.deleteForever, "Delete Cart")
                        ::enabled { currentSession().mayOrder }
                        onClick { deleteCart() }
                    }
                }
            }
        }
    }
}


