package com.virtualrain.admin

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import com.virtualrain.models.*
import com.virtualrain.sdk.currentSession
import com.virtualrain.views.components.productCategoryCardContent
import com.virtualrain.views.screens.products.CatalogScreen.narrow
import kotlinx.coroutines.*


@Routable("/categories")
class AdminCategoriesScreen() : Screen {
    override val title: Readable<String> = Constant<String>("Categories")

    @QueryParameter("search")
    val search = Property("")
    val query = shared {
        Query(
            condition<ProductCategory> {
                Condition.And(
                    listOfNotNull(
                        search.debounce(500)().let { s ->
                            if (s.isBlank()) condition(true)
                            else Condition.And(s.split(' ').map { part ->
                                it.path.contains(part, true)
                            })
                        },
                        if (limitToNoImage.await()) it.image eq null else null
//                        categories?
                    )
                )
            },
            orderBy = sort { it.path.ascending() }
        )
    }
    private val products = shared { currentSession.awaitNotNull().productCategories.query(query.await()) }

    @QueryParameter("limitToNoImage")
    val limitToNoImage = Property<Boolean>(false)

    override fun ViewWriter.render() = col {
        row {
            expanding - fieldTheme - row {
                icon(Icon.search, "Search")
                expanding - textInput {
                    content bind search
                    hint = "Search by path"
                }
            }
            row {
                centered - checkbox {
                    checked bind limitToNoImage
                }
                centered - text("Limit to categories without image")
            }
        }
        expanding - col {
            expanding - recyclerView {
                ::columns { if (narrow()) 1 else 2 }
                reactiveScope {
                    if (lastVisibleIndex() > products().limit - 20)
                        products().limit = lastVisibleIndex() + 100
                }
                val items = shared { products()() }
                children(items) { product ->
                    card - button {
                        spacing = 0.px
                        productCategoryCardContent(product, usePath = true)
                        onClick {
                            val file = ExternalServices.requestFile(listOf("image/*")) ?: return@onClick
                            val req = currentSession().nonCached.uploadFileForRequest()
                            fetch(req.uploadUrl, HttpMethod.PUT, body = file)
                            currentSession().productCategories[product()._id].modify(modification { it.image assign ServerFile(req.futureCallToken) })
                        }
                    }
                }
            }
        }
    }
}