package com.virtualrain.sdk.erp

import com.lightningkite.kiteui.ConsoleRoot
import com.lightningkite.kiteui.reactive.ReactiveContext
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.now
import com.virtualrain.models.VBErpContact
import com.virtualrain.sdk.UserSession
import com.virtualrain.sdk.utils.CachedCalculation
import kotlin.time.Duration
import kotlinx.datetime.Instant

class ContactsCache(val session: UserSession, val cacheTime: Duration) {
    private val cache by lazy {
        CachedCalculation(cacheTime) {
            val account = session.accountErpId
            if (account == null) {
                log.warn("Cannot send contacts request, accountErpId is null")
                emptyList()
            }
            else session.nonCached.getContactsForAccount(account)
        }

    }
    private val log = ConsoleRoot.tag("ContactsCache")

    fun request(): Readable<List<VBErpContact>> = cache
}