package com.virtualrain.views.screens.cart

import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.now
import com.lightningkite.serialization.*

import com.virtualrain.mappings.dynamicLens
import com.virtualrain.mappings.fromData
import com.virtualrain.models.*
import com.virtualrain.sdk.currentSession
import com.virtualrain.sdk.flatten
import com.virtualrain.sdk.utils.activeWarehouses
import com.virtualrain.sdk.utils.creationError
import com.virtualrain.sdk.utils.notFoundError
import com.virtualrain.theming.checkboxTheme
import com.virtualrain.theming.lightOutline
import com.virtualrain.validation.Validator
import com.virtualrain.validation.interceptWrite
import com.virtualrain.validation.validate
import com.virtualrain.views.screens.account.orders.OrderScreen
import com.virtualrain.views.screens.common.HasNarrowContent
import com.virtualrain.views.textFormatting.formatName
import com.virtualrain.views.textFormatting.randomID
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlin.random.Random

suspend fun checkout(cart: Writable<Cart>): CheckoutDialog {
    val read = cart()
    return CheckoutDialog(read.items, read.warehouse, cartId = read._id)
}

class CheckoutDialog(val items: Set<CartItem>, sourceWarehouse: UUID, val cartId: UUID? = null) : Screen, HasNarrowContent, Validator() {
    val account: Readable<CustomerAccount> = shared {
        val session = currentSession()
        session.customerAccounts[session.accountId!!]() ?: notFoundError<CustomerAccount>()
    }

    private val contacts = shared<List<VBErpContact>> {
        currentSession().contacts.request()()
    }

    val pricing = shared { currentSession().pricing.request(order())() }

    val order = Draft {
        val session = currentSession()
        val self = session.self()

        Order(
            account = account()._id,
            poNum = "",
            items = items.toSet(),
            listPriceTotal = (-1).dollars,
            total = (-1).dollars,
            warehouse = sourceWarehouse,
            contactErpID = contacts.once().firstOrNull { it?.email == self.email }?.contactId,
            contactEmail = contacts.once().firstOrNull { it?.email == self.email }?.email,
            orderedBy = currentSession().self()._id
        )
    }

    override fun ViewWriter.render() {
        dismissBackground {
            spacing = 2.rem
            ::spacing {
                if (narrow()) 2.rem
                else 7.rem
            }

            reactiveScope {
                clearChildren()
                if (!narrow()) sizeConstraints(width = 50.rem)
            }
            centered - card - scrolls - col {
                spacing = 1.5.rem

                row {
                    expanding - h2("Checkout")

                    atTopEnd - button {
                        spacing = 0.rem
                        icon { source = Icon.close }
                        onClick { dialogScreenNavigator.dismiss() }
                    }
                }

                separator()

                val warehouses = shared {
                    val locations = activeWarehouses()()

                    val preferred = account().preferredPickupLocation ?: return@shared locations
                    locations.sortedByDescending {
                        it._id == preferred
                    }
                }

                val sourceWarehouse = order.lensPath { it.warehouse }.fromData(warehouses)

                val shippingLocations = shared {
                    val session = currentSession()
                    val a = account()
                    val locations = session.shippingAddresses.query(Query(
                        condition { it.account eq a._id }
                    ))() + null

                    val preferred = account().preferredShippingAddress ?: return@shared locations
                    locations.sortedByDescending {
                        it?._id == preferred
                    }
                }

                val shippingLocation = order.lensPath { it.shipTo }.fromData(shippingLocations)

                label {
                    content = "Source Warehouse"

                    fieldTheme - select {
                        bind(
                            edits = sourceWarehouse,
                            data = warehouses,
                            render = { it.formatName() }
                        )
                    }
                }

                separator()

                col {
                    val ship = LazyProperty { shippingLocation() != null }.interceptWrite {
                        if (!it) shippingLocation set null
                        value = it
                    }

                    row {
                        expanding - lightOutline - radioToggleButton {
                            centered - text("Pickup")
                            checked bind ship.equalTo(false)
                        }

                        centered - text("or")

                        expanding - lightOutline - radioToggleButton {
                            centered - text("Ship")
                            checked bind ship
                        }
                    }

                    onlyWhen { ship() } - label {
                        content = "Shipping Location"

                        fieldTheme - validate {
                            if (ship()) (shippingLocation() != null) else true
                        } - select {
                            bind(
                                edits = shippingLocation,
                                data = shippingLocations,
                                render = {
                                    if (it == null) "None"
                                    else {
                                        it.name ?: it.address.businessName
                                    }
                                }
                            )
                        }
                    }
                }

                separator()

                label {
                    content = "Custom Purchase Order Number (Optional)"
                    fieldTheme - textInput {
                        hint = "PO Number"

                        content bind order.lensPath { it.poNum }
                    }
                }

                label {
                    content = "Order Comment (Optional)"

                    fieldTheme - textArea {
                        content bind order.lensPath { it.comment }
                    }
                }

                stack {
                    label {
                        content = "Contact"

//                                ::exists { contacts().size > 1 }

                        val contact = order.lensPath { it.contactErpID }.dynamicLens(
                            get = { id -> contacts().find { it.contactId == id } },
                            set = { it?.contactId },
                        )
                        launch {
                            contacts().firstOrNull()?.let {
                                if (contact() == null) contact set it
                            }
                        }

                        fieldTheme - select {
                            bind(contact, contacts) { it?.fullName ?: "None" }
                        }
                    }
                }

                onlyWhen { items.anyUnderstocked(order().warehouse)() } - lightOutline - col {
                    row {
                        checkboxTheme - checkbox {
                            checked bind order.lensPath { it.shipASAP }
                        }
                        centered - text("Ship ASAP")
                    }
                    subtext(
                        "Some of the items you're about to order have higher quantities than what's in stock. If selected, your order will be shipped as soon as possible with the items currently available, with the remaining items shipped when acquired. If not selected, your order will be delayed until all items are ready. Additional shipping charges may apply."
                    )
                }

                cartId?.let {
                    val cart = shared { currentSession().carts[it] }.flatten().waitForNotNull
                    reactive {
                        println("Cart keep = ${cart().keepAfterOrder}")
                    }
                    reactive {
                        println("Cart keep 2 = ${cart.lensPath { it.keepAfterOrder }()}")
                    }
                    lightOutline - row {
                        checkboxTheme - checkbox {
                            checked bind cart.lensPath { it.keepAfterOrder }
                        }
                        centered - text {
                            ::content {
                                val name = cart().name?.let { "'$it'" } ?: "Cart"
                                "Keep $name After Order"
                            }
                        }
                    }
                }
                separator()

                rowCollapsingToColumn {
                    fun total(setup: RView.() -> Unit) {
                        h6 {
                            setup()
                            ::content { "Order Total: ${pricing().priceString()}" }
                        }
                    }

                    centered - bold - expanding - total { existsWhenWide() }
                    bold - total { existsWhenNarrow() }

                    card - button {
                        h6("Cancel")
                        onClick { dialogScreenNavigator.dismiss() }
                    }

                    important - button {
                        ::enabled { allValid() }

                        h6("Place Order")

                        onClick {
                            val itemsWithPreviousPrices = pricing()

                            order.modify { ord ->
                                ord.copy(
                                    poNum = ord.poNum.takeUnless { it.isBlank() } ?: randomID(),
                                    items = itemsWithPreviousPrices,
                                    total = itemsWithPreviousPrices.sumPrice { it.previousLineTotal!! },
                                    listPriceTotal = itemsWithPreviousPrices.sumPrice { it.previousListPrice!! * it.quantity },
                                    orderedAt = now()
                                )
                            }

                            val order = order.publish()

                            currentSession().orders.insert(order)() ?: creationError<Order>()

                            cartId?.let {
                                val cartProp = currentSession().carts[it]
                                val cart = cartProp()
                                if (cart?.keepAfterOrder == true) {
                                    cartProp.modify(modification {
                                        it.items assign itemsWithPreviousPrices
                                        it.previousOrder assign order._id
                                    })
                                } else cartProp.delete()
                            }

                            mainScreenNavigator.navigate(OrderScreen(order._id).also {
                                it.isConfirmation = true
                            })
                            dialogScreenNavigator.dismiss()
                        }
                    }

                    space { existsWhenNarrow() }
                }
            }

        }
    }
}
