package com.virtualrain.admin

import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.HttpMethod
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.now
import com.lightningkite.serialization.*
import com.virtualrain.mappings.*
import com.virtualrain.mappings.nullable
import com.virtualrain.models.*
import com.virtualrain.sdk.currentSession
import com.virtualrain.theming.*
import com.virtualrain.validation.Validator
import com.virtualrain.validation.validate
import com.virtualrain.views.components.special
import com.virtualrain.views.screens.products.MessageHandler
import kotlin.time.Duration.Companion.days


@Routable("/specials")
class AdminSpecialsScreen : Screen, Validator() {
    override val title: Readable<String> = Constant("Specials")

    val now = now()
    private val active: LazyProperty<Writable<PopupMessage?>?> = LazyProperty {
        val session = currentSession()
        session.popupMessages.query(
            Query(
                condition {
                    (it.startTime lte now) and (it.stopTime gte now)
                },
                orderBy = sort { it.startTime.descending() },
                limit = 1
            )
        )().firstOrNull()?.let {
            session.popupMessages[it._id]
        }
    }
    private val draft = draftOf { active() ?: UnWritable(ReadableState(null)) }

    private val isNewMessage = Property(false)
    private var previous: UUID? = null

    private val imageOverride = Property<ImageSource?>(null)

    override fun ViewWriter.render() {
        scrolls - col {
            row {
                centered - expanding - h2 {
                    ::content {
                        if (active() == null) "No Active Specials"
                        else if (!isNewMessage()) "Active Special"
                        else "New Special"
                    }
                }

                atTopEnd - buttonTheme - button {
                    centered - row {
                        icon(Icon.add, "New Special")
                        centered - text("Create New")
                    }
                    onClick {
                        suspend fun addNew() {
                            draft.cancel()
                            isNewMessage.value = true
                            active.value = Property(
                                PopupMessage(
                                    message = "",
                                    startTime = now(),
                                    stopTime = now() + 1.days,
                                )
                            ).interceptWrite { new ->
                                currentSession().popupMessages[value._id].set(new)
                                value = new
                            }.nullable()
                        }

                        val a = active()
                        if (a != null) confirmDanger(
                            title = "New Special",
                            body = "Creating a new special will override the currently active special. Are you sure you want to do this?",
                            actionName = "Confirm",
                            action = {
                                a()?.let { previous = it._id }
                                addNew()
                            }
                        ) else addNew()
                    }
                }
            }

            separator()

            special(draft) {
                enabled = false
                ::imageSource { imageOverride() }
            }

            card - col {
                spacing = 1.5.rem
                exists = false
                ::exists { active() != null }

                row {
                    spacing = 2.rem

                    expanding - label {
                        content = "Title"

                        val title = draft.lensPath { it.notNull.title }.validate { it.isNotBlank() }
                        fieldTheme - validate(title) - textInput {
                            content bind title
                        }
                    }

                    label {
                        content = "When"

                        row {
                            val start = draft.map { it.notNull.startTime }.toLocalDateTime().asNullable().validateNotNull()
                            fieldTheme - validate(start) - localDateTimeField {
                                content bind start
                            }

                            centered - text("-")

                            val stop = draft.map { it.notNull.stopTime }.toLocalDateTime().asNullable().validate {
                                if (it == null) false
                                else {
                                    val s = start.invoke()
                                    if (s == null) false
                                    else if (it < s) false
                                    else true
                                }
                            }
                            fieldTheme - validate(stop) - localDateTimeField {
                                content bind stop
                            }
                        }
                    }
                }

                row {
                    spacing = 2.rem

                    expanding - label {
                        content = "Image"

                        fieldTheme - row {
                            spacing = 0.px
                            expanding - button {
                                centered - row {
                                    icon { source = Icon.upload }
                                    centered - text("Upload Image")
                                }
                                onClick {
                                    val file = ExternalServices.requestFile(listOf("image/*")) ?: return@onClick
                                    val req = currentSession().nonCached.uploadFileForRequest()
                                    com.lightningkite.kiteui.fetch(req.uploadUrl, HttpMethod.PUT, body = file)
                                    imageOverride.value = ImageLocal(file)
                                    draft.modify { it?.copy(image = ServerFile(req.futureCallToken)) }
                                }
                            }

                            onlyWhen { draft()?.image != null } - button {
                                spacing = 0.5.rem
                                centered - icon(Icon.close, "Remove Picture")
                                onClick {
                                    draft.modify { it?.copy(image = null) }
                                    imageOverride.value = null
                                }
                            }
                        }
                    }

                    expanding - label {
                        val linked: Readable<CatalogItem?> = shared {
                            val message = draft()

                            message?.product?.let {
                                currentSession().products[it]()
                            } ?: message?.category?.let {
                                currentSession().productCategories[it]()
                            }
                        }
                        content = "Link"
                        ::content {
                            when (linked()) {
                                is Product -> "Product Link"
                                is ProductCategory -> "Category Link"
                                null -> "Link"
                            }
                        }

                        fieldTheme - row {
                            spacing = 0.px
                            expanding - button {
                                centered - row {
                                    centered - icon {
                                        ::source {
                                            if (linked() == null) Icon.addLink
                                            else Icon.link
                                        }
                                    }
                                    centered - text {
                                        ::content { linked()?.name ?: "Add Link" }
                                    }
                                }

                                onClick {
                                    dialogScreenNavigator.navigate(LinkDialog())
                                }
                            }

                            onlyWhen { linked() != null } - button {
                                spacing = 0.5.rem
                                centered - icon(Icon.close, "Remove Picture")
                                onClick {
                                    draft.modify { it?.copy(product = null, category = null) }
                                }
                            }
                        }
                    }

                    weight(0.8f) - label {
                        content = "Show"

                        fieldTheme - select {
                            bind(
                                edits = draft.map { it.notNull.behavior },
                                data = Constant(PopupBehavior.entries),
                                render = { it.displayName }
                            )
                        }
                    }
                }

                label {
                    content = "Message (HTML Supported)"

                    val message = draft.map { it.notNull.message }
                    sizeConstraints(minHeight = 20.rem) - fieldTheme - textArea {
                        content bind message
                    }
                }

                atEnd - onlyWhen { draft.changesMade() or isNewMessage() } - row {
                    important - button {
                        ::exists { !isNewMessage() }
                        centered - icon(Icon.close, "Discard Changes")
                        onClick {
                            imageOverride.value = null
                            draft.cancel()
                        }
                    }
                    important - button {
                        ::enabled { allValid() }
                        centered - row {
                            icon {
                                ::source {
                                    if (isNewMessage()) Icon.send
                                    else Icon.save
                                }
                            }
                            centered - text {
                                ::content {
                                    if (isNewMessage()) "Publish Special"
                                    else "Save Changes"
                                }
                            }
                        }
                        onClick {
                            val published = draft.publish() ?: return@onClick
                            if (isNewMessage()) {
                                previous?.let {
                                    currentSession().popupMessages[it].delete()
//                                    println("$it deleted")
                                }
                            }
                            MessageHandler.update(published)
                            isNewMessage.value = false
                        }
                    }
                }
            }
        }
    }

    private enum class LinkType { Product, Category }
    private inner class LinkDialog: Screen {
        private val query = Property("")
        private val debounced = query.debounce(500)

        private val selectedItem = Property<CatalogItem?>(null)

        private fun ViewWriter.categoryPicker() {
            val categories = shared {
                currentSession().productCategories.query(
                    Query(
                        debounced().takeUnless { it.isBlank() }?.let { q -> condition { it.name.contains(q) } } ?: Condition.Always(),
                        sort { it.name.ascending() },
                        limit = 200
                    )
                )()
            }

            col {
                important - row {
                    centered - icon { source = Icon.search }

                    expanding - textInput {
                        hint = "Search categories by name"

                        content bind query

                        requestFocus()
                    }

                    button {
                        spacing = 0.rem

                        icon { source = Icon.close }
                        onClick { query.value = "" }
                    }
                }

                expanding - recyclerView {
                    children(categories) { category ->
                        lightOutline - toggleButton() {
                            centered - text { ::content { category().name } }
                            checked bind selectedItem.equalToDynamic(category)
                        }
                    }
                }
            }
        }

        private fun ViewWriter.productPicker() {
            col {
                val products = shared {
                    currentSession().products.query(
                        Query(
                            debounced().takeUnless { it.isBlank() }?.let { q ->
                                condition {
                                    (it.title.contains(q)) or (it.erpId.contains(q))
                                }
                            } ?: Condition.Always(),
                            sort { it.title.ascending() },
                            limit = 200
                        )
                    )()
                }

                important - row {
                    centered - icon { source = Icon.search }

                    expanding - textInput {
                        hint = "Search products by name or id"

                        content bind query

                        requestFocus()
                    }

                    button {
                        spacing = 0.rem

                        icon { source = Icon.close }
                        onClick { query.value = "" }
                    }
                }

                expanding - recyclerView {
                    children(products) { product ->
                        lightOutline - toggleButton {
                            centered - text { ::content { product().name } }
                            checked bind selectedItem.equalToDynamic(product)
                        }
                    }
                }
            }
        }

        private val picker = Property(LinkType.Product)

        override fun ViewWriter.render() {
            dismissBackground {
                sizeConstraints(width = 35.rem) - centered - card - col {
                    row {
                        centered - expanding - h2("Link")

                        button {
                            spacing = 0.5.rem
                            centered - icon(Icon.close, "Close Dialog")
                            onClick { dialogScreenNavigator.dismiss() }
                        }
                    }

                    label {
                        content = "to"

                        row {
                            expanding - lightOutline - toggleButton {
                                centered - text("Product")
                                checked bind picker.equalTo(LinkType.Product)
                            }

                            expanding - lightOutline - toggleButton {
                                centered - text("Category")
                                checked bind picker.equalTo(LinkType.Category)
                            }
                        }
                    }

                    sizeConstraints(height = 32.rem) - lightOutline - swapView {
                        swapping(
                            current = { picker() },
                            views = {
                                when (it) {
                                    LinkType.Product -> productPicker()
                                    LinkType.Category -> categoryPicker()
                                }
                            }
                        )
                    }

                    atEnd - sizeConstraints(width = 10.rem) - important - button {
                        spacing = 0.5.rem
                        ::enabled { selectedItem() != null }

                        centered - row {
                            icon(Icon.save, "Save Selection")
                            centered - text("Save")
                        }

                        onClick {
                            draft.modify {
                                when (val s = selectedItem.value) {
                                    is Product -> it?.copy(product = s._id, category = null)
                                    is ProductCategory -> it?.copy(product = null, category = s._id)
                                    null -> it
                                }
                            }
                            dialogScreenNavigator.dismiss()
                        }
                    }
                }
            }
        }
    }
}