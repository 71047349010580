// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareWarehouseFields() {
    val props: Array<SerializableProperty<Warehouse, *>> = arrayOf(Warehouse__id, Warehouse_erpId, Warehouse_name, Warehouse_address, Warehouse_public, Warehouse_permittedAccounts)
    Warehouse.serializer().properties { props }
}
val <K> DataClassPath<K, Warehouse>._id: DataClassPath<K, UUID> get() = this[Warehouse__id]
val <K> DataClassPath<K, Warehouse>.erpId: DataClassPath<K, String?> get() = this[Warehouse_erpId]
val <K> DataClassPath<K, Warehouse>.name: DataClassPath<K, String> get() = this[Warehouse_name]
val <K> DataClassPath<K, Warehouse>.address: DataClassPath<K, Address> get() = this[Warehouse_address]
val <K> DataClassPath<K, Warehouse>.public: DataClassPath<K, Boolean> get() = this[Warehouse_public]
val <K> DataClassPath<K, Warehouse>.permittedAccounts: DataClassPath<K, Set<UUID>> get() = this[Warehouse_permittedAccounts]
inline val Warehouse.Companion.path: DataClassPath<Warehouse, Warehouse> get() = path<Warehouse>()


object Warehouse__id: SerializableProperty<Warehouse, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Warehouse): UUID = receiver._id
    override fun setCopy(receiver: Warehouse, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Warehouse.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object Warehouse_erpId: SerializableProperty<Warehouse, String?> {
    override val name: String = "erpId"
    override fun get(receiver: Warehouse): String? = receiver.erpId
    override fun setCopy(receiver: Warehouse, value: String?) = receiver.copy(erpId = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Warehouse.serializer().tryFindAnnotations("erpId")
    override val default: String? = null
}
object Warehouse_name: SerializableProperty<Warehouse, String> {
    override val name: String = "name"
    override fun get(receiver: Warehouse): String = receiver.name
    override fun setCopy(receiver: Warehouse, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Warehouse.serializer().tryFindAnnotations("name")
}
object Warehouse_address: SerializableProperty<Warehouse, Address> {
    override val name: String = "address"
    override fun get(receiver: Warehouse): Address = receiver.address
    override fun setCopy(receiver: Warehouse, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> = Address.serializer()
    override val annotations: List<Annotation> = Warehouse.serializer().tryFindAnnotations("address")
}
object Warehouse_public: SerializableProperty<Warehouse, Boolean> {
    override val name: String = "public"
    override fun get(receiver: Warehouse): Boolean = receiver.public
    override fun setCopy(receiver: Warehouse, value: Boolean) = receiver.copy(public = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = Warehouse.serializer().tryFindAnnotations("public")
    override val default: Boolean = true
}
object Warehouse_permittedAccounts: SerializableProperty<Warehouse, Set<UUID>> {
    override val name: String = "permittedAccounts"
    override fun get(receiver: Warehouse): Set<UUID> = receiver.permittedAccounts
    override fun setCopy(receiver: Warehouse, value: Set<UUID>) = receiver.copy(permittedAccounts = value)
    override val serializer: KSerializer<Set<UUID>> = SetSerializer(ContextualSerializer(UUID::class))
    override val annotations: List<Annotation> = Warehouse.serializer().tryFindAnnotations("permittedAccounts")
    override val default: Set<UUID> = setOf()
}
