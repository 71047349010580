// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareAddressFields() {
    val props: Array<SerializableProperty<Address, *>> = arrayOf(Address_individualName, Address_businessName, Address_street, Address_unitOrSuite, Address_city, Address_subcountry, Address_country, Address_postalCode)
    Address.serializer().properties { props }
}
val <K> DataClassPath<K, Address>.individualName: DataClassPath<K, String?> get() = this[Address_individualName]
val <K> DataClassPath<K, Address>.businessName: DataClassPath<K, String> get() = this[Address_businessName]
val <K> DataClassPath<K, Address>.street: DataClassPath<K, String> get() = this[Address_street]
val <K> DataClassPath<K, Address>.unitOrSuite: DataClassPath<K, String?> get() = this[Address_unitOrSuite]
val <K> DataClassPath<K, Address>.city: DataClassPath<K, String> get() = this[Address_city]
val <K> DataClassPath<K, Address>.subcountry: DataClassPath<K, IsoCode2Letter> get() = this[Address_subcountry]
val <K> DataClassPath<K, Address>.country: DataClassPath<K, IsoCode2Letter> get() = this[Address_country]
val <K> DataClassPath<K, Address>.postalCode: DataClassPath<K, String> get() = this[Address_postalCode]
inline val Address.Companion.path: DataClassPath<Address, Address> get() = path<Address>()


object Address_individualName: SerializableProperty<Address, String?> {
    override val name: String = "individualName"
    override fun get(receiver: Address): String? = receiver.individualName
    override fun setCopy(receiver: Address, value: String?) = receiver.copy(individualName = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("individualName")
    override val default: String? = null
}
object Address_businessName: SerializableProperty<Address, String> {
    override val name: String = "businessName"
    override fun get(receiver: Address): String = receiver.businessName
    override fun setCopy(receiver: Address, value: String) = receiver.copy(businessName = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("businessName")
}
object Address_street: SerializableProperty<Address, String> {
    override val name: String = "street"
    override fun get(receiver: Address): String = receiver.street
    override fun setCopy(receiver: Address, value: String) = receiver.copy(street = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("street")
}
object Address_unitOrSuite: SerializableProperty<Address, String?> {
    override val name: String = "unitOrSuite"
    override fun get(receiver: Address): String? = receiver.unitOrSuite
    override fun setCopy(receiver: Address, value: String?) = receiver.copy(unitOrSuite = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("unitOrSuite")
    override val default: String? = null
}
object Address_city: SerializableProperty<Address, String> {
    override val name: String = "city"
    override fun get(receiver: Address): String = receiver.city
    override fun setCopy(receiver: Address, value: String) = receiver.copy(city = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("city")
}
object Address_subcountry: SerializableProperty<Address, IsoCode2Letter> {
    override val name: String = "subcountry"
    override fun get(receiver: Address): IsoCode2Letter = receiver.subcountry
    override fun setCopy(receiver: Address, value: IsoCode2Letter) = receiver.copy(subcountry = value)
    override val serializer: KSerializer<IsoCode2Letter> = IsoCode2Letter.serializer()
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("subcountry")
}
object Address_country: SerializableProperty<Address, IsoCode2Letter> {
    override val name: String = "country"
    override fun get(receiver: Address): IsoCode2Letter = receiver.country
    override fun setCopy(receiver: Address, value: IsoCode2Letter) = receiver.copy(country = value)
    override val serializer: KSerializer<IsoCode2Letter> = IsoCode2Letter.serializer()
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("country")
}
object Address_postalCode: SerializableProperty<Address, String> {
    override val name: String = "postalCode"
    override fun get(receiver: Address): String = receiver.postalCode
    override fun setCopy(receiver: Address, value: String) = receiver.copy(postalCode = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Address.serializer().tryFindAnnotations("postalCode")
}
