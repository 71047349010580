// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareDistributorFields() {
    val props: Array<SerializableProperty<Distributor, *>> = arrayOf(Distributor__id, Distributor_companyName, Distributor_email, Distributor_address, Distributor_emailOnNewAccount, Distributor_emailOnNewOrder, Distributor_logo)
    Distributor.serializer().properties { props }
}
val <K> DataClassPath<K, Distributor>._id: DataClassPath<K, String> get() = this[Distributor__id]
val <K> DataClassPath<K, Distributor>.companyName: DataClassPath<K, String> get() = this[Distributor_companyName]
val <K> DataClassPath<K, Distributor>.email: DataClassPath<K, String> get() = this[Distributor_email]
val <K> DataClassPath<K, Distributor>.address: DataClassPath<K, Address> get() = this[Distributor_address]
val <K> DataClassPath<K, Distributor>.emailOnNewAccount: DataClassPath<K, Set<String>> get() = this[Distributor_emailOnNewAccount]
val <K> DataClassPath<K, Distributor>.emailOnNewOrder: DataClassPath<K, Set<String>> get() = this[Distributor_emailOnNewOrder]
val <K> DataClassPath<K, Distributor>.logo: DataClassPath<K, ServerFile?> get() = this[Distributor_logo]
inline val Distributor.Companion.path: DataClassPath<Distributor, Distributor> get() = path<Distributor>()


object Distributor__id: SerializableProperty<Distributor, String> {
    override val name: String = "_id"
    override fun get(receiver: Distributor): String = receiver._id
    override fun setCopy(receiver: Distributor, value: String) = receiver.copy(_id = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Distributor.serializer().tryFindAnnotations("_id")
    override val default: String = "singleton"
}
object Distributor_companyName: SerializableProperty<Distributor, String> {
    override val name: String = "companyName"
    override fun get(receiver: Distributor): String = receiver.companyName
    override fun setCopy(receiver: Distributor, value: String) = receiver.copy(companyName = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Distributor.serializer().tryFindAnnotations("companyName")
}
object Distributor_email: SerializableProperty<Distributor, String> {
    override val name: String = "email"
    override fun get(receiver: Distributor): String = receiver.email
    override fun setCopy(receiver: Distributor, value: String) = receiver.copy(email = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Distributor.serializer().tryFindAnnotations("email")
}
object Distributor_address: SerializableProperty<Distributor, Address> {
    override val name: String = "address"
    override fun get(receiver: Distributor): Address = receiver.address
    override fun setCopy(receiver: Distributor, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> = Address.serializer()
    override val annotations: List<Annotation> = Distributor.serializer().tryFindAnnotations("address")
}
object Distributor_emailOnNewAccount: SerializableProperty<Distributor, Set<String>> {
    override val name: String = "emailOnNewAccount"
    override fun get(receiver: Distributor): Set<String> = receiver.emailOnNewAccount
    override fun setCopy(receiver: Distributor, value: Set<String>) = receiver.copy(emailOnNewAccount = value)
    override val serializer: KSerializer<Set<String>> = SetSerializer(String.serializer())
    override val annotations: List<Annotation> = Distributor.serializer().tryFindAnnotations("emailOnNewAccount")
    override val default: Set<String> = emptySet()
}
object Distributor_emailOnNewOrder: SerializableProperty<Distributor, Set<String>> {
    override val name: String = "emailOnNewOrder"
    override fun get(receiver: Distributor): Set<String> = receiver.emailOnNewOrder
    override fun setCopy(receiver: Distributor, value: Set<String>) = receiver.copy(emailOnNewOrder = value)
    override val serializer: KSerializer<Set<String>> = SetSerializer(String.serializer())
    override val annotations: List<Annotation> = Distributor.serializer().tryFindAnnotations("emailOnNewOrder")
    override val default: Set<String> = emptySet()
}
object Distributor_logo: SerializableProperty<Distributor, ServerFile?> {
    override val name: String = "logo"
    override fun get(receiver: Distributor): ServerFile? = receiver.logo
    override fun setCopy(receiver: Distributor, value: ServerFile?) = receiver.copy(logo = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = Distributor.serializer().tryFindAnnotations("logo")
    override val default: ServerFile? = null
}
