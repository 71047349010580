package com.virtualrain.views.screens.products

import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.reactiveScope
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.card
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.lightningdb.*
import com.virtualrain.models.categories
import com.virtualrain.models.name
import com.virtualrain.models.parent
import com.virtualrain.models.title
import com.virtualrain.sdk.currentSession
import com.virtualrain.views.components.productCard
import com.virtualrain.views.components.productCategoryCard
import com.virtualrain.views.screens.common.HasNarrowContent

@Routable("Category/{categoryID}")
class CategoryScreen(val categoryID: UUID) : Screen, HasNarrowContent {

    val category = shared {
        currentSession().productCategories[categoryID]()!!
    }

    val childCategories = shared {
        currentSession().productCategories.query(Query(
            condition = condition { it.parent eq categoryID },
            orderBy = sort { it.name.ascending() }
        )).invoke()
    }
    val products = shared {
        currentSession().products.query(Query(
            condition { prod -> prod.categories.any { it eq categoryID } },
            orderBy = sort { it.title.ascending() }
        ))()
    }

    override val title: Readable<String> = shared {
        return@shared if (narrow()) category().name else category().path
    }

    override fun ViewWriter.render() {
        stack {
            val indicator: ActivityIndicator
            centered - activityIndicator {
                indicator = this
            }
            stack {
                reactiveScope {
                    indicator.exists = true
                    clearChildren()
                    val hasChildCategories = childCategories().isNotEmpty()
                    indicator.exists = false
                    if (hasChildCategories) {
                        recyclerView {
                            ::columns { if (narrow()) 1 else 2 }

                            children(childCategories) {
                                productCategoryCard(it)
                            }
                        }
                    } else {
                        recyclerView {
                            ::columns { if (narrow()) 1 else 2 }

                            children(products) {
                                card - productCard(it, imageHeight = 5.rem)
                            }
                        }
                    }
                }
            }
        }
    }
}