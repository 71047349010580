// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun preparePriceIncreaseFields() {
    val props: Array<SerializableProperty<PriceIncrease, *>> = arrayOf(PriceIncrease__id, PriceIncrease_increasesAt, PriceIncrease_message, PriceIncrease_products)
    PriceIncrease.serializer().properties { props }
}
val <K> DataClassPath<K, PriceIncrease>._id: DataClassPath<K, UUID> get() = this[PriceIncrease__id]
val <K> DataClassPath<K, PriceIncrease>.increasesAt: DataClassPath<K, Instant> get() = this[PriceIncrease_increasesAt]
val <K> DataClassPath<K, PriceIncrease>.message: DataClassPath<K, String> get() = this[PriceIncrease_message]
val <K> DataClassPath<K, PriceIncrease>.products: DataClassPath<K, Set<UUID>> get() = this[PriceIncrease_products]
inline val PriceIncrease.Companion.path: DataClassPath<PriceIncrease, PriceIncrease> get() = path<PriceIncrease>()


object PriceIncrease__id: SerializableProperty<PriceIncrease, UUID> {
    override val name: String = "_id"
    override fun get(receiver: PriceIncrease): UUID = receiver._id
    override fun setCopy(receiver: PriceIncrease, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = PriceIncrease.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object PriceIncrease_increasesAt: SerializableProperty<PriceIncrease, Instant> {
    override val name: String = "increasesAt"
    override fun get(receiver: PriceIncrease): Instant = receiver.increasesAt
    override fun setCopy(receiver: PriceIncrease, value: Instant) = receiver.copy(increasesAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = PriceIncrease.serializer().tryFindAnnotations("increasesAt")
}
object PriceIncrease_message: SerializableProperty<PriceIncrease, String> {
    override val name: String = "message"
    override fun get(receiver: PriceIncrease): String = receiver.message
    override fun setCopy(receiver: PriceIncrease, value: String) = receiver.copy(message = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = PriceIncrease.serializer().tryFindAnnotations("message")
}
object PriceIncrease_products: SerializableProperty<PriceIncrease, Set<UUID>> {
    override val name: String = "products"
    override fun get(receiver: PriceIncrease): Set<UUID> = receiver.products
    override fun setCopy(receiver: PriceIncrease, value: Set<UUID>) = receiver.copy(products = value)
    override val serializer: KSerializer<Set<UUID>> = SetSerializer(ContextualSerializer(UUID::class))
    override val annotations: List<Annotation> = PriceIncrease.serializer().tryFindAnnotations("products")
}
