// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareSyncTaskStatusFields() {
    val props: Array<SerializableProperty<SyncTaskStatus, *>> = arrayOf(SyncTaskStatus__id, SyncTaskStatus_startedAt, SyncTaskStatus_finishedAt, SyncTaskStatus_duration, SyncTaskStatus_exception)
    SyncTaskStatus.serializer().properties { props }
}
val <K> DataClassPath<K, SyncTaskStatus>._id: DataClassPath<K, String> get() = this[SyncTaskStatus__id]
val <K> DataClassPath<K, SyncTaskStatus>.startedAt: DataClassPath<K, Instant?> get() = this[SyncTaskStatus_startedAt]
val <K> DataClassPath<K, SyncTaskStatus>.finishedAt: DataClassPath<K, Instant?> get() = this[SyncTaskStatus_finishedAt]
val <K> DataClassPath<K, SyncTaskStatus>.duration: DataClassPath<K, Duration?> get() = this[SyncTaskStatus_duration]
val <K> DataClassPath<K, SyncTaskStatus>.exception: DataClassPath<K, String?> get() = this[SyncTaskStatus_exception]
inline val SyncTaskStatus.Companion.path: DataClassPath<SyncTaskStatus, SyncTaskStatus> get() = path<SyncTaskStatus>()


object SyncTaskStatus__id: SerializableProperty<SyncTaskStatus, String> {
    override val name: String = "_id"
    override fun get(receiver: SyncTaskStatus): String = receiver._id
    override fun setCopy(receiver: SyncTaskStatus, value: String) = receiver.copy(_id = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = SyncTaskStatus.serializer().tryFindAnnotations("_id")
    override val default: String = "main"
}
object SyncTaskStatus_startedAt: SerializableProperty<SyncTaskStatus, Instant?> {
    override val name: String = "startedAt"
    override fun get(receiver: SyncTaskStatus): Instant? = receiver.startedAt
    override fun setCopy(receiver: SyncTaskStatus, value: Instant?) = receiver.copy(startedAt = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = SyncTaskStatus.serializer().tryFindAnnotations("startedAt")
    override val default: Instant? = null
}
object SyncTaskStatus_finishedAt: SerializableProperty<SyncTaskStatus, Instant?> {
    override val name: String = "finishedAt"
    override fun get(receiver: SyncTaskStatus): Instant? = receiver.finishedAt
    override fun setCopy(receiver: SyncTaskStatus, value: Instant?) = receiver.copy(finishedAt = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = SyncTaskStatus.serializer().tryFindAnnotations("finishedAt")
    override val default: Instant? = null
}
object SyncTaskStatus_duration: SerializableProperty<SyncTaskStatus, Duration?> {
    override val name: String = "duration"
    override fun get(receiver: SyncTaskStatus): Duration? = receiver.duration
    override fun setCopy(receiver: SyncTaskStatus, value: Duration?) = receiver.copy(duration = value)
    override val serializer: KSerializer<Duration?> = Duration.serializer().nullable2
    override val annotations: List<Annotation> = SyncTaskStatus.serializer().tryFindAnnotations("duration")
    override val default: Duration? = null
}
object SyncTaskStatus_exception: SerializableProperty<SyncTaskStatus, String?> {
    override val name: String = "exception"
    override fun get(receiver: SyncTaskStatus): String? = receiver.exception
    override fun setCopy(receiver: SyncTaskStatus, value: String?) = receiver.copy(exception = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = SyncTaskStatus.serializer().tryFindAnnotations("exception")
    override val default: String? = null
}
