package com.virtualrain.views.screens.auth

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.forms.AuthComponent
import com.lightningkite.kiteui.models.Action
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningserver.auth.AuthClientEndpoints
import com.virtualrain.mappings.validate
import com.virtualrain.onNextSuccess
import com.virtualrain.sdk.AbstractAnonymousSession
import com.virtualrain.sdk.currentSession
import com.virtualrain.sdk.selectedApi
import com.virtualrain.sdk.sessionToken
import com.virtualrain.theming.InvalidMessage
import com.virtualrain.theming.emphasized
import com.virtualrain.views.screens.products.CatalogScreen

sealed interface AuthScreen : Screen

@Routable("/auth/login")
class LoginScreen : AuthScreen {
    val authComponent = shared {
        val api = selectedApi().api
        val unauth = object : AbstractAnonymousSession(api) {}
        AuthComponent(
            AuthClientEndpoints(
                subjects = mapOf(
                    "user" to unauth.userAuth
                ),
                authenticatedSubjects = mapOf(),
                emailProof = unauth.emailProof,
            )
        ) { token ->
            println("Got token $token, setting to sessionToken")
            sessionToken set token
        }
    }
    val email = Property("")
    private val unregistered = Property<String?>(null)

    override fun ViewWriter.render() {
        stack {
            reactive {
                if(currentSession.invoke().also { println("Current session is now $it") } != null) mainScreenNavigator.reset(CatalogScreen)
            }
            atTopEnd - button {
                centered - h6 { ::content { selectedApi().apiName } }

                onClick {
                    selectedApi.value = selectedApi.awaitOnce().next()
                }
            }

            centered - padded - card - sizeConstraints(width = 28.rem) - col {
                spacing = 1.5.rem

                bold - h2("Vanden-Bussche Irrigation")

                stack {
                    reactive {
                        clearChildren()
                        with(authComponent()) {
                            render()
                        }
                    }
                }

                row {
                    spacing = 0.5.rem
                    centered - h6("Don't have an account? ")

                    centered - emphasized - link {
                        spacing = 0.5.rem
                        to = { UserRegistration().RegistrationScreen() }
                        centered - h6("Register Here")
                    }
                }
            }
        }
    }
}