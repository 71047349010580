package com.virtualrain.views.components

import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.reactiveScope
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.RecyclerView
import com.lightningkite.lightningserver.db.LimitReadable

fun <T> RecyclerView.pageChildren(
    items: Readable<LimitReadable<T>>,
    increase: Int = 100,
    tolerance: Int = 20,
    render: ViewWriter.(Readable<T>) -> Unit
) {
    reactiveScope {
        val it = items()
        if (lastVisibleIndex() > it.limit - tolerance) it.limit = lastVisibleIndex() + increase
    }
    children(shared { items()() }, render)
}