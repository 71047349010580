package com.virtualrain.admin

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import com.virtualrain.csv.CsvFormat
import com.virtualrain.csv.StringDeferringConfig
import com.virtualrain.models.*
import com.virtualrain.sdk.currentSession
import com.virtualrain.views.components.atEndOfDay
import com.virtualrain.views.components.atStartOfDay
import com.virtualrain.views.textFormatting.format
import kotlinx.coroutines.*
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.JsonNull.content


@Routable("/reports")
class AdminReportsScreen() : Screen {
    override val title: Readable<String> = Constant<String>("Reports")

    @QueryParameter
    val startDate = Property<LocalDate?>(null)
    @QueryParameter
    val endDate = Property<LocalDate?>(null)

    val format = CsvFormat(StringDeferringConfig(ClientModule))

    override fun ViewWriter.render() = scrolls - col {
        rowCollapsingToColumn(50.rem) {
            expanding - card - col {
                h3("Order Export")

                text("Date Range")
                row {
                    centered - text("From:")
                    fieldTheme - compact - localDateField { content bind startDate }

                    centered - text("To:")
                    fieldTheme - compact - localDateField { content bind endDate }
                }
                row {
                    button {
                        centered - h6("Export Order Items")

                        onClick {
                            val orders = currentSession().orders.query(Query(
                                condition<Order> {
                                    Condition.And(
                                        listOfNotNull(
                                            startDate()?.atStartOfDay()?.let { d -> it.orderedAt.gte(d) },
                                            endDate()?.atEndOfDay()?.let { d -> it.orderedAt.lte(d) },
                                        )
                                    )
                                },
                                orderBy = sort { it.orderedAt.descending() }
                            ))()
                            val allAccounts =
                                currentSession().customerAccounts.query(Query(condition { condition(true) }))()

                            val products = currentSession().products.query(Query(condition { condition(true) }))()

                            format.encodeToString(
                                orders.flatMap { order ->
                                    order.items.map { item ->
                                        ExportOrderItem(
                                            orderId = order.erpId,
                                            status = order.status.code.name,
                                            company = allAccounts.find { it._id == order.account }?.address?.businessName
                                                ?: "",

                                            productNum = products.find { it._id == item.product }?.erpId ?: "",
                                            SKU = products.find { it._id == item.product }?.sku ?: "",
                                            productTitle = products.find { it._id == item.product }?.title ?: "",
                                            description = products.find { it._id == item.product }?.manufacturerDescription
                                                ?: "",
                                            manufacturer = products.find { it._id == item.product }?.manufacturer ?: "",
                                            quantity = item.quantity,
                                            price = item.previousPrice?.toString() ?: "",
                                            unit = (products.find { it._id == item.product }?.quantityType
                                                ?: "").toString(),
                                        )
                                    }
                                }
                            ).toBlob().let { ExternalServices.download("orderItems.csv", it) }
                        }
                    }
                    button {
                        centered - h6("Export Orders")
                        onClick {
                            val orders = currentSession().orders.query(Query(
                                condition<Order> {
                                    Condition.And(
                                        listOfNotNull(
                                            startDate()?.atStartOfDay()?.let { d -> it.orderedAt.gte(d) },
                                            endDate()?.atEndOfDay()?.let { d -> it.orderedAt.lte(d) },
                                        )
                                    )
                                },
                                orderBy = sort { it.orderedAt.descending() }
                            ))()
                            val allAccounts =
                                currentSession().customerAccounts.query(Query(condition { condition(true) }))()
                            val shipTo =
                                currentSession().shippingAddresses.query(Query(condition { condition(true) }))()
                            val allWarehouses =
                                currentSession().warehouses.query(Query(condition { condition(true) }))()
                            format.encodeToString(
                                orders.map { order ->
                                    ExportOrder(

                                        date = order.orderedAt.format(),
                                        status = order.status.code.name,
                                        account = allAccounts.find { it._id == order.account }?.address?.businessName
                                            ?: "",
                                        orderId = order.erpId,
                                        warehouse = allWarehouses.find { it._id == order.warehouse }?.name ?: "",
                                        shipTo = shipTo.find { it._id == order.shipTo }?.name ?: "",
                                        contactErpId = order.contactErpID,
                                        contactEmail = order.contactEmail ?: "",
                                        shipASAP = order.shipASAP,
                                        comment = order.comment,
                                        poNum = order.poNum,
                                        total = "",
                                    )
                                }
                            ).toBlob().let { ExternalServices.download("orders.csv", it) }

                        }
                    }
                }

            }
            expanding - card - col {
                h3("User Export")
                button {
                    centered - h6("Export All Users")
                    onClick {
                        val allUsers = currentSession().users.query(Query(condition { condition(true) }))()
                        val format = CsvFormat(StringDeferringConfig(ClientModule))
                        val allAccounts =
                            currentSession().customerAccounts.query(Query(condition { condition(true) }))()

                        format.encodeToString(
                            allUsers.map { user ->
                                val account = allAccounts.find { it._id == user.account }
                                ExportUser(
                                    firstName = user.firstName,
                                    lastName = user.lastName,
                                    active = user.active,
                                    email = user.email,
                                    account = account?.address?.businessName ?: "",
                                    role = user.role,
                                    emailsForNewOrderStatus = user.emailOnNewOrderStatus,
                                    emailsForNewUsers = user.emailOnNewUsers,
                                )
                            }
                        ).toBlob().let { ExternalServices.download("users.csv", it) }

                    }
                }
            }
        }
        card - col {
            h3("Sync ERP")
            rowCollapsingToColumn(50.rem) {
                expanding - col {
                    button {
                        text("Sync Now")
                        onClick {
                            currentSession().nonCached.syncNow()
                        }
                    }

                    col {
                        val liveState = shared { currentSession().syncTaskStatus.watch(Query())() }
                        forEachUpdating(asyncReadable { currentSession().nonCached.getSyncTasks() }) { task ->
                            val live = shared { liveState().find { it._id == task() } }
                            row {
                                weight(1f) - text {
                                    ::content { task() }
                                }
                                weight(1f) - text {
                                    ::content { live()?.startedAt?.format()?.let { "Last run $it" } ?: "Not Started" }
                                }
                                weight(1f) - text {
                                    ::content { live()?.status ?: "-" }
                                }
                                weight(1f) - compact - button {
                                    text("Run Now")
                                    onClick {
                                        currentSession().nonCached.syncNowPartial(task())
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@Serializable
data class ExportUser(
    val firstName: String,
    val lastName: String,

    val email: String,
    val active: Boolean = true,
    val role: UserRole = UserRole.Customer,
    val account: String,
    val emailsForNewOrderStatus: Boolean? = null,
    val emailsForNewUsers: Boolean? = null,

    )

@Serializable
data class ExportOrder(
    val date: String,
    val orderId: String? = null,
    val status: String,
    val account: String,
    val contactErpId: Double? = null,
    val contactEmail: String,
    val warehouse: String,
    val shipTo: String,
    val shipASAP: Boolean = false,
    val comment: String,
    val poNum: String,
    val total: String,
)

@Serializable
data class ExportOrderItem(
    val orderId: String?,
    val status: String,
    val company: String,
    val productNum: String,
    val productTitle: String,
    val description: String,
    val manufacturer: String,
    val SKU: String,
    val quantity: Int?,
    val unit: String,
    val price: String,


    )