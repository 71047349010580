package com.virtualrain

import com.lightningkite.kiteui.exceptions.installLsError
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.exceptions.ExceptionToMessages
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.*
import com.lightningkite.now
import com.lightningkite.serialization.ClientModule
import com.virtualrain.admin.*
import com.virtualrain.models.UserRole
import com.virtualrain.models.prepareModelsShared
import com.virtualrain.sdk.currentSession
import com.virtualrain.sdk.currentSessionFailed
import com.virtualrain.sdk.sessionToken
import com.virtualrain.theming.*
import com.virtualrain.views.screens.account.AccountInfoScreen
import com.virtualrain.views.screens.account.PickupLocationsScreen
import com.virtualrain.views.screens.account.ShippingLocationsScreen
import com.virtualrain.views.screens.account.orders.OrderHistoryScreen
import com.virtualrain.views.screens.auth.AuthScreen
import com.virtualrain.views.screens.auth.LoginScreen
import com.virtualrain.views.screens.cart.CartScreen
import com.virtualrain.views.screens.cart.Carts
import com.virtualrain.views.screens.common.HasNarrowContent.Info.narrow
import com.virtualrain.views.screens.common.userHasAccount
import com.virtualrain.views.screens.common.userIsAdmin
import com.virtualrain.views.screens.products.CatalogScreen
import com.virtualrain.views.screens.products.Favorites
import com.virtualrain.views.screens.products.SearchScreen
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant

object Logout {
    private val actions = ArrayList<suspend () -> Unit>()
    fun onLogout(action: suspend () -> Unit) {
        actions.add(action)
    }

    suspend operator fun invoke() {
        actions.forEach {
            try {
                it()
            } catch (e: Exception) {
                println("Logout action error occurred: ${e.printStackTrace2()}")
            }
        }
    }
}

fun ViewWriter.app(navigator: ScreenNavigator, dialog: ScreenNavigator) {
    debugMode = true
    com.lightningkite.prepareModelsShared()
    prepareModelsShared()
    DefaultSerializersModule = ClientModule

    ExceptionToMessages.root.installLsError()

    appNavFactory.value = ViewWriter::appNavBottomTabs

    appBase(navigator, dialog) {
        swapView {
            swapping(
                current = { appNavFactory() },
                views = {
                    it(this) {
                        currentSessionFailed.addListener {
                            dialogScreenNavigator.dismiss()
                            mainScreenNavigator.reset(LoginScreen())
                        }

                        ::navItems {
                            listOf(
                                NavLink(title = "Home", icon = Icon.home) { CatalogScreen },
                                NavLink(title = { "Favorites" }, icon = { Icon.heartFilled }, hidden = { currentSession.invoke()?.accountId == null }) { { Favorites() } },
                                NavLink(title = "Search", icon = Icon.search) { SearchScreen() },
                                NavLink(
                                    title = { "Cart" }, icon = { Icon.cart }, hidden = { currentSession.invoke()?.accountId == null },
                                    count = { if (userHasAccount()) Carts.selected()?.items?.size?.takeUnless { it == 0 } else null }
                                ) { { CartScreen() } },
                                NavGroup(
                                    title = { "Account" },
                                    icon = { Icon.person },
                                    children = {
                                        listOf(
                                            NavLink("Account Info", Icon.person) { AccountInfoScreen() },
                                            NavLink({ "Order History" }, { Icon.list }, hidden = { currentSession.invoke()?.accountId == null }) { { OrderHistoryScreen() } },
                                            NavLink({ "Shipping Locations" }, { Icon.ship }, hidden = { currentSession.invoke()?.accountId == null }) { { ShippingLocationsScreen() } },
                                            NavLink({ "Pickup Locations" }, { Icon.location }, hidden = { currentSession.invoke()?.accountId == null }) { { PickupLocationsScreen() } },
                                            NavAction(
                                                title = { "Logout" },
                                                icon = { Icon.logout },
                                                onSelect = {
                                                    confirmDanger(
                                                        title = "Log Out",
                                                        body = "Are you sure you want to log out?",
                                                        actionName = "I'm Sure.",
                                                        action = {
                                                            try {
                                                                currentSession().nonCached.userAuth.terminateSession()
                                                            } catch (_: Exception) {
                                                            }
                                                            Logout()
                                                            sessionToken.set(null)
                                                            navigator.reset(LoginScreen())
                                                        }
                                                    )
                                                }
                                            )
                                        )
                                    }
                                ),
                                NavGroup(
                                    title = { "Admin" },
                                    icon = { Icon.person },
                                    hidden = { (currentSession.invoke()?.role ?: UserRole.Customer) < UserRole.Admin || narrow() },
                                    children = {
                                        listOf(
                                            NavLink({ "Specials" }, { Icon.list }) { { AdminSpecialsScreen() } },
                                            NavLink({ "Categories" }, { Icon.ship }) { { AdminCategoriesScreen() } },
                                            NavLink({ "Products" }, { Icon.ship }) { { AdminProductsScreen() } },
                                            NavLink({ "Reports" }, { Icon.location }) { { AdminReportsScreen() } },
                                            NavLink({ "Accounts" }, { Icon.location }) { { AdminAccountsScreen() } },
                                            NavLink({ "Users" }, { Icon.location }) { { AdminUsersScreen() } },
                                            NavLink({ "Admins" }, { Icon.location }) { { AdminAdminLoginsScreen() } },
                                            NavLink({ "Orders" }, { Icon.location }) { { AdminOrdersScreen() } },
                                            NavLink({ "Warehouses" }, { Icon.location }) { { AdminWarehousesScreen() } },
                                            NavLink("Price Increases", Icon.edit) { PriceIncreasesScreen() }
                                        )
                                    }
                                )
                            )
                        }

                        ::exists {
                            screenNavigator.currentScreen.await() !is AuthScreen
                        }
                    }
                }
            )
        }
        // Really evil hack forces data to reload whenever we navigate screens
        // Not sure if this is a good idea, but it should stymie some complaints about the cache.
        AppScope.reactiveScope {
            val session = currentSession.invoke() ?: return@reactiveScope
            val screen = mainScreenNavigator.currentScreen()
            session.refresh()
        }
        connectivityDialog()
    }
}

fun ViewWriter.connectivityDialog() = dismissBackground {
    reactiveScope {
        if (Connectivity.lastConnectivityIssueCode() in Connectivity.tooMuchCodes) {
            val breakdown = recentUrlHits
                .groupBy { it.second }
                .mapValues { it.value.size }.entries
                .sortedByDescending { it.value }
                .joinToString(", ") {
                    "${it.key.substringAfter("://").substringAfter("/")} = ${it.value}"
                }
            Exception("Too many requests. Breakdown: $breakdown").report()
        }
    }
    ::exists { Connectivity.fetchGate.retryAt() != null }
    onClick { }
    centered - card - col {
        h2("Connectivity Issues")
        col {
            ::exists { Connectivity.lastConnectivityIssueCode() !in Connectivity.tooMuchCodes }
            text("Your access to the internet has some problems.")
            text("Please reconnect to the internet to continue.")
        }
        col {
            ::exists { Connectivity.lastConnectivityIssueCode() in Connectivity.tooMuchCodes }
            text("You're requesting too much.")
            text("Please wait for a minute and retry.")
        }
        text {
            launch {
                while (true) {
                    delay(200)
                    content =
                        "Retrying in ${((Connectivity.fetchGate.retryAt() ?: now()) - now()).inWholeSeconds + 1} seconds"
                }
            }
        }
        row {
            expanding - danger - button {
                centered - text("Log Out")
                onClick {
                    Connectivity.fetchGate.abandon()
                    mainScreenNavigator.navigate(LoginScreen())
                }
            }
            expanding - button {
                centered - text("Retry now")
                onClick {
                    Connectivity.fetchGate.retryNow()
                }
            }
        }
    }
}

val recentUrlHits = ArrayList<Pair<Instant, String>>()