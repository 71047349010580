// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareProductFields() {
    val props: Array<SerializableProperty<Product, *>> = arrayOf(Product__id, Product_erpId, Product_categories, Product_title, Product_manufacturerDescription, Product_manufacturer, Product_sku, Product_createdAt, Product_updatedAt, Product_image, Product_imageLarge, Product_quantityType, Product_outOfDate, Product_active)
    Product.serializer().properties { props }
}
val <K> DataClassPath<K, Product>._id: DataClassPath<K, UUID> get() = this[Product__id]
val <K> DataClassPath<K, Product>.erpId: DataClassPath<K, String> get() = this[Product_erpId]
val <K> DataClassPath<K, Product>.categories: DataClassPath<K, Set<UUID>> get() = this[Product_categories]
val <K> DataClassPath<K, Product>.title: DataClassPath<K, String> get() = this[Product_title]
val <K> DataClassPath<K, Product>.manufacturerDescription: DataClassPath<K, String> get() = this[Product_manufacturerDescription]
val <K> DataClassPath<K, Product>.manufacturer: DataClassPath<K, String> get() = this[Product_manufacturer]
val <K> DataClassPath<K, Product>.sku: DataClassPath<K, String> get() = this[Product_sku]
val <K> DataClassPath<K, Product>.createdAt: DataClassPath<K, Instant> get() = this[Product_createdAt]
val <K> DataClassPath<K, Product>.updatedAt: DataClassPath<K, Instant> get() = this[Product_updatedAt]
val <K> DataClassPath<K, Product>.image: DataClassPath<K, ServerFile?> get() = this[Product_image]
val <K> DataClassPath<K, Product>.imageLarge: DataClassPath<K, ServerFile?> get() = this[Product_imageLarge]
val <K> DataClassPath<K, Product>.quantityType: DataClassPath<K, QuantityType> get() = this[Product_quantityType]
val <K> DataClassPath<K, Product>.outOfDate: DataClassPath<K, Boolean> get() = this[Product_outOfDate]
val <K> DataClassPath<K, Product>.active: DataClassPath<K, Boolean> get() = this[Product_active]
inline val Product.Companion.path: DataClassPath<Product, Product> get() = path<Product>()


object Product__id: SerializableProperty<Product, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Product): UUID = receiver._id
    override fun setCopy(receiver: Product, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Product.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object Product_erpId: SerializableProperty<Product, String> {
    override val name: String = "erpId"
    override fun get(receiver: Product): String = receiver.erpId
    override fun setCopy(receiver: Product, value: String) = receiver.copy(erpId = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Product.serializer().tryFindAnnotations("erpId")
}
object Product_categories: SerializableProperty<Product, Set<UUID>> {
    override val name: String = "categories"
    override fun get(receiver: Product): Set<UUID> = receiver.categories
    override fun setCopy(receiver: Product, value: Set<UUID>) = receiver.copy(categories = value)
    override val serializer: KSerializer<Set<UUID>> = SetSerializer(ContextualSerializer(UUID::class))
    override val annotations: List<Annotation> = Product.serializer().tryFindAnnotations("categories")
}
object Product_title: SerializableProperty<Product, String> {
    override val name: String = "title"
    override fun get(receiver: Product): String = receiver.title
    override fun setCopy(receiver: Product, value: String) = receiver.copy(title = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Product.serializer().tryFindAnnotations("title")
}
object Product_manufacturerDescription: SerializableProperty<Product, String> {
    override val name: String = "manufacturerDescription"
    override fun get(receiver: Product): String = receiver.manufacturerDescription
    override fun setCopy(receiver: Product, value: String) = receiver.copy(manufacturerDescription = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Product.serializer().tryFindAnnotations("manufacturerDescription")
}
object Product_manufacturer: SerializableProperty<Product, String> {
    override val name: String = "manufacturer"
    override fun get(receiver: Product): String = receiver.manufacturer
    override fun setCopy(receiver: Product, value: String) = receiver.copy(manufacturer = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Product.serializer().tryFindAnnotations("manufacturer")
}
object Product_sku: SerializableProperty<Product, String> {
    override val name: String = "sku"
    override fun get(receiver: Product): String = receiver.sku
    override fun setCopy(receiver: Product, value: String) = receiver.copy(sku = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Product.serializer().tryFindAnnotations("sku")
}
object Product_createdAt: SerializableProperty<Product, Instant> {
    override val name: String = "createdAt"
    override fun get(receiver: Product): Instant = receiver.createdAt
    override fun setCopy(receiver: Product, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Product.serializer().tryFindAnnotations("createdAt")
    override val default: Instant = Clock.System.now()
}
object Product_updatedAt: SerializableProperty<Product, Instant> {
    override val name: String = "updatedAt"
    override fun get(receiver: Product): Instant = receiver.updatedAt
    override fun setCopy(receiver: Product, value: Instant) = receiver.copy(updatedAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Product.serializer().tryFindAnnotations("updatedAt")
    override val default: Instant = Clock.System.now()
}
object Product_image: SerializableProperty<Product, ServerFile?> {
    override val name: String = "image"
    override fun get(receiver: Product): ServerFile? = receiver.image
    override fun setCopy(receiver: Product, value: ServerFile?) = receiver.copy(image = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = Product.serializer().tryFindAnnotations("image")
    override val default: ServerFile? = null
}
object Product_imageLarge: SerializableProperty<Product, ServerFile?> {
    override val name: String = "imageLarge"
    override fun get(receiver: Product): ServerFile? = receiver.imageLarge
    override fun setCopy(receiver: Product, value: ServerFile?) = receiver.copy(imageLarge = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = Product.serializer().tryFindAnnotations("imageLarge")
    override val default: ServerFile? = null
}
object Product_quantityType: SerializableProperty<Product, QuantityType> {
    override val name: String = "quantityType"
    override fun get(receiver: Product): QuantityType = receiver.quantityType
    override fun setCopy(receiver: Product, value: QuantityType) = receiver.copy(quantityType = value)
    override val serializer: KSerializer<QuantityType> = QuantityType.serializer()
    override val annotations: List<Annotation> = Product.serializer().tryFindAnnotations("quantityType")
    override val default: QuantityType = QuantityType.Each
}
object Product_outOfDate: SerializableProperty<Product, Boolean> {
    override val name: String = "outOfDate"
    override fun get(receiver: Product): Boolean = receiver.outOfDate
    override fun setCopy(receiver: Product, value: Boolean) = receiver.copy(outOfDate = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = Product.serializer().tryFindAnnotations("outOfDate")
    override val default: Boolean = false
}
object Product_active: SerializableProperty<Product, Boolean> {
    override val name: String = "active"
    override fun get(receiver: Product): Boolean = receiver.active
    override fun setCopy(receiver: Product, value: Boolean) = receiver.copy(active = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = Product.serializer().tryFindAnnotations("active")
    override val default: Boolean = true
}
