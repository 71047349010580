package com.virtualrain.theming

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.EmbeddedSemantic
import com.lightningkite.kiteui.models.EmphasizedSemantic
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter

@ViewModifierDsl3
val ViewWriter.embedded: ViewWrapper
    get() = EmbeddedSemantic.onNext

@ViewModifierDsl3
val ViewWriter.lightOutline: ViewWrapper
    get() = LightOutline.onNext

@ViewModifierDsl3
val ViewWriter.ghost: ViewWrapper
    get() = GhostSemantic.onNext

@ViewModifierDsl3
val ViewWriter.fadingGradient: ViewWrapper
    get() = FadingGradient.onNext

@ViewModifierDsl3
val ViewWriter.checkboxTheme: ViewWrapper
    get() = CheckboxSemantic.onNext

@ViewModifierDsl3
val ViewWriter.unavailable: ViewWrapper
    get() = UnavailableSemantic.onNext

@ViewModifierDsl3
val ViewWriter.listContainerTheme: ViewWrapper
    get() = ListContainerSemantic.onNext

@ViewModifierDsl3
val ViewWriter.emphasized: ViewWrapper
    get() = EmphasizedSemantic.onNext