// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.virtualrain.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareFavoriteFields() {
    val props: Array<SerializableProperty<Favorite, *>> = arrayOf(Favorite__id, Favorite_priority)
    Favorite.serializer().properties { props }
}
val <K> DataClassPath<K, Favorite>._id: DataClassPath<K, AccountProduct> get() = this[Favorite__id]
val <K> DataClassPath<K, Favorite>.priority: DataClassPath<K, Int> get() = this[Favorite_priority]
inline val Favorite.Companion.path: DataClassPath<Favorite, Favorite> get() = path<Favorite>()


object Favorite__id: SerializableProperty<Favorite, AccountProduct> {
    override val name: String = "_id"
    override fun get(receiver: Favorite): AccountProduct = receiver._id
    override fun setCopy(receiver: Favorite, value: AccountProduct) = receiver.copy(_id = value)
    override val serializer: KSerializer<AccountProduct> = AccountProduct.serializer()
    override val annotations: List<Annotation> = Favorite.serializer().tryFindAnnotations("_id")
}
object Favorite_priority: SerializableProperty<Favorite, Int> {
    override val name: String = "priority"
    override fun get(receiver: Favorite): Int = receiver.priority
    override fun setCopy(receiver: Favorite, value: Int) = receiver.copy(priority = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = Favorite.serializer().tryFindAnnotations("priority")
    override val default: Int = 0
}
