package com.virtualrain.sdk.utils

import com.lightningkite.kiteui.models.InvalidSemantic
import com.lightningkite.kiteui.reactive.Draft
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.dynamicTheme
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.ModelCache
import com.lightningkite.lightningserver.db.WritableModel
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*

import kotlinx.serialization.KSerializer

suspend inline fun <reified T> WritableModel<T>.modify(setup: ModificationBuilder<T>.(DataClassPath<T, T>) -> Unit) =
    modify(modification(setup))

suspend fun <T> Draft<T>.diff(serializer: KSerializer<T>): Modification<T>? = modification(serializer, published.await(), this.await())
suspend inline fun <reified T> Draft<T>.diff(): Modification<T>? = diff(serializerOrContextual())

suspend fun <T: HasId<ID>, ID: Comparable<ID>> Draft<T>.pushChanges(cache: ModelCache<T, ID>) {
    val id = published()._id
    cache[id].set(publish())
}

suspend fun <T: HasId<ID>, ID: Comparable<ID>> Draft<T>.pushChanges(getCache: suspend () -> ModelCache<T, ID>) =
    pushChanges(getCache())