package com.virtualrain.views.screens.account.orders

import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.inside
import com.lightningkite.uuid
import com.virtualrain.models.*
import com.virtualrain.sdk.currentSession
import com.virtualrain.sdk.utils.modify
import com.virtualrain.theming.hide
import com.virtualrain.theming.listContainerTheme
import com.virtualrain.theming.show
import com.virtualrain.toggle
import com.virtualrain.views.components.detail
import com.virtualrain.views.components.productCard
import com.virtualrain.views.components.productImage
import com.virtualrain.views.screens.cart.CheckoutDialog
import com.virtualrain.views.screens.common.HasNarrowLayout
import com.virtualrain.views.screens.common.userHasAccount
import com.virtualrain.views.screens.products.ProductScreen
import com.virtualrain.views.textFormatting.format

@Routable("/order/{orderID}")
class OrderScreen(val orderID: UUID) : HasNarrowLayout {
    val order = shared {
        val session = currentSession()
        session.orders[orderID]() ?: throw NullPointerException("Couldn't find order")
    }

    private val products = shared {
        val session = currentSession()
        val products = order().items.map { it.product }

        session.products.query(
            Query(
                condition { it._id inside products },
                limit = 1000
            )
        )().associateBy { it._id }
    }

    var isConfirmation: Boolean = false

    override val title: Readable<String> = shared { "Order #" + order().poNum }

    /*--COMPONENTS--*/
    private fun ViewWriter.thankYouConfirmation() {
        if (isConfirmation) {
            centered - col {
                centered - h2("Thank You!")
                h5("You will be notified when your order has been processed.")
            }
            space()
        }
    }

    private fun ViewWriter.reorderButton() {
        button {
            centered - h6("Reorder")

            onClick {
                val o = order()

                dialogScreenNavigator.navigate(CheckoutDialog(o.items, o.warehouse))
            }
        }
    }

    private fun ViewWriter.orderDetails() {
        col {
            detail("PO #") { order().poNum }
            detail("Ordered") { order().orderedAt.format() }
            detail("Status") { order().status.code.toString() }
            detail("Total") { order().total.toString() }
            onlyWhen { order().shipTo == null } - detail("Shipping Location") { order().warehouse.let { currentSession().warehouses[it]() }?.name ?: "Unknown" }
            onlyWhen { order().shipTo != null } - detail("Pickup Location") { order().shipTo?.let { currentSession().shippingAddresses[it]() }?.name ?: "Unknown" }
            onlyWhen { order().account != currentSession().accountId } - col {
                val acct = shared { currentSession().customerAccounts[order().account]() }
                detail("Company") { acct()?.address?.businessName ?: "" }
                detail("Account #") { acct()?.erpId?.toString() ?: "" }
            }
            if(debugMode) detail("ERP ID") { order().erpId ?: "<none>" }
            detail("Ordered by") { order().orderedBy?.let { currentSession().users.get(it)() }?.name ?: "<unknown>" }
            detail("Contact") {
                val id = order().contactErpID
                currentSession().contacts.request()().find { it.contactId == id }?.let {
                    "${it.fullName} (${it.email})"
                } ?: "No Contact"
            }

            col {
                ::vertical { order().comment.isNotBlank() }
                bold - text("Order Comment")
                row { expanding - text { ::content { order().comment.ifBlank { "None" } } } }
            }
        }
    }

    private fun RView.renderItems() {
        forEachUpdating(shared { order().items.toList() }) { cartItem ->
            val associatedProduct = shared { products()[cartItem().product]!! }

            sizeConstraints(height = 8.rem) - card - row {

                expanding - productCard(associatedProduct, 5.rem)

                padded - col {
                    spacing = 0.5.rem
                    atEnd - text { ::content { "Quantity: " + cartItem().quantity.toString() } }
                    row {
                        gravity(Align.Start, Align.End) - expanding - subtext("x")
                        text {
                            align = Align.End
                            ::content { cartItem().previousPrice.toString() }
                        }
                    }
                    separator()
                    text {
                        align = Align.End
                        ::content { cartItem().previousLineTotal.toString() }
                    }
                }
            }
        }
    }

    override fun ViewWriter.renderWide() {
        scrolls - col {
            thankYouConfirmation()

            row {
                expanding - card - orderDetails()

                card - stack {
                    ::exists { userHasAccount() }

                    centered - padded - col {
                        spacing = 1.25.rem

                        card - run {
                            button {
                                ::enabled { order().status.code != OrderStatusCode.Cancelled }

                                centered - h6("Cancel")

                                onClick {
                                    confirmDanger("Cancel Order", "Are you sure you want to cancel this order?") {
                                        val o = order()
                                        currentSession().orders[o._id].modify {
                                            it.status assign OrderStatus(OrderStatusCode.Cancelled)
                                        }
                                    }
                                }
                            }
                            Unit
                        }

                        important - reorderButton()
                    }
                }
            }

            listContainerTheme - col {
                h2 { ::content { "${order().items.size} Item(s)" } }

                col {
                    renderItems()
                }
            }
        }
    }

    override fun ViewWriter.renderNarrow() {
        scrolls - col {
            thankYouConfirmation()

            card - col {
                orderDetails()
            }

            col {
                renderItems()
            }

            row {
                expanding - card - run {
                    button {
                        ::enabled { order().status.code != OrderStatusCode.Cancelled }

                        centered - h6("Cancel")

                        onClick {
                            confirmDanger("Cancel Order", "Are you sure you want to cancel this order?") {
                                val o = order()
                                currentSession().orders[o._id].modify {
                                    it.status assign OrderStatus(OrderStatusCode.Cancelled)
                                }
                            }
                        }
                    }
                    Unit
                }
                expanding - important - reorderButton()
            }
        }
    }
}