package com.virtualrain.views.screens.products

import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.now
import com.lightningkite.serialization.*
import com.virtualrain.mappings.fromData
import com.virtualrain.models.*
import com.virtualrain.sdk.currentSession
import com.virtualrain.sdk.utils.activeWarehouses
import com.virtualrain.sdk.utils.notFoundError
import com.virtualrain.theming.*
import com.virtualrain.views.components.*
import com.virtualrain.views.screens.cart.Carts
import com.virtualrain.views.screens.cart.inStock
import com.virtualrain.views.screens.common.HasNarrowContent
import com.virtualrain.views.screens.common.userHasAccount
import com.virtualrain.views.textFormatting.format
import com.virtualrain.views.textFormatting.formatName


@Routable("Product/{productID}")
class ProductScreen(val productID: UUID) : Screen, HasNarrowContent {
    val product = LazyProperty {
        currentSession().products[productID]() ?: notFoundError<Product>()
    }

    override val title: Readable<String> = shared { product().title }

    private val pricing = LateInitProperty<Readable<CartItem?>>()

    override fun ViewWriter.render() {
        scrolls - col  {
            atTopCenter - sizeConstraints(width = HasNarrowContent.breakpoint) - card - col {
                rowCollapsingToColumn(30.rem) {
                    gravity(Align.Center, Align.Start) - sizeConstraints(width = 9.rem, height = 9.rem) - ImageSemantic.onNext - image {
                        scaleType = ImageScaleType.Fit
                        ::source { product().image?.location?.let(::ImageRemote) }
                    }
                    expanding - row {
                        expanding - col {
                            spacing = 0.1.rem
                            h4 { ::content { product().title } }
                            subtext { ::content { product().erpId } }
                            subtext { ::content { product().manufacturer } }
                            atStart - SubtextSemantic.onNext - row {
                                ::exists { userHasAccount() }
                                spacing = 0.5.rem
                                centered - text {
                                    ::content {
                                        Carts.selectedSourceWarehouse().inStock(productID)().let {
                                            if(pricing()()?.previousPrice == null)
                                                "Available on request at"
                                            else if (currentSession().self().showInventory)
                                                "$it in stock at"
                                            else if (it == 0)
                                                "Out of stock at"
                                            else
                                                "In stock at"
                                        }
                                    }
                                }
                                val warehouses = shared { activeWarehouses()() }
                                sizeConstraints(width = 10.rem) - fieldTheme - select {
                                    spacing = 0.2.rem
                                    bind(
                                        edits = Carts.selected.waitForNotNull.lensPath { it.warehouse }.fromData(warehouses),
                                        data = warehouses,
                                        render = { it.formatName() }
                                    )
                                }
                            }

                        }
                        atTop - toggleButton {
                            val fav = product.inFavorites()
                            ::exists { userHasAccount() }
                            ::enabled { currentSession().mayOrder }
                            icon {
                                ::source { if (fav()) Icon.heartFilled else Icon.heart }
                            }
                            checked bind fav
                        }
                    }
                }
                RichDescriptionSemantic.onNext - text {
                    ::setBasicHtmlContent { product().manufacturerDescription }
                }

                warning - col {
                    val now = now()
                    val priceIncrease = shared {
                        currentSession().priceIncreases.query(
                            Query(
                                condition { increase -> (increase.increasesAt gte now) and (increase.products.any { it eq productID }) },
                                orderBy = sort { it.increasesAt.ascending() },
                                limit = 1
                            )
                        )().firstOrNull()
                    }
                    exists = false
                    ::exists { priceIncrease() != null }

                    bold - rowCollapsingToColumn {
                        expanding - text("Upcoming Price Increase")
                        text { ::content { priceIncrease()?.increasesAt?.format() ?: "" } }
                    }

                    text { ::content { priceIncrease()?.message ?: "" } }
                }

                rowCollapsingToColumn {
                    val hasPricing = shared { pricing()()?.previousListPrice?.takeUnless { it == 0.cents } != null }
                    gravity(Align.Start, Align.Center) - onlyWhen {
                        currentSession().self().showPricing && hasPricing()
                    } - row {
                        detail("List Price") { pricing()()?.previousListPrice?.takeUnless { it == 0.cents }?.toString() ?: "-" }
                        separator()
                        detail("Your Price") { pricing()()?.previousPrice?.takeUnless { it == 0.cents }?.toString() ?: "-" }
                    }
                    gravity(Align.Start, Align.Center) - onlyWhen {
                        currentSession().self().showPricing && !hasPricing()
                    } - text("Pricing established after ordering")

                    expanding - space()

                    stack {
                        reactiveScope {
                            clearChildren()
                            val n = narrow()
                            if (n) expanding
                            pricing.value = quantityField(product, expand = n)
                        }
                    }
                }
            }
        }
    }

//    /*--COMPONENTS--*/
//    private fun ViewWriter.productImage() {
//        image {
//            scaleType = ImageScaleType.Fit
//            ::source {
//                val prod = product()
//                ImageRemote((prod.imageLarge ?: prod.image ?: defaultImage()).location)
//            }
//        }
//    }
//
//    private fun ViewWriter.productDetails() {
//        col {
//            detail("Manufacturer") { product().manufacturer }
//            detail("Part #") { product().erpId ?: "No Part Number" }
//            detail("Last Updated") { product().updatedAt.format() }
//            detail("Sold Per") { product().quantityType.toString() }
//        }
//    }
//
//    private fun ViewWriter.favoriteButton() {
//        buttonTheme - favoriteButton(product) {
//            spacing = 0.5.rem
//
//            centered - row {
//                spacing = 0.5.rem
//
//                centered - sizeConstraints(height = 1.25.rem) - icon { ::source {
//                    if (it()) Icon.heartFilled
//                    else Icon.heart
//                } }
//
//                centered - text { ::content {
//                    if (it()) "In Favorites"
//                    else "Add to Favorites"
//                } }
//            }
//        }
//    }
//
//    private fun ViewWriter.priceIncreaseWarning() {
//        warning - row {
//            exists = false
//            ::exists { product().priceWillIncreaseAt?.let { it < now() } ?: false }
//
//            centered - icon(Icon.warning, "Warning")
//
//            centered - expanding - text {
//                ::content {
//                    "The price for this product will increase on ${product().priceWillIncreaseAt}."
//                }
//            }
//        }
//    }
//
//    private fun ViewWriter.description() {
//        col {
//            spacing = defaultSpacing
//            h4("Manufacturer Description")
//            text {
//                ::setBasicHtmlContent {product().manufacturerDescription}
//            }
//        }
//    }
//
//    private fun ViewWriter.selectStock() {
//        col {
//            spacing = defaultSpacing
//            selectSourceWarehouse()
//
//            detail("In Stock") { Carts.selectedSourceWarehouse().inStock(productID).toString() }
//        }
//    }
//
//    private val canPurchase = shared { pricing()() != null }
//    private fun RView.noInventoryError() {
//        onlyWhen { !canPurchase() } - danger - col {
//            bold - text("Cannot purchase item")
//            text {
//                ::content {
//                    val warehouse = Carts.selectedSourceWarehouse().formatName()
//                    "This item is currently unavailable at $warehouse warehouse."
//                }
//            }
//        }
//    }
//
//    private fun RView.purchaseInfo() {
//        col {
//            onlyWhen {
//                currentSession().self().showPricing
//            } - row {
//                detail("List Price") { pricing()()?.listPrice?.toString() ?: "-" }
//                separator()
//                detail("Your Price") { pricing()()?.price?.toString() ?: "-" }
//            }
//        }
//    }
//
//    override fun ViewWriter.renderWide() {
//        row {
//            expanding - scrolls - col {
//                spacing = defaultSpacing
//
//                row {
//                    sizeConstraints(height = 15.rem) - productImage()
//
//                    expanding - card - col {
//                        spacing = defaultSpacing
//
//                        expanding - productDetails()
//
//                        favoriteButton()
//                    }
//                }
//
//                card - col {
//                    spacing = defaultSpacing
//                    selectStock()
//
//                    purchaseInfo()
//
//                    priceIncreaseWarning()
//                    noInventoryError()
//
//                    label {
//                        content = "Purchase"
//
//                        col {
//                            pricing.value = quantityField(product, expand = true)
//                        }
//                    }
//                }
//
//                space()
//            }
//
//            separator()
//
//            expanding - scrolls - description()
//        }
//    }
//
//    override fun ViewWriter.renderNarrow() {
//        stack {
//            scrolls - col {
//                spacing = 1.25.rem
//
//                val expanded = Property(true)
//                sizeConstraints(aspectRatio = Pair(1, 1)) - onlyWhen { expanded() } - stack {
//                    spacing = 1.rem
//                    unpadded - productImage()
//
//                    atTopEnd - button {
//                        themeChoice = ThemeDerivation {
//                            it.copy(
//                                id = "dark-icon",
//                                foreground = Color.black,
//                                background = Color.white.withAlpha(0.3f),
//                                cornerRadii = CornerRadii.Constant(0.3.rem)
//                            ).withBack
//                        }
//                        spacing = 0.5.rem
//                        centered - icon(Icon.hide, "Hide Image")
//                        onClick { expanded.value = false }
//                    }
//                }
//
//                lightOutline - onlyWhen { !expanded() } - stack {
//                    centered - expanding - h6{
//                        content = "Image Hidden"
//                        align = Align.Center
//                    }
//                    atTopEnd - button {
//                        spacing = 0.5.rem
//                        centered - icon(Icon.show, "Show Image")
//                        onClick { expanded.value = true }
//                    }
//                }
//
//                card - col {
//                    spacing = defaultSpacing
//
//                    detail("Manufacturer") { product().manufacturer }
//                    detail("Part #") { product().erpId ?: "No Part Number" }
//                    detail("Last Updated") { product().updatedAt.format() }
//
//                    favoriteButton()
//                }
//
//                separator()
//
//                description()
//
//                card - col {
//                    selectStock()
//                    priceIncreaseWarning()
//                    purchaseInfo()
//                }
//
//                sizeConstraints(height = 5.rem) - space()
//            }
//
//            atBottomEnd
//            pricing.value = quantityField(product, expand = false)
//        }
//    }
}