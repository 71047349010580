package com.virtualrain.theming

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.Property
import com.virtualrain.theming.ThemeSettings.step
import kotlin.time.Duration.Companion.seconds

fun Paint.alterBrightness(delta: Float): Paint {
    fun Color.alterBrightness(): Color = toHSP().let {
        it.copy(brightness = it.brightness + delta)
    }.toRGB()

    return when(this) {
        is Color -> alterBrightness()
        is LinearGradient -> copy( stops = stops.map { it.copy( color = it.color.alterBrightness() ) } )
        is RadialGradient -> copy( stops = stops.map { it.copy( color = it.color.alterBrightness() ) } )
    }
}

fun HSPColor.alterBrightness(delta: Float): HSPColor = copy(
    brightness = brightness + delta
)

fun shiftPaint(altered: Paint, accent: Color, ratio: Float): Paint = when(altered) {
    is Color -> Color.interpolate(altered, accent, ratio)
    is LinearGradient -> altered.copy( stops = altered.stops.map { it.copy( color = Color.interpolate(it.color, accent, ratio).withAlpha(it.color.alpha) ) } )
    is RadialGradient -> altered.copy( stops = altered.stops.map { it.copy( color = Color.interpolate(it.color, accent, ratio).withAlpha(it.color.alpha) ) } )
}

fun Color.whiteShift(ratio: Float = 0.65f): Color = Color.interpolate(this, Color.white, ratio)

object ThemeSettings {
    const val step: Float = 0.03f

    object Colors {
        val white = Color.gray(0.9f)
        val blueWhite = Color.fromHex(0xe1e3f7)
        val blue = Color.fromHex(0x008FFF).toHSP()
        val blueGrass = HSPColor(1f, Angle(175.0/360), 0.85f, 0.45f)
        val seafoam = Color.fromHex(0x33bf7b)
        val danger = Color.fromHex(0xFFB00020.toInt())
    }
}

val lightTheme = Theme(
    id = "vblight",
    cornerRadii = CornerRadii.ForceConstant(0.3.rem),
    spacing = 0.75.rem,
    navSpacing = 0.px,
    elevation = 0.dp,
    foreground = Color.black,
    background = ThemeSettings.Colors.blueWhite,
    outline = ThemeSettings.Colors.white.highlight(0.2f),
    transitionDuration = 0.1.seconds,
    derivations = mapOf(
        CardSemantic to {
            it.copy(
                id = "card",
                background = it.background.closestColor().whiteShift(),
            ).withBack
        },
        DialogSemantic to {
            it.copy(
                id = "dlg",
                background = it.background.closestColor().whiteShift(),
            ).withBack
        },
        EmbeddedSemantic to {
            it.copy(
                id = "embedded",
                background = it.background.alterBrightness(-step/2),
                elevation = it.elevation * -1,
                revert = true
            ).withBack
        },
        BarSemantic to {
            it.copy(
                id = "bar",
                background = ThemeSettings.Colors.blue.toRGB(),
                cornerRadii = CornerRadii.ForceConstant(0.px),
                foreground = Color.white
            ).withBack
        },
        NavSemantic to {
            it.copy(
                id = "nav",
                background = ThemeSettings.Colors.blueWhite.whiteShift(0.9f),
                foreground = Color.gray,
            ).withBack
        },
        UnselectedSemantic to {
            it.copy(
                id = "unselected",
                foreground = Color.interpolate(it.foreground.closestColor(), it.background.closestColor(), 0.4f),
                background = it.background.closestColor().whiteShift()
            ).withoutBack
        },
        SelectedSemantic to {
            it.copy(
                id = "selected",
                foreground = ThemeSettings.Colors.blue.toRGB(),
                outline = ThemeSettings.Colors.blue.toRGB(),
                outlineWidth = it.outlineWidth * 2
            ).withoutBack
        },
//        HoverSemantic to {
//            it.copy(
//                id = "hover",
//                background = shiftPaint(
//                    it.background.alterBrightness(step),
//                    ThemeSettings.Colors.blue.toRGB(),
//                    0.2f
//                ),
//                outline = it.outline.alterBrightness(step),
//                outlineWidth = it.outlineWidth * 2
//            ).withBack
//        },
//        DownSemantic to {
//            it.copy(
//                id = "down",
//                background = it.background.alterBrightness(step * 3),
//                outline = it.outline.alterBrightness(step * 3),
//                outlineWidth = it.outlineWidth * 2
//            ).withBack
//        },
        FocusSemantic to {
            it.copy(
                id = "focus",
                outlineWidth = it.outlineWidth + 2.dp
            ).withBack
        },
        FieldSemantic to {
            it.copy(
                id = "field",
                outline = ThemeSettings.Colors.blue.toRGB(),
                outlineWidth = 1.px,
                background = it.background.closestColor(),
                elevation = 0.px,
                revert = true
            ).withBack
        },
        FocusSemantic to {
            it.copy(
                id = "focus",
                outlineWidth = it.outlineWidth + 1.px
            ).withoutBack
        },
        InvalidSemantic to {
            it.copy(
                id = "invalid",
                outlineWidth = 1.px,
                outline = Color.red.withAlpha(0.7f),
                revert = true
            ).withBack
        },
        ImportantSemantic to {
            it.copy(
                id = "important",
                background = ThemeSettings.Colors.blue.let {
                    it.copy(brightness = it.brightness/2)
                }.toRGB(),
                foreground = Color.white
            ).withBack
        },
        EmphasizedSemantic to {
            it.copy(
                id = "emph",
                foreground = ThemeSettings.Colors.blue.let {
                    it.copy(brightness = it.brightness/2)
                }.toRGB()
            ).withoutBack
        },
        ButtonSemantic to {
            it[ImportantSemantic].theme.copy(
                id = "button",
                cornerRadii = CornerRadii.ForceConstant(0.5.rem)
            ).withBack
        },
        GhostSemantic to {
            it.copy(
                id = "ghost",
                background = ThemeSettings.Colors.blue.toRGB().withAlpha(0.1f),
                outlineWidth = 0.px,
                elevation = 0.px
            ).withBack
        },
        UnavailableSemantic to {
            it.copy(
                id = "unavailable",
                foreground = ThemeSettings.Colors.danger
            ).withoutBack
        },
        MainContentSemantic to { it.withoutBack }
    )
)

data object ImageSemantic: Semantic {
    override val key: String = "img"
    override fun default(theme: Theme): ThemeAndBack {
        return theme.copy(
            background = Color.white
        ).withBack
    }
}

data object RichDescriptionSemantic: Semantic {
    override val key: String = "rich"
    override fun default(theme: Theme): ThemeAndBack {
        return theme.copy(
            font = theme.font.copy(size = theme.font.size)
        ).withoutBack
    }
}

val appTheme = Property(lightTheme)