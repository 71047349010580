package com.lightningtime

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.reactiveScope
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.row
import com.lightningkite.kiteui.views.direct.stack


enum class ColBuildStyle {
    LeftToRight, ByColumn
}

class StaticColDivider(
    val numCols: Int = 2,
    val buildStyle: ColBuildStyle = ColBuildStyle.LeftToRight,
    val expand: Boolean = false,
    val spacing: Dimension? = null
) {
    val items = ArrayList<ViewWriter.() -> Unit>()

    fun ViewWriter.build() {
        when (buildStyle) {
            ColBuildStyle.ByColumn -> row {
                val splitContents = items.chunked((items.size + numCols - 1) / numCols)

                if (items.isNotEmpty()) repeat(numCols) { index ->
                    if (expand) expanding
                    col {
                        this@StaticColDivider.spacing?.let { spacing = it }

                        splitContents[index].forEach { it.invoke(this@col) }
                    }
                }
            }

            ColBuildStyle.LeftToRight -> row {
                val splitContents = items.withIndex().groupBy { it.index % numCols }

                if (items.isNotEmpty()) repeat(numCols) { index ->
                    if (expand) expanding
                    col {
                        this@StaticColDivider.spacing?.let { spacing = it }

                        splitContents[index]?.forEach { it.value.invoke(this@col) }
                    }
                }
            }
        }
    }
}

class ReactiveColDivider<T> {
    private val _numCols = Property(2)
    var columns: Int by _numCols

    private val _buildStyle = Property(ColBuildStyle.LeftToRight)
    var buildStyle: ColBuildStyle by _buildStyle

    private var items: Readable<List<T>>? = null
    private var render: (ViewWriter.(T) -> Unit)? = null
    fun children(items: Readable<List<T>>, render: ViewWriter.(T) -> Unit) {
        this.items = items
        this.render = render
    }

    fun ViewWriter.build() {
        stack {
            val frozenItems = items ?: return
            val frozenRender = render ?: return

            reactiveScope {
                clearChildren()

                StaticColDivider(_numCols(), _buildStyle(), expand = true).apply {
                    val instructions: List<ViewWriter.() -> Unit> = frozenItems().map { { frozenRender(it) } }
                    items.addAll(instructions)
                    build()
                }
            }
        }
    }
}

@ViewDsl
fun <T> ViewWriter.reactiveDivider(setup: ReactiveColDivider<T>.() -> Unit) {
    ReactiveColDivider<T>().apply {
        setup()
        build()
    }
}

@ViewDsl
fun ViewWriter.dividedCol(
    numCols: Int = 2,
    buildStyle: ColBuildStyle = ColBuildStyle.LeftToRight,
    setup: StaticColDivider.() -> Unit
) = StaticColDivider(numCols, buildStyle).run {
    setup()
    build()
}